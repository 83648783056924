import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Main from './session/main';
import Versus from './session/versus';
import { sessionActions } from '../redux/_actions';
import {
  sessionLoadingState,
  sessionViewType,
  uiState,
} from '../redux/_constants';
import { SessionType } from '../models';
import { useHistory, useLocation } from 'react-router-dom';
import FullscreenLoading from './helpers/fullscreenloading';
import ProducerLoading from './helpers/producerloading';
import { useQueryParams } from '../utilities/query';
import ScoresJSON from './session/scoresJSON';
import ScoreSlips from './session/scoreslips';
import LiveClipsJSON from './session/liveclipsJSON';
import ScoreboardDisplay from './session/scoreboarddisplay';
import Producer from './session/producer';
import SessionNotFoundPage from './session/not-found-page';
import UnauthorizedPage from './session/unauthorized-page';
import ScoreConverter from './session/scoreconverter';
import ScoreSheet from './session/scoresheet';
import OfficialScoreSheet from './session/officialscoresheet';
import ClipConverter from './session/clipconverter';
import { useApolloClient } from '@apollo/client';
import PrefetchSession from '../apollo/queries/PrefetchSession.graphql';
import GetRoutines from '../apollo/queries/GetRoutines.graphql';
import { useDocumentTitle } from '../utilities/hooks';
import { useNetwork } from '../utilities/network';
import { alertActions } from '../redux/_actions';
import { useErrorHandler } from '../apollo/helpers';
import MultiExperimental from './session/multiexperimental';

function Session() {
  const setTitle = useDocumentTitle();
  const sessionKey = useSelector((state) => state.session.sessionKey);
  const sessionId = useSelector((state) => state.session.id);
  const sessionName = useSelector((state) => state.session.name);
  const sessionType = useSelector((state) => state.session.type);
  const loadingState = useSelector((state) => state.session.loadingState);
  const networkState = useNetwork({ polling: false });

  const producerLineupAId = useSelector(
    (state) => state.producer?.teamA?.lineupId
  );
  const producerLineupBId = useSelector(
    (state) => state.producer?.teamB?.lineupId
  );
  const sessionLineupAId = useSelector(
    (state) =>
      state.session.lineups?.items.find((l) => l.id === producerLineupAId)?.id
  );
  const sessionLineupBId = useSelector(
    (state) =>
      state.session.lineups?.items.find((l) => l.id === producerLineupBId)?.id
  );

  const handlePrefetchSessionError = useErrorHandler(
    'There was a problem prefetching the session'
  );

  const history = useHistory();
  const queryParams = useQueryParams();
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();
  const location = useLocation();

  const [prefetch, setPrefetch] = useState();
  const [routines, setRoutines] = useState();

  const queryMirror = queryParams.has('mirror');
  const queryMirrorSide = queryParams.get('mirror');
  const queryConvertLineupDataScores = queryParams.has(
    'convertlineupdatascores'
  );
  const queryProducer = queryParams.has('producer');
  const queryTheme = queryParams.has('theme');
  const isDarkMode =
    queryTheme &&
    ['1', 'dark'].includes(String(queryParams.get('theme')).toLowerCase());
  const querySizeType = queryParams.get('size');
  const queryProducerType = queryParams.get('producer');
  const queryConvertClips = queryParams.has('convertclips');
  const queryLiveClips = queryParams.has('liveclips');
  const queryScoreSheet = queryParams.has('scoresheet');
  const queryVersus = queryParams.has('versus');
  const queryScoreSlips = queryParams.has('scoreslips');
  const queryOfficialScoreSheet = queryParams.has('officialscoresheet');
  const queryLeaderBoard = queryParams.has('leaderboard');
  const queryLeaderBoardType = queryParams.get('leaderboard');
  const queryScoreBoard = queryParams.has('scoreboard');
  const queryScoreBoardType = queryParams.get('scoreboard');
  const queryScoresData = queryParams.has('scores');
  const queryFullScreen = queryParams.has('fullscreen');
  const queryFullScreenSide = queryParams.get('fullscreen');
  const querySoloPlayer = queryParams.has('soloplayer');
  const queryExperimental =
    queryParams.has('beta') || queryParams.has('evalslab');
  const queryMobile = queryParams.has('mobile');
  const queryStreamType = queryParams.get('stream');
  const querySessionKey = queryParams.get('s');
  const isLoaded = querySessionKey === sessionKey;
  // The producer state is not currently cleared when we navigate away from a session
  // so the lineup IDs it has may not be the ones from the session we want to load for
  // the current page. We check just one of the producer lineup ids here since they're
  // set at the same time.
  const isProducerInitialized =
    isLoaded &&
    !!producerLineupAId &&
    (producerLineupAId === sessionLineupAId ||
      producerLineupAId === sessionLineupBId);
  const isScoreboardDataInitialized = queryScoreBoardType && routines;
  const isMulti = sessionType === SessionType.MULTI;
  const readyToRender =
    isLoaded &&
    isProducerInitialized &&
    loadingState === sessionLoadingState.LOADED &&
    prefetch &&
    (!queryScoreBoardType || isScoreboardDataInitialized);

  useEffect(() => {
    if (sessionId) {
      dispatch(sessionActions.subscribe(sessionId));
      dispatch(sessionActions.subscribeNewStream(sessionId));
      dispatch(sessionActions.subscribeAllStreams());
      dispatch(sessionActions.subscribeAllLineups());
      return () => {
        dispatch(sessionActions.unsubscribe(sessionId));
        dispatch(sessionActions.unsubscribeNewStream(sessionId));
        dispatch(sessionActions.unsubscribeAllStreams());
        dispatch(sessionActions.unsubscribeAllLineups());
      };
    }
  }, [dispatch, sessionId]);

  useEffect(() => {
    if (!networkState.online) {
      console.log('Session is currently offline');
      dispatch(alertActions.offline('Check connection and refresh.'));
    }

    if (networkState.online) {
      console.log('Session is currently online');
      dispatch(alertActions.clear());
    }
  }, [dispatch, networkState.online]);

  useEffect(() => {
    let isCancelled = false;

    if (querySessionKey || queryProducer) {
      apolloClient
        .query({
          query: PrefetchSession,
          variables: { sessionKey: querySessionKey },
          errorPolicy: 'all',
        })
        .then(
          (result) => {
            if (result.data.SessionByKey?.items?.length === 0) {
              // more ideally, we turn this page into a 404
              history.push(uiState.START);
              return;
            }

            if (!isCancelled) {
              setPrefetch(result.data.SessionByKey?.items?.[0]);
            }

            if (result.errors) {
              handlePrefetchSessionError(result.errors[0]);
            }
          },
          (error) => {
            handlePrefetchSessionError(error, true);
          }
        );
      return () => {
        isCancelled = true;
      };
    }
  }, [apolloClient, querySessionKey, queryProducer, setPrefetch, history]);

  useEffect(() => {
    let isCancelled = false;

    if (sessionId && (queryScoreBoardType || queryLiveClips)) {
      apolloClient
        .query({
          query: GetRoutines,
          variables: { sessionId },
        })
        .then((result) => {
          if (!isCancelled) {
            setRoutines(result.data.SessionRoutines.items);
            /*setRoutines(
              result.data.SessionRoutines.items
                .filter(
                  (routine) =>
                    routine.apparatus === queryScoreBoardType.toUpperCase() &&
                    routine.status !== RoutineStatus.DELETED
                )
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            );*/
          }
        });
      return () => {
        isCancelled = true;
      };
    }
  }, [
    apolloClient,
    sessionId,
    queryScoreBoardType,
    queryLiveClips,
    setRoutines,
  ]);

  useEffect(() => {
    dispatch(sessionActions.load(querySessionKey));
    return () => {
      dispatch(sessionActions.unload());
    };
  }, [dispatch, querySessionKey]);

  useEffect(() => {
    if (isLoaded) {
      setTitle(sessionName);
      return () => {
        setTitle('Virtius');
      };
    }
  }, [isLoaded, sessionName, setTitle]);

  // useEffect(() => {
  //   if (isLoaded) {
  //     dispatch(sessionActions.subscribeNewStream(sessionId));
  //     return () => {
  //       dispatch(sessionActions.unsubscribeNewStream(sessionId));
  //     };
  //   }
  // }, [dispatch, isLoaded, sessionId]);

  // useEffect(() => {
  //   if (isProducerInitialized) {
  //     dispatch(sessionActions.subscribeAllStreams());
  //     return () => {
  //       dispatch(sessionActions.unsubscribeAllStreams());
  //     };
  //   }
  // }, [dispatch, isProducerInitialized]);

  // useEffect(() => {
  //   if (isProducerInitialized) {
  //     dispatch(sessionActions.subscribeAllLineups());
  //     return () => {
  //       dispatch(sessionActions.unsubscribeAllLineups());
  //     };
  //   }
  // }, [dispatch, isProducerInitialized]);

  useEffect(() => {
    if (readyToRender && queryScoreBoard) {
      dispatch(
        sessionActions.changeView(
          sessionViewType.SCOREBOARD,
          queryScoreBoardType
        )
      );
    }
  }, [dispatch, readyToRender, queryScoreBoard, queryScoreBoardType]);

  useEffect(() => {
    if (readyToRender && querySoloPlayer) {
      dispatch(sessionActions.changeView(sessionViewType.SOLOPLAYER));
    }
  }, [dispatch, readyToRender, querySoloPlayer]);

  // Handle Full Screen
  useEffect(() => {
    if (readyToRender && queryFullScreen) {
      dispatch(
        sessionActions.changeView(
          sessionViewType.FULLSCREEN,
          queryFullScreenSide
        )
      );
    }
  }, [dispatch, readyToRender, queryFullScreen, queryFullScreenSide]);

  // Handle mobile view
  useEffect(() => {
    if (readyToRender && queryMobile) {
      dispatch(sessionActions.changeView(sessionViewType.MOBILE));
    }
  }, [dispatch, readyToRender, queryMobile]);

  // Handle mirror view
  useEffect(() => {
    if (queryMirror) {
      dispatch(
        sessionActions.changeView(sessionViewType.MIRROR, queryMirrorSide)
      );
    }
  }, [dispatch, queryMirror, queryMirrorSide]);

  useEffect(() => {
    if (!querySessionKey) {
      history.push(uiState.START);
    }
  }, [history, querySessionKey]);

  if (readyToRender) {
    if (queryScoresData) {
      return <ScoresJSON sessionId={prefetch.id} />;
    } else if (queryLiveClips) {
      return <LiveClipsJSON sessionId={prefetch.id} />;
    } else if (queryConvertLineupDataScores) {
      return <ScoreConverter sessionId={prefetch.id} />;
    } else if (queryConvertClips) {
      return <ClipConverter sessionId={prefetch.id} />;
    } else if (queryScoreSheet) {
      return <ScoreSheet sessionId={prefetch.id} />;
    } else if (queryScoreSlips) {
      return <ScoreSlips sessionId={prefetch.id} />;
    } else if (
      queryOfficialScoreSheet ||
      uiState.SCORESHEET.includes(location.pathname)
    ) {
      return <OfficialScoreSheet sessionId={prefetch.id} />;
    } else if (queryScoreBoardType) {
      return (
        <ScoreboardDisplay
          sessionId={prefetch.id}
          type={queryScoreBoardType}
          isDarkMode={isDarkMode}
          size={querySizeType}
        />
      );
    } else if (queryProducer) {
      return (
        <Producer
          sessionId={prefetch.id}
          loadType={queryProducerType}
          isDarkMode={isDarkMode}
        />
      );
    } else if (queryLeaderBoard) {
      return (
        <Main
          sessionId={prefetch.id}
          isDarkMode={isDarkMode}
          isLeaderBoard={queryLeaderBoard}
          size={querySizeType}
          leaderboardType={queryLeaderBoardType}
        />
      );
    } else if (
      queryExperimental ||
      isMulti ||
      [
        'iJ_2tgT5pk',
        'DPhL8w-Yyk',
        'hPCo7KChNE',
        '1lTtBbL1G3',
        'KTjsvZNTHL',
        'fmm1tw3VTL', // Penn intrasquad
        'F5JFJxrqG-', // W&M intrasquad
        'KOOCeNZ_4X', // Red rocks preview Utah
        'gVK4F67dPg', // Arizona Red & Blue
        '6GcfgdsBYV', // BGSU intrasquad
        'lcNDT5Ozmj', // Arkansas Intrasquad
        '3BGoE0eNKL', // Minnesota Intrasquad
        'oo8GWgi1-G', // Pitt Intrasquad
        'I3l1eOEA7h',
      ].includes(sessionKey) // from Battle of the Bay x 4 and W&M Women's Hybrid h2h+alternating intrasquad, Penn Intrasquad
    ) {
      return <MultiExperimental sessionId={prefetch.id} />;
    } else if (queryVersus) {
      return (
        <Versus
          sessionId={prefetch.id}
          isDarkMode={isDarkMode}
          isLeaderBoard={queryLeaderBoard}
          streamType={queryStreamType}
        />
      );
    } else {
      return (
        <Main
          sessionId={prefetch.id}
          isDarkMode={isDarkMode}
          isLeaderBoard={queryLeaderBoard}
          streamType={queryStreamType}
        />
      );
    }
  } else if (loadingState === sessionLoadingState.UNAUTHORIZED) {
    return <UnauthorizedPage />;
  } else if (loadingState === sessionLoadingState.NOT_FOUND) {
    return <SessionNotFoundPage />;
  } else {
    if (queryProducer) {
      return <ProducerLoading />;
    } else {
      return (
        <FullscreenLoading
          message="Loading session..."
          isDarkMode={isDarkMode}
        />
      );
    }
  }
}

export default Session;
