import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CreateOrJoin from './setup/createorjoin';
import Header from './helpers/header';
import UserBubble from './helpers/userbubble';
import MenuBubble from './helpers/menububble';
import Footer from './helpers/footer';
import { uiState } from '../redux/_constants';
import { heroLogo } from './helpers/logos';
import { useHistory } from 'react-router-dom';
import { useUser } from '../user-provider';
import MatchupGrid from './helpers/matchupgrid';

function Start() {
  const { isFan } = useUser();
  const history = useHistory();

  useEffect(() => {
    if (isFan && history.location.pathname === uiState.CREATE) {
      history.push(uiState.START);
    }
  }, [history, isFan, history.location.pathname]);

  return (
    <div className="main">
      <Header>
        <UserBubble />
        <MenuBubble />
      </Header>
      <CreateOrJoin />
      <Container>
        <Row style={{ height: 'calc(8vh - 72px)' }} />
        <Row
          style={{
            background: '#fff',
            padding: '1rem',
            zIndex: '100',
          }}
        >
          <Col className="center" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div className="heroLogo"> {heroLogo} </div>
            <div className="heroTagline">Season 5</div>
            <div className="heroItalic" style={{ fontStyle: 'italic' }}>
              Select match (local time)
            </div>
          </Col>
        </Row>
        <Row style={{ height: '50vh' }}>
          <Col className="center" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <MatchupGrid />
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Start;
