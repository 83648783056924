import { API, graphqlOperation } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import {
  RoutineStatus,
  GenderType,
  GymApparatus,
  ScoreType,
  PenaltyType,
} from '../../models';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from '../../redux/_actions';
import { convertMillipointsToDisplay } from '../../utilities/scoring';
import { sessionRoleType } from '../../redux/_constants';
import {
  getEvalConfig,
  getDisplayConfig,
  getVideoConfig,
} from '../../utilities/session';
import { useNetwork } from '../../utilities/network';
import { GetFullSession } from '../../apollo/queries/GetFullSession.graphql';
import { PrefetchSession } from '../../apollo/queries/PrefetchSession.graphql';
import { GetRotations } from '../../apollo/queries/GetRotations.graphql';
import { GetRoster } from '../../apollo/queries/GetRoster.graphql';
import { GetFullTeam } from '../../apollo/queries/GetFullTeam.graphql';
import { GetOverlays } from '../../apollo/queries/GetOverlays.graphql';
import { cacheIdForNode } from '../../utilities/apollo';
import { teamBrand } from '../../utilities/conversions';
import retry from 'async-retry';

import SubscribeToSession from '../../apollo/subscriptions/SubscribeToSession.graphql';
import SubscribeToNewRoutineBySession from '../../apollo/subscriptions/SubscribeToNewRoutineBySession.graphql';
import SubscribeToNewScoreBySession from '../../apollo/subscriptions/SubscribeToNewScoreBySession.graphql';
import SubscribeToNewInquiryBySession from '../../apollo/subscriptions/SubscribeToNewInquiryBySession.graphql';
import SubscribeToNewPenaltyBySession from '../../apollo/subscriptions/SubscribeToNewPenaltyBySession.graphql';
import SubscribeToRoutinesBySession from '../../apollo/subscriptions/SubscribeToRoutinesBySession.graphql';
import SubscribeToRotationsBySession from '../../apollo/subscriptions/SubscribeToRotationsBySession.graphql';
import SubscribeToInquiriesBySession from '../../apollo/subscriptions/SubscribeToInquiriesBySession.graphql';
import SubscribeToPenaltiesBySession from '../../apollo/subscriptions/SubscribeToPenaltiesBySession.graphql';
import SubscribeToClipsBySession from '../../apollo/subscriptions/SubscribeToClipsBySession.graphql';
import SubscribeToNewClipBySession from '../../apollo/subscriptions/SubscribeToNewClipBySession.graphql';
import SubscribeToNewOverlayBySession from '../../apollo/subscriptions/SubscribeToNewOverlayBySession.graphql';
import SubscribeToLineupsBySession from '../../apollo/subscriptions/SubscribeToLineupsBySession.graphql';
import SubscribeToStagesBySession from '../../apollo/subscriptions/SubscribeToStagesBySession.graphql';
import SubscribeToNewLineupMemberBySession from '../../apollo/subscriptions/SubscribeToNewLineupMemberBySession.graphql';
import SubscribeToLineupMembersBySession from '../../apollo/subscriptions/SubscribeToLineupMembersBySession.graphql';

import SyncSessionFragment from '../../apollo/fragments/SyncSessionFragment.graphql';
import SimpleLineupFragment from '../../apollo/fragments/SimpleLineupFragment.graphql';
import FullClipFragment from '../../apollo/fragments/FullClipFragment.graphql';
import FullInquiryFragment from '../../apollo/fragments/FullInquiryFragment.graphql';
import FullScoreFragment from '../../apollo/fragments/FullScoreFragment.graphql';
import NewRoutineFragment from '../../apollo/fragments/NewRoutineFragment.graphql';
import RotationFragment from '../../apollo/fragments/RotationFragment.graphql';
import StageFragment from '../../apollo/fragments/StageFragment.graphql';
import LineupMemberFragment from '../../apollo/fragments/LineupMemberFragment.graphql';
import NewLineupMemberFragment from '../../apollo/fragments/NewLineupMemberFragment.graphql';
import FullPenaltyFragment from '../../apollo/fragments/FullPenaltyFragment.graphql';

export function useSessionByKey({ sessionKey }) {
  const { data, loading } = useQuery(PrefetchSession, {
    variables: { sessionKey },
    skip: !sessionKey,
  });

  if (!data) {
    return {};
  }

  return {
    session: data.SessionByKey.items?.[0] || {},
    loading,
  };
}

export function useSession(sessionId) {
  const { data } = useQuery(GetFullSession, {
    variables: { id: sessionId },
    skip: !sessionId,
  });
  if (!data) {
    return {};
  }

  return data?.getSession || {};
}

export function useExperimental({ sessionId }) {
  //const sessionIds = ['46fbd0aa-7f16-4c05-8a9e-4a8880a04432', ''];
  const [evalRoutine, setEvalRoutine] = useState(null);
  const [activeLineup, setActiveLineup] = useState(null);
  const [activeApparatus, setActiveApparatus] = useState(null);
  const { data } = useQuery(GetFullSession, { variables: { id: sessionId } });

  if (!data) {
    return {};
  }

  const session = data.getSession;
  const meanLogo = 'upload/images/team/M5_W8pqd0pPhDSGVb6kTh';
  const defaultLogo = 'upload/images/team/RsWFNLabmofKFY8Ro4Yo1';
  const mlkLogo = 'upload/images/team/YdHjIR98J5mEHtZFMLXhR';

  const isMeanGirls = [
    'vpNBY1X6yS',
    'rUwuGGB1nO',
    'Bpbv1cfjxZ',
    'Ul9Rd2Gda4',
    'MXlgAKlhyI',
    'YsDhb1jEoe',
  ].includes(session.sessionKey);

  const isSprouts = [
    'vWnQNu9hy1',
    'HEhOhRGmU0',
    'GMdHlGf2Fx'
  ].includes(session.sessionKey);

  const isMLK = ['D2y_LD_kzm', 'EGLSm2Mk94'].includes(session.sessionKey);

  const logo = isMeanGirls ? meanLogo : isMLK ? mlkLogo : defaultLogo;

  const roundCountSolo = true;
  const isExperimental = !!session.experimental || true;

  return {
    logo,
    roundCountSolo,
    session,
    isExperimental,
    evalRoutine,
    setEvalRoutine,
    activeLineup,
    setActiveLineup,
    activeApparatus,
    setActiveApparatus,
    isMeanGirls,
    isSprouts
  };
}

export function useJudgesByApparatus(sessionId) {
  const { data } = useQuery(GetFullSession, { variables: { id: sessionId } });
  if (!data) {
    return {};
  }

  const session = data.getSession;

  const judgesByApparatus =
    session.gender === GenderType.FEMALE
      ? {
          [GymApparatus.VT]: { headJudge: null, rest: {}, component: {} },
          [GymApparatus.UB]: { headJudge: null, rest: {}, component: {} },
          [GymApparatus.BB]: { headJudge: null, rest: {}, component: {} },
          [GymApparatus.FX]: { headJudge: null, rest: {}, component: {} },
        }
      : {
          [GymApparatus.FX]: { headJudge: null, rest: {}, component: {} },
          [GymApparatus.PH]: { headJudge: null, rest: {}, component: {} },
          [GymApparatus.SR]: { headJudge: null, rest: {}, component: {} },
          [GymApparatus.VT]: { headJudge: null, rest: {}, component: {} },
          [GymApparatus.PB]: { headJudge: null, rest: {}, component: {} },
          [GymApparatus.HB]: { headJudge: null, rest: {}, component: {} },
        };

  const headJudgeScoreType =
    session.gender === GenderType.FEMALE ? ScoreType.J1 : ScoreType.D;

  // Judge configuration
  session.rotations.items.forEach((rotation) => {
    if (!rotation._deleted) {
      rotation.stages.items.forEach((stage) => {
        if (!stage._deleted) {
          const judgesForStage = judgesByApparatus[stage.apparatus];
          stage.judges.items.forEach((stageJudge) => {
            if (!stageJudge._deleted) {
              const thisJudgeId = stageJudge.sessionJudgeAssignment.judge.id;
              if (stageJudge.scoreTypes.indexOf(headJudgeScoreType) !== -1) {
                if (!judgesForStage.headJudge) {
                  judgesForStage.headJudge = stageJudge;
                } else if (
                  judgesForStage.headJudge.sessionJudgeAssignment.judge.id !==
                    thisJudgeId &&
                  !judgesForStage.rest[thisJudgeId]
                ) {
                  judgesForStage.rest[thisJudgeId] = stageJudge;
                }
              } else if (!judgesForStage.rest[thisJudgeId]) {
                judgesForStage.rest[thisJudgeId] = stageJudge;
              }
              stageJudge.scoreTypes.forEach((s) => {
                judgesForStage.component[s] =
                  stageJudge?.sessionJudgeAssignment?.judge?.name || '';
              });
            }
          });
        }
      });
    }
  });

  return judgesByApparatus;
}

export function useTeamsByAthleteId(sessionId) {
  const { data } = useQuery(GetFullSession, {
    variables: { id: sessionId },
    skip: !sessionId,
  });
  if (!data) {
    return {};
  }

  return data.getSession.rosters.items.reduce((acc, sessionRoster) => {
    if (!sessionRoster._deleted) {
      acc = {
        ...acc,
        ...sessionRoster.roster.athleteContexts.items.reduce(
          (innerAcc, rosterAthleteContext) => {
            if (!rosterAthleteContext._deleted) {
              // Check if additional rosterContext in place, prefer the additional one without lineupId
              let accVal = acc[rosterAthleteContext.athleteContext.athleteId];

              if (accVal && !!sessionRoster?.lineupId) {
                return innerAcc;
              }

              innerAcc[rosterAthleteContext.athleteContext.athleteId] =
                sessionRoster.roster.team;
            }
            return innerAcc;
          },
          {}
        ),
      };
    }
    return acc;
  }, {});
}

export function useAthletesByAthleteId(sessionId) {
  const { data } = useQuery(GetFullSession, {
    variables: { id: sessionId },
    skip: !sessionId,
  });
  if (!data) {
    return {};
  }

  return data.getSession.rosters.items.reduce((acc, sessionRoster) => {
    if (!sessionRoster._deleted) {
      acc = {
        ...acc,
        ...sessionRoster.roster.athleteContexts.items.reduce(
          (innerAcc, rosterAthleteContext) => {
            if (!rosterAthleteContext._deleted) {
              innerAcc[rosterAthleteContext.athleteContext.athleteId] =
                rosterAthleteContext.athleteContext.athlete;
            }
            return innerAcc;
          },
          {}
        ),
      };
    }
    return acc;
  }, {});
}

export function useRoster(rosterId) {
  const { data } = useQuery(GetRoster, { variables: { rosterId } });
  if (!data) {
    return {};
  }

  return data?.getRoster;
}

export function useFullTeam(teamId) {
  const { data, refetch } = useQuery(GetFullTeam, {
    variables: { id: teamId },
    skip: !teamId,
  });
  if (!data) {
    return {};
  }

  return { ...data?.getTeam, refetch };
}

export function useRosterAthletes(rosterId) {
  const { data, loading } = useQuery(GetRoster, {
    variables: { id: rosterId },
    skip: !rosterId,
  });

  if (!data) {
    return { loading };
  }

  const result = data.getRoster.athletes.items.reduce((acc, rosterLink) => {
    if (!rosterLink._deleted && rosterLink.active) {
      acc[rosterLink.id] = rosterLink;
    }
    return acc;
  }, {});

  return result;
}

export function useRosters(sessionId) {
  const { data } = useQuery(GetFullSession, { variables: { id: sessionId } });
  if (!data) {
    return {};
  }

  return data.getSession.rosters.items.reduce((acc, sessionRoster) => {
    if (!sessionRoster._deleted) {
      acc[sessionRoster.id] = sessionRoster;
    }
    return acc;
  }, {});
}

export function useRotationsOnly(sessionId) {
  const { data } = useQuery(GetRotations, { variables: { sessionId } });
  if (!data) {
    return {};
  }

  return data.SessionRotations.items.reduce((acc, sessionRotation) => {
    if (!sessionRotation._deleted) {
      acc[sessionRotation.order] = sessionRotation;
    }
    return acc;
  }, {});
}

export function useRotations(sessionId) {
  const { data } = useQuery(GetFullSession, { variables: { id: sessionId } });
  if (!data) {
    return {};
  }

  return data.getSession.rotations.items.reduce((acc, sessionRotation) => {
    if (!sessionRotation._deleted) {
      acc[sessionRotation.order] = sessionRotation;
    }
    return acc;
  }, {});
}

export function useLineups(sessionId) {
  const { data } = useQuery(GetFullSession, { variables: { id: sessionId } });

  if (!data) {
    return {};
  }

  return data.getSession.lineups.items.reduce((acc, lineup) => {
    if (!lineup._deleted) {
      acc[lineup.id] = lineup;
    }
    return acc;
  }, {});
}

export function useSortedLineups(sessionId) {
  const session = useSession(sessionId);
  const lineups = session?.lineups || [];
  return lineups.items
    .filter((lineup) => !lineup._deleted)
    .slice()
    .sort((a, b) => (a.order > b.order ? 1 : -1));
}

export function useLineupsRotationApparatus(sessionId) {
  const rotations = useRotations(sessionId);
  const lineups = useLineups(sessionId);
  const lineupsByRotationApparatus = {};

  if (!Object.keys(lineups).length || !Object.keys(rotations).length) {
    return lineupsByRotationApparatus;
  }

  Object.keys(lineups).forEach((l) => {
    lineupsByRotationApparatus[l] = {};
    for (let i = 0; i < Object.keys(rotations).length; i++) {
      // console.log(lineupsByRotationApparatus[l]);
      lineupsByRotationApparatus[l][i + 1] = '';
      for (let j = 0; j < rotations[i].stages.items.length; j++) {
        rotations[i].stages.items[j]?.squad?.rosters?.items
          .filter((sr) => !sr._deleted)
          .forEach((rs) => {
            if (rs?.lineupId === l) {
              lineupsByRotationApparatus[l][i + 1] =
                rotations[i].stages.items[j].apparatus;
            }
          });
      }
    }
  });

  return lineupsByRotationApparatus;
}

export function useRotationsByLineup(sessionId) {
  // Returns Object by Rotation #, by Apparatus Abbv. with associated team
  const rotations = useRotations(sessionId);
  const lineups = useLineups(sessionId);
  const rotationsByLineup = {};

  for (let i = 0; i < Object.keys(rotations).length; i++) {
    let stages = {};
    const filteredStages = rotations[i].stages.items.filter(
      (stage) => !stage._deleted
    );

    for (let j = 0; j < filteredStages.length; j++) {
      stages[filteredStages[j].apparatus] = filteredStages?.[
        j
      ]?.squad?.rosters?.items
        .filter((sr) => !sr._deleted)
        .slice()
        .sort((a, b) => a.squadStartPosition - b.squadStartPosition)
        .map((rs) => lineups?.[rs?.lineupId]);
    }
    rotationsByLineup[i + 1] = stages;
  }

  return rotationsByLineup;
}

export function useRotationsByTeam(sessionId) {
  // Returns Object by Rotation #, by Apparatus Abbv. with associated team
  const rotations = useRotations(sessionId);
  const rotationsByTeam = {};

  for (let i = 0; i < Object.keys(rotations).length; i++) {
    let stages = {};
    const filteredStages = rotations[i].stages.items.filter(
      (stage) => !stage._deleted
    );

    for (let j = 0; j < filteredStages.length; j++) {
      stages[filteredStages[j].apparatus] = filteredStages[
        j
      ]?.squad?.rosters?.items
        .filter((sr) => !sr._deleted)
        .slice()
        .sort((a, b) => a.squadStartPosition - b.squadStartPosition)
        .map((rs) => rs?.roster?.team);
    }
    rotationsByTeam[i + 1] = stages;
  }

  return rotationsByTeam;
}

export function useRotationsBySessionRoster(sessionId) {
  // Returns Object by Rotation #, by Apparatus Abbv. with associated team
  const rotations = useRotations(sessionId);
  const rotationsBySessionRoster = {};

  for (let i = 0; i < Object.keys(rotations).length; i++) {
    let stages = {};
    const filteredStages = rotations[i].stages.items.filter(
      (stage) => !stage._deleted
    );

    for (let j = 0; j < filteredStages.length; j++) {
      stages[filteredStages[j].apparatus] = filteredStages[
        j
      ]?.squad?.rosters?.items
        .filter((sr) => !sr._deleted)
        .slice()
        .sort((a, b) => a.squadStartPosition - b.squadStartPosition)
        .map((rs) => rs?.roster);
    }
    rotationsBySessionRoster[i + 1] = stages;
  }

  return rotationsBySessionRoster;
}

function useRefreshSessionOnReconnect(sessionId) {
  const apolloClient = useApolloClient();
  const [wasOffline, setWasOffline] = useState(false);
  const networkState = useNetwork();

  useEffect(() => {
    if (!networkState.online) {
      setWasOffline(true);
    }
  }, [setWasOffline, networkState.online]);

  useEffect(() => {
    if (wasOffline && networkState.online) {
      retry(
        (bail) =>
          apolloClient
            .query({
              query: GetFullSession,
              variables: { id: sessionId },
              fetchPolicy: 'network-only',
            })
            .then(
              () => {
                setWasOffline(false);
              },
              (error) => {
                // TODO: there are probably types of errors that should make us bail() here
                throw error;
              }
            ),
        { retries: 5 }
      );
    }
  }, [wasOffline, networkState.online]);
}

function useSessionSubscriptions(sessionId) {
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();
  const displayConfig = useDisplayConfig();

  useEffect(() => {
    const sessionSubscription = API.graphql(
      graphqlOperation(SubscribeToSession, { id: sessionId })
    ).subscribe({
      next: ({ provider, value }) => {
        const session = value.data.subscribeToSession;

        const prevSession = apolloClient.readFragment({
          id: cacheIdForNode(session),
          fragment: SyncSessionFragment,
          fragmentName: 'SyncSessionFragment',
        });
        apolloClient.writeFragment({
          id: cacheIdForNode(session),
          fragment: SyncSessionFragment,
          fragmentName: 'SyncSessionFragment',
          data: {
            ...prevSession,
            ...session,
          },
        });
      },
      error: (error) => {
        console.warn(error);
      },
    });

    const rotationSubscription = API.graphql(
      graphqlOperation(SubscribeToRotationsBySession, { sessionId })
    ).subscribe({
      next: ({ provider, value }) => {
        const rotation = value.data.subscribeToRotationsBySession;
        apolloClient.writeFragment({
          id: cacheIdForNode(rotation),
          fragment: RotationFragment,
          data: rotation,
        });
      },
      error: (error) => {
        console.warn(error);
      },
    });

    const lineupSubscription = API.graphql(
      graphqlOperation(SubscribeToLineupsBySession, { sessionId })
    ).subscribe({
      next: ({ provider, value }) => {
        const lineup = value.data.subscribeToLineupsBySession;
        apolloClient.writeFragment({
          id: cacheIdForNode(lineup),
          fragment: SimpleLineupFragment,
          data: lineup,
        });
      },
      error: (error) => {
        console.warn(error);
      },
    });

    const newClipSubscription = API.graphql(
      graphqlOperation(SubscribeToNewClipBySession, { sessionId })
    ).subscribe({
      next: ({ provider, value }) => {
        const clip = value.data.subscribeToNewClipBySession;
        apolloClient.writeFragment({
          id: cacheIdForNode(clip),
          fragment: FullClipFragment,
          fragmentName: 'FullClipFragment',
          data: clip,
        });
      },
      error: (error) => {
        console.warn(error);
      },
    });

    const clipsSubscription = API.graphql(
      graphqlOperation(SubscribeToClipsBySession, { sessionId })
    ).subscribe({
      next: ({ provider, value }) => {
        const clip = value.data.subscribeToClipsBySession;
        apolloClient.writeFragment({
          id: cacheIdForNode(clip),
          fragment: FullClipFragment,
          fragmentName: 'FullClipFragment',
          data: clip,
        });
      },
      error: (error) => {
        console.warn(error);
      },
    });

    const newRoutineSubscription = API.graphql(
      graphqlOperation(SubscribeToNewRoutineBySession, { sessionId })
    ).subscribe({
      next: ({ provider, value }) => {
        const routine = value.data.subscribeToNewRoutineBySession;
        apolloClient.writeFragment({
          id: cacheIdForNode(routine),
          fragment: NewRoutineFragment,
          fragmentName: 'NewRoutineFragment',
          data: routine,
        });
      },
      error: (error) => {
        console.warn(error);
      },
    });

    const routinesSubscription = API.graphql(
      graphqlOperation(SubscribeToRoutinesBySession, { sessionId })
    ).subscribe({
      next: ({ provider, value }) => {
        const routine = value.data.subscribeToRoutinesBySession;
        apolloClient.writeFragment({
          id: cacheIdForNode(routine),
          fragment: NewRoutineFragment,
          fragmentName: 'NewRoutineFragment',
          data: routine,
        });
        if (routine.status === RoutineStatus.COMPLETE) {
          dispatch(
            alertActions.sync(
              `${routine?.athlete?.name}'s ${
                routine.apparatus
              } score is ${convertMillipointsToDisplay(
                routine.score,
                displayConfig.scorePrecision
              )}`
            )
          );
        }
      },
      error: (error) => {
        console.warn(error);
      },
    });

    const newInquirySubscription = API.graphql(
      graphqlOperation(SubscribeToNewInquiryBySession, { sessionId })
    ).subscribe({
      next: ({ provider, value }) => {
        const inquiry = value.data.subscribeToNewInquiryBySession;
        apolloClient.writeFragment({
          id: cacheIdForNode(inquiry),
          fragment: FullInquiryFragment,
          fragmentName: 'FullInquiryFragment',
          data: inquiry,
        });
      },
      error: (error) => {
        console.warn(error);
      },
    });

    const inquiriesSubscription = API.graphql(
      graphqlOperation(SubscribeToInquiriesBySession, { sessionId })
    ).subscribe({
      next: ({ provider, value }) => {
        const inquiry = value.data.subscribeToInquiriesBySession;
        apolloClient.writeFragment({
          id: cacheIdForNode(inquiry),
          fragment: FullInquiryFragment,
          fragmentName: 'FullInquiryFragment',
          data: inquiry,
        });
      },
      error: (error) => {
        console.warn(error);
      },
    });

    const newPenaltySubscription = API.graphql(
      graphqlOperation(SubscribeToNewPenaltyBySession, { sessionId })
    ).subscribe({
      next: ({ provider, value }) => {
        const penalty = value.data.subscribeToNewPenaltyBySession;
        apolloClient.writeFragment({
          id: cacheIdForNode(penalty),
          fragment: FullPenaltyFragment,
          fragmentName: 'FullPenaltyFragment',
          data: penalty,
        });

        const { name } = teamBrand(penalty.lineup.team);

        if (penalty.type === PenaltyType.NEUTRAL) {
          dispatch(
            alertActions.sync(
              `${name} received a ${convertMillipointsToDisplay(
                penalty.value,
                1
              )} team deduction`
            )
          );
        }
        if (penalty.type === PenaltyType.YELLOWCARD) {
          dispatch(
            alertActions.sync(
              `${name} received ${penalty.value} yellow card(s)`
            )
          );
        }
      },
      error: (error) => {
        console.warn(error);
      },
    });

    const penaltiesSubscription = API.graphql(
      graphqlOperation(SubscribeToPenaltiesBySession, { sessionId })
    ).subscribe({
      next: ({ provider, value }) => {
        const penalty = value.data.subscribeToPenaltiesBySession;
        apolloClient.writeFragment({
          id: cacheIdForNode(penalty),
          fragment: FullPenaltyFragment,
          fragmentName: 'FullPenaltyFragment',
          data: penalty,
        });

        const { name } = teamBrand(penalty.lineup.team);

        if (penalty.type === PenaltyType.NEUTRAL) {
          dispatch(
            alertActions.sync(
              `${name} updated ${convertMillipointsToDisplay(
                penalty.value,
                1
              )} team deduction`
            )
          );
        }
        if (penalty.type === PenaltyType.YELLOWCARD) {
          dispatch(
            alertActions.sync(`${name} updated ${penalty.value} yellow card(s)`)
          );
        }
      },
      error: (error) => {
        console.warn(error);
      },
    });

    const newScoreSubscription = API.graphql(
      graphqlOperation(SubscribeToNewScoreBySession, { sessionId })
    ).subscribe({
      next: ({ provider, value }) => {
        const score = value.data.subscribeToNewScoreBySession;
        // console.log('scoresubscription', { score });
        apolloClient.writeFragment({
          id: cacheIdForNode(score),
          fragment: FullScoreFragment,
          fragmentName: 'FullScoreFragment',
          data: score,
        });
      },
      error: (error) => {
        console.warn(error);
      },
    });

    const stagesSubscription = API.graphql(
      graphqlOperation(SubscribeToStagesBySession, { sessionId })
    ).subscribe({
      next: ({ provider, value }) => {
        const stage = value.data.subscribeToStagesBySession;
        apolloClient.writeFragment({
          id: cacheIdForNode(stage),
          fragment: StageFragment,
          data: stage,
        });
      },
      error: (error) => {
        console.warn(error);
      },
    });

    const newLineupMemberSubscription = API.graphql(
      graphqlOperation(SubscribeToNewLineupMemberBySession, { sessionId })
    ).subscribe({
      next: ({ provider, value }) => {
        const lineupMember = value.data.subscribeToNewLineupMemberBySession;
        apolloClient.writeFragment({
          id: cacheIdForNode(lineupMember),
          fragment: NewLineupMemberFragment,
          fragmentName: 'NewLineupMemberFragment',
          data: lineupMember,
        });
      },
      error: (error) => {
        console.warn(error);
      },
    });

    const lineupMembersSubscription = API.graphql(
      graphqlOperation(SubscribeToLineupMembersBySession, { sessionId })
    ).subscribe({
      next: ({ provider, value }) => {
        const lineupMember = value.data.subscribeToLineupMembersBySession;
        apolloClient.writeFragment({
          id: cacheIdForNode(lineupMember),
          fragment: LineupMemberFragment,
          data: lineupMember,
        });
      },
      error: (error) => {
        console.warn(error);
      },
    });

    return () => {
      sessionSubscription.unsubscribe();
      rotationSubscription.unsubscribe();
      lineupSubscription.unsubscribe();
      newClipSubscription.unsubscribe();
      clipsSubscription.unsubscribe();
      newRoutineSubscription.unsubscribe();
      routinesSubscription.unsubscribe();
      newInquirySubscription.unsubscribe();
      newPenaltySubscription.unsubscribe();
      inquiriesSubscription.unsubscribe();
      penaltiesSubscription.unsubscribe();
      newScoreSubscription.unsubscribe();
      stagesSubscription.unsubscribe();
      newLineupMemberSubscription.unsubscribe();
      lineupMembersSubscription.unsubscribe();
    };
  }, [apolloClient, dispatch, sessionId]);
}

export function useOverlaySubscriptions(sessionId) {
  const apolloClient = useApolloClient();

  useEffect(() => {
    const overlaySubscription = API.graphql(
      graphqlOperation(SubscribeToNewOverlayBySession, { sessionId })
    ).subscribe({
      next: ({ provider, value }) => {
        const overlay = value.data.subscribeToNewOverlayBySession;
        const original = apolloClient.readQuery({
          query: GetOverlays,
          variables: { sessionId },
        });
        const alreadyHaveNewItem = original.SessionOverlays.items.some(
          (o) => o.id === overlay.id
        );

        if (!alreadyHaveNewItem) {
          apolloClient.writeQuery({
            query: GetOverlays,
            variables: {
              sessionId,
            },
            data: {
              SessionOverlays: {
                ...original.SessionOverlays,
                items: [...original.SessionOverlays.items, overlay],
              },
            },
          });
        }
      },
      error: (error) => {
        console.warn(error);
      },
    });
    return () => overlaySubscription.unsubscribe();
  }, [apolloClient, sessionId]);
}

export function useJudgingEnabled() {
  const sessionRole = useSelector((state) => state.session.role);
  return [sessionRoleType.ADMIN, sessionRoleType.JUDGE].includes(sessionRole);
}

export function useEvalConfig() {
  const sessionRole = useSelector((state) => state.session.role);
  const sessionJudgePanel = useSelector((state) => state.session.judgePanel);
  const sessionGender = useSelector((state) => state.session.gender);
  return getEvalConfig(sessionRole, sessionJudgePanel, sessionGender);
}

export function useDisplayConfig() {
  const sessionJudgePanel = useSelector((state) => state.session.judgePanel);
  return getDisplayConfig(sessionJudgePanel);
}

export function useVideoConfig() {
  const sessionStreams = useSelector((state) => state.session.streams?.items);
  return getVideoConfig(sessionStreams);
}

export function OverlaySubscriptions({ sessionId }) {
  const networkState = useNetwork();

  return networkState.online ? (
    <OverlaySubscriptionsInner sessionId={sessionId} />
  ) : null;
}

function OverlaySubscriptionsInner({ sessionId }) {
  useOverlaySubscriptions(sessionId);
  return null;
}

// TODO: A 'hooks' file is a strange place for this component to live
export function SessionSubscriptionManager({ sessionId }) {
  useRefreshSessionOnReconnect(sessionId);
  useSessionSubscriptions(sessionId);
  return null;
}
