import { adminConstants, streamRequestType, QUERY_SIZE } from '../_constants';
import { alertActions } from '../_actions';
import { normalize, schema } from 'normalizr';
import { adminService, sessionService } from '../_services';
import { dataUtilities } from '../../utilities/data';
import { store } from '../store';
import { flatMap } from 'lodash';

export const adminActions = {
  getRosterAthletes,
  getTeamRosterAthletes,
  getTeams,
  getLeagues,
  getAthletes,
  getSessions,
  getSession,
  getUsers,
  sort,
  createLeague,
  editLeague,
  createTeam,
  editTeam,
  createAthlete,
  editAthlete,
  getTeamAthletes,
  createRoster,
  editRoster,
  updateTable,
  editSession,
  checkStreamPostgame,
  updateStreamURLs,
  editUser,
  updateJudges,
  getJudges,
  updateLineupTitles,
  //createSession,
  //createAthlete,
  //editAthletes
};

// Normalized data schema
const athlete = new schema.Entity('athletes');
const rosterLink = new schema.Entity('rosterLinks');
const roster = new schema.Entity('rosters', {
  athletes: {
    items: [rosterLink],
  },
});
const stream = new schema.Entity('streams');
const lineup = new schema.Entity('lineups');
const sessionTeam = new schema.Entity('sessionTeams');
const sessionJudge = new schema.Entity('sessionJudges');
const coach = new schema.Entity('coaches');
const user = new schema.Entity('users');
const teamLeague = new schema.Entity('leagues');
const league = new schema.Entity('leagues', {});
const team = new schema.Entity('teams', {
  rosters: {
    items: [roster],
  },
  lineups: {
    items: [lineup],
  },
  coaches: {
    items: [coach],
  },
  leagues: {
    items: [league],
  },
});

const session = new schema.Entity('sessions', {
  sessionTeams: {
    items: [sessionTeam],
  },
  sessionJudges: {
    items: [sessionJudge],
  },
  streams: {
    items: [stream],
  },
  lineups: {
    items: [lineup],
  },
  teams: {
    items: [team],
  },
});

league.define({
  teams: {
    items: [team],
  },
});

function sort(sortBy, byId, allIds, newItem = null) {
  const sortedResult = dataUtilities.sort(sortBy, byId, allIds, newItem);

  return { type: sortBy, result: sortedResult };
}

function getUsers(params = null) {
  const { allIds, byId, tokens } = store.getState().admin.users;
  const { nextToken, searchToken, searchQuery } = tokens;
  const newSearchQuery = params?.searchQuery || '';
  const isSearch = newSearchQuery !== '';

  return (dispatch) => {
    dispatch(request());

    if (allIds.length !== 0 && nextToken === null && params === null) {
      dispatch(
        success({
          users: { entities: { users: byId }, result: allIds },
          tokens: tokens,
        })
      );
    } else {
      adminService
        .searchUsers(params)
        .then((res) => {
          console.log(res);
          const rawUsers = res.data.searchUsers;
          const normalizedUsers = normalize(rawUsers.items, [user]);
          const resNextToken =
            rawUsers.items.length === 0 ||
            (rawUsers.items.length < QUERY_SIZE &&
              rawUsers.items[rawUsers.items.length - 1].name ===
                rawUsers.nextToken)
              ? null
              : rawUsers?.nextToken;

          // Recombine results with existing results then sort
          // Must check for duplicates and remove
          const sortedResult = dataUtilities.sort(
            adminConstants.SORT_NAME_DESC,
            { ...byId, ...normalizedUsers.entities.users },
            [...allIds, ...normalizedUsers.result]
          );
          const filteredResult = sortedResult.filter((el, pos) => {
            return sortedResult.indexOf(el) === pos;
          });
          const sortedUsers = { ...normalizedUsers, result: filteredResult };
          const newTokens = {
            nextToken: isSearch ? nextToken : resNextToken,
            searchQuery: isSearch ? newSearchQuery : searchQuery,
            searchToken: isSearch ? resNextToken : searchToken,
          };
          dispatch(success({ users: sortedUsers, tokens: newTokens }));
        })
        .catch((error) => {
          console.log(error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error('Error loading users.'));
        });
    }
  };

  function request() {
    return { type: adminConstants.GET_USERS_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.GET_USERS_SUCCESS, data };
  }
  function failure() {
    return { type: adminConstants.GET_USERS_FAILURE };
  }
}

function getJudges(params = null) {
  const { allIds, byId, tokens } = store.getState().admin.judges;
  const { nextToken, searchToken, searchQuery } = tokens;
  const newSearchQuery = params?.searchQuery || '';
  const isSearch = newSearchQuery !== '';

  return (dispatch) => {
    dispatch(request());

    if (allIds.length !== 0 && nextToken === null && params === null) {
      dispatch(
        success({
          judges: { entities: { users: byId }, result: allIds },
          tokens: tokens,
        })
      );
    } else {
      adminService
        .searchJudges(params)
        .then((res) => {
          console.log(res);
          const rawUsers = res.data.searchUsers;
          const normalizedUsers = normalize(rawUsers.items, [user]);
          const resNextToken =
            rawUsers.items.length === 0 ||
            (rawUsers.items.length < QUERY_SIZE &&
              rawUsers.items[rawUsers.items.length - 1].name ===
                rawUsers.nextToken)
              ? null
              : rawUsers?.nextToken;

          // Recombine results with existing results then sort
          // Must check for duplicates and remove
          const sortedResult = dataUtilities.sort(
            adminConstants.SORT_NAME_DESC,
            { ...byId, ...normalizedUsers.entities.users },
            [...allIds, ...normalizedUsers.result]
          );
          const filteredResult = sortedResult.filter((el, pos) => {
            return sortedResult.indexOf(el) === pos;
          });
          const sortedUsers = { ...normalizedUsers, result: filteredResult };
          const newTokens = {
            nextToken: isSearch ? nextToken : resNextToken,
            searchQuery: isSearch ? newSearchQuery : searchQuery,
            searchToken: isSearch ? resNextToken : searchToken,
          };
          dispatch(success({ judges: sortedUsers, tokens: newTokens }));
        })
        .catch((error) => {
          console.log(error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error('Error loading judges.'));
        });
    }
  };

  function request() {
    return { type: adminConstants.GET_JUDGES_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.GET_JUDGES_SUCCESS, data };
  }
  function failure() {
    return { type: adminConstants.GET_JUDGES_FAILURE };
  }
}

function getAthletes(params = null) {
  const { allIds, byId, tokens } = store.getState().admin.athletes;
  const { nextToken, searchToken, searchQuery } = tokens;
  const newSearchQuery = params?.searchQuery || '';
  const isSearch = newSearchQuery !== '';

  return (dispatch) => {
    dispatch(request());

    if (allIds.length !== 0 && nextToken === null && params === null) {
      dispatch(
        success({
          athletes: { entities: { athletes: byId }, result: allIds },
          tokens: tokens,
        })
      );
    } else {
      adminService
        .searchAthletes(params)
        .then((res) => {
          console.log(res);
          const rawAthletes = res.data.searchAthletes;
          const normalizedAthletes = normalize(rawAthletes.items, [athlete]);
          const resNextToken =
            rawAthletes.items.length === 0 ||
            (rawAthletes.items.length < QUERY_SIZE &&
              rawAthletes.items[rawAthletes.items.length - 1].name ===
                rawAthletes.nextToken)
              ? null
              : rawAthletes?.nextToken;

          // Recombine results with existing results then sort
          // Must check for duplicates and remove
          const sortedResult = dataUtilities.sort(
            adminConstants.SORT_NAME_DESC,
            { ...byId, ...normalizedAthletes.entities.athletes },
            [...allIds, ...normalizedAthletes.result]
          );
          const filteredResult = sortedResult.filter((el, pos) => {
            return sortedResult.indexOf(el) === pos;
          });
          const sortedAthletes = {
            ...normalizedAthletes,
            result: filteredResult,
          };
          const newTokens = {
            nextToken: isSearch ? nextToken : resNextToken,
            searchQuery: isSearch ? newSearchQuery : searchQuery,
            searchToken: isSearch ? resNextToken : searchToken,
          };
          dispatch(success({ athletes: sortedAthletes, tokens: newTokens }));
        })
        .catch((error) => {
          console.log(error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error('Error loading athletes.'));
        });
    }
  };

  function request() {
    return { type: adminConstants.GET_ATHLETES_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.GET_ATHLETES_SUCCESS, data };
  }
  function failure() {
    return { type: adminConstants.GET_ATHLETES_FAILURE };
  }
}

function getSession(params = null) {
  const { allIds, byId } = store.getState().admin.sessions;

  return (dispatch) => {
    dispatch(request());

    sessionService
      .load(params)
      .then((res) => {
        console.log(res);
        const sessions = res.data.SessionByKey;

        const normalizedSession = normalize(sessions.items, [session]);
        const {
          sessionTeams,
          streams,
          sessionJudges,
          lineups,
        } = normalizedSession.entities;

        // const filteredSessionTeams = Object.values(sessionTeams).reduce(
        //   (acc, st) => {
        //     if (!st?._deleted) {
        //       acc[st.id] = st;
        //     }
        //     return acc;
        //   },
        //   {}
        // );

        // normalizedSession.entities.sessionTeams = filteredSessionTeams;

        const normalizedSessionTeams = sessionTeams
          ? normalize(sessionTeams, [sessionTeam])
          : null;

        const normalizedStreams = streams ? normalize(streams, [stream]) : null;
        const normalizedSessionJudges = sessionJudges
          ? normalize(sessionJudges, [sessionJudge])
          : null;
        const normalizedLineups = lineups ? normalize(lineups, [lineup]) : null;

        const normalizedTeams =
          (normalizedSessionTeams &&
            normalize(
              Object.values(normalizedSessionTeams?.entities?.sessionTeams).map(
                (st) => st.team
              ),
              [team]
            )) ||
          {};

        const sortedResult = dataUtilities.sort(
          adminConstants.SORT_NAME_DESC,
          { ...byId, ...normalizedSession.entities.sessions },
          [...allIds, ...normalizedSession.result]
        );

        const filteredResult = sortedResult.filter((el, pos) => {
          return sortedResult.indexOf(el) === pos;
        });
        const sortedSessions = {
          ...normalizedSession,
          result: filteredResult,
        };
        dispatch(
          success({
            sessions: sortedSessions,
            sessionTeams: normalizedSessionTeams,
            streams: normalizedStreams,
            sessionJudges: normalizedSessionJudges,
            lineups: normalizedLineups,
            teams: normalizedTeams,
          })
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error loading session.'));
      });
  };

  function request() {
    return { type: adminConstants.GET_SESSION_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.GET_SESSION_SUCCESS, data };
  }
  function failure() {
    return { type: adminConstants.GET_SESSION_FAILURE };
  }
}

function getSessions(params = null) {
  const { allIds, byId, tokens } = store.getState().admin.sessions;
  const { nextToken, searchToken, searchQuery } = tokens;
  const newSearchQuery = params?.searchQuery || '';
  const isSearch = newSearchQuery !== '';

  return (dispatch) => {
    dispatch(request());

    if (allIds.length !== 0 && nextToken === null && params === null) {
      dispatch(
        success({
          sessions: { entities: { sessions: byId }, result: allIds },
          tokens: tokens,
        })
      );
    } else {
      adminService
        .searchSessions(params)
        .then((res) => {
          console.log(res);
          const rawSessions = res.data.searchSessions;
          const normalizedSessions = normalize(rawSessions.items, [session]);
          const {
            sessionTeams,
            streams,
            sessionJudges,
            lineups,
          } = normalizedSessions.entities; // note teams here are SessionTeams

          // const filteredSessionTeams = Object.values(sessionTeams).reduce(
          //   (acc, st) => {
          //     if (!st?._deleted) {
          //       acc[st.id] = st;
          //     }
          //     return acc;
          //   },
          //   {}
          // );

          // normalizedSessions.entities.sessionTeams = filteredSessionTeams;

          const normalizedSessionTeams = sessionTeams
            ? normalize(sessionTeams, [sessionTeam])
            : null;
          const normalizedStreams = streams
            ? normalize(streams, [stream])
            : null;
          const normalizedSessionJudges = sessionJudges
            ? normalize(sessionJudges, [sessionJudge])
            : null;
          const normalizedLineups = lineups
            ? normalize(lineups, [lineup])
            : null;

          console.log(normalizedSessionTeams);

          const normalizedTeams =
            (normalizedSessionTeams &&
              normalize(
                Object.values(
                  normalizedSessionTeams?.entities?.sessionTeams
                ).map((st) => st.team),
                [team]
              )) ||
            {};

          console.log(normalizedTeams);

          const resNextToken =
            rawSessions.items.length === 0 ||
            (rawSessions.items.length < QUERY_SIZE &&
              new Date(rawSessions.items[rawSessions.items.length - 1]?.startAt)
                .getTime()
                .toString() === rawSessions.nextToken)
              ? null
              : rawSessions?.nextToken;

          const sortedResult = dataUtilities.sort(
            adminConstants.SORT_NAME_DESC,
            { ...byId, ...normalizedSessions.entities.sessions },
            [...allIds, ...normalizedSessions.result]
          );

          const filteredResult = sortedResult.filter((el, pos) => {
            return sortedResult.indexOf(el) === pos;
          });
          const sortedSessions = {
            ...normalizedSessions,
            result: filteredResult,
          };
          const newTokens = {
            nextToken: isSearch ? nextToken : resNextToken,
            searchQuery: isSearch ? newSearchQuery : searchQuery,
            searchToken: isSearch ? resNextToken : searchToken,
          };
          dispatch(
            success({
              sessions: sortedSessions,
              sessionTeams: normalizedSessionTeams,
              streams: normalizedStreams,
              sessionJudges: normalizedSessionJudges,
              lineups: normalizedLineups,
              teams: normalizedTeams,
              tokens: newTokens,
            })
          );
        })
        .catch((error) => {
          console.log(error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error('Error loading sessions.'));
        });
    }

    function request() {
      return { type: adminConstants.GET_SESSIONS_REQUEST };
    }
    function success(data) {
      return { type: adminConstants.GET_SESSIONS_SUCCESS, data };
    }
    function failure() {
      return { type: adminConstants.GET_SESSIONS_FAILURE };
    }
  };
}

function editSession(input, teamChange) {
  return (dispatch) => {
    dispatch(request());
    sessionService
      .update(input, teamChange)
      .then((res) => {
        console.log(res);
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Handle Session updates
        const sessionData =
          res && res.length > 0 && res[0] ? res[0].data.updateSession : null;
        const normalizedSessions = normalize(sessionData, session);
        const newSessionById = {
          ...store.getState().admin.sessions.byId,
          ...(normalizedSessions.entities.sessions
            ? { [input.id]: normalizedSessions.entities.sessions[input.id] }
            : {}),
        };

        // Handle creates and updates
        const creates =
          res && res.length > 1 && res[1]
            ? res[1].map((e) => {
                return e.data.createSessionTeam;
              })
            : [];
        const updates =
          res && res.length > 2 && res[2]
            ? res[2].map((e) => {
                return e.data.updateSessionTeam;
              })
            : [];

        const normalizedCreates =
          creates.length > 0 ? normalize(creates, [sessionTeam]) : null;
        const normalizedUpdates =
          updates.length > 0 ? normalize(updates, [sessionTeam]) : null;

        const { allIds, byId } = store.getState().admin.sessionTeams;
        const sessionTeamsData = {
          allIds: [
            ...allIds,
            ...((normalizedCreates && normalizedCreates.result) || []),
          ],
          byId: {
            ...byId,
            ...((normalizedUpdates &&
              normalizedUpdates.entities.sessionTeams) ||
              {}),
            ...((normalizedCreates &&
              normalizedCreates.entities.sessionTeams) ||
              {}),
          },
        };

        dispatch(
          success({ byId: newSessionById, sessionTeams: sessionTeamsData })
        );
        dispatch(alertActions.success('Session updated successfully.'));
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        switch (error.code) {
          default:
            error.message = 'Update session error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: adminConstants.EDIT_SESSION_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.EDIT_SESSION_SUCCESS, data };
  }
  function failure() {
    return { type: adminConstants.EDIT_SESSION_FAILURE };
  }
}

function getTeams(params = null) {
  const { allIds, byId, tokens } = store.getState().admin.teams;
  const { nextToken, searchToken, searchQuery } = tokens;
  const newSearchQuery = params?.searchQuery || '';
  const isSearch = newSearchQuery !== '';

  return (dispatch) => {
    dispatch(request());

    if (allIds.length !== 0 && nextToken === null && params === null) {
      dispatch(
        success({
          athletes: { entities: { teams: byId }, result: allIds },
          tokens: tokens,
        })
      );
    } else {
      adminService
        .searchTeams(params)
        .then((res) => {
          console.log(res);
          const rawTeams = res.data.searchTeams;
          const normalizedTeams = normalize(rawTeams.items, [team]);
          const {
            lineups,
            rosters,
            coaches,
            leagues,
            rosterLinks,
          } = normalizedTeams.entities;
          const normalizedRosters = rosters
            ? normalize(rosters, [roster])
            : null;
          const normalizedLeagues = leagues
            ? normalize(leagues, [teamLeague])
            : null;
          const normalizedLineups = lineups
            ? normalize(lineups, [lineup])
            : null;
          const normalizedCoaches = coaches
            ? normalize(coaches, [coach])
            : null;
          const normalizedRosterLinks = rosterLinks
            ? normalize(rosterLinks, [rosterLink])
            : null;

          // Consolidate data for Lineups in case there is a previous data set
          console.log(normalizedLineups);
          //const newLineups = Object.values([...adminLineups.byId, normalizedLineups.data.lineups.entities.lineups])

          const resNextToken =
            rawTeams.items.length === 0 ||
            (rawTeams.items.length < QUERY_SIZE &&
              rawTeams.items[rawTeams.items.length - 1]?.name ===
                rawTeams.nextToken)
              ? null
              : rawTeams?.nextToken;

          const sortedResult = dataUtilities.sort(
            adminConstants.SORT_NAME_DESC,
            { ...byId, ...normalizedTeams.entities.teams },
            [...allIds, ...normalizedTeams.result]
          );

          const filteredResult = sortedResult.filter((el, pos) => {
            return sortedResult.indexOf(el) === pos;
          });
          const sortedTeams = { ...normalizedTeams, result: filteredResult };
          const newTokens = {
            nextToken: isSearch ? nextToken : resNextToken,
            searchQuery: isSearch ? newSearchQuery : searchQuery,
            searchToken: isSearch ? resNextToken : searchToken,
          };
          dispatch(
            success({
              teams: sortedTeams,
              rosters: normalizedRosters,
              leagues: normalizedLeagues,
              coaches: normalizedCoaches,
              lineups: normalizedLineups,
              rosterLinks: normalizedRosterLinks,
              tokens: newTokens,
            })
          );
        })
        .catch((error) => {
          console.log(error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error('Error loading teams.'));
        });
    }
  };

  function request() {
    return { type: adminConstants.GET_TEAMS_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.GET_TEAMS_SUCCESS, data };
  }
  function failure() {
    return { type: adminConstants.GET_TEAMS_FAILURE };
  }
}

function getLeagues(params = null) {
  const { allIds, byId, tokens } = store.getState().admin.leagues;
  const { nextToken, searchToken, searchQuery } = tokens;
  const newSearchQuery = params?.searchQuery || '';
  const isSearch = newSearchQuery !== '';

  return (dispatch) => {
    dispatch(request());

    if (allIds.length !== 0 && nextToken === null && params === null) {
      dispatch(
        success({
          leagues: { entities: { leagues: byId }, result: allIds },
          tokens: tokens,
        })
      );
    } else {
      adminService
        .searchLeagues(params)
        .then((res) => {
          console.log(res);
          const rawLeagues = res.data.searchLeagues;
          const normalizedLeagues = normalize(rawLeagues.items, [league]);

          const resNextToken =
            rawLeagues.items.length === 0 ||
            (rawLeagues.items.length < QUERY_SIZE &&
              rawLeagues.items[rawLeagues.items.length - 1].name ===
                rawLeagues.nextToken)
              ? null
              : rawLeagues?.nextToken;

          const sortedResult = dataUtilities.sort(
            adminConstants.SORT_NAME_DESC,
            { ...byId, ...normalizedLeagues.entities.leagues },
            [...allIds, ...normalizedLeagues.result]
          );
          const filteredResult = sortedResult.filter((el, pos) => {
            return sortedResult.indexOf(el) === pos;
          });
          const sortedLeagues = {
            ...normalizedLeagues,
            result: filteredResult,
          };
          const newTokens = {
            nextToken: isSearch ? nextToken : resNextToken,
            searchQuery: isSearch ? newSearchQuery : searchQuery,
            searchToken: isSearch ? resNextToken : searchToken,
          };
          dispatch(success({ leagues: sortedLeagues, tokens: newTokens }));
        })
        .catch((error) => {
          console.log(error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error('Error loading leagues.'));
        });
    }
  };

  function request() {
    return { type: adminConstants.GET_LEAGUES_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.GET_LEAGUES_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.GET_LEAGUES_FAILURE };
  }
}

function createLeague(input) {
  return (dispatch) => {
    dispatch(request());
    // Todo: add to existing loaded leages opportunistically?
    adminService
      .addLeague(input)
      .then((res) => {
        console.log(res);
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Add new item to normalized data & sort
        const data = res[0].data.createLeague;
        const { allIds, byId } = store.getState().admin.leagues;
        const newAllIds = dataUtilities.sort(
          adminConstants.SORT_NAME_DESC,
          byId,
          allIds,
          data
        );
        dispatch(success({ allIds: newAllIds, byId: byId }));
        dispatch(alertActions.success('League created successfully.'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error creating league.'));
      });
  };

  function request() {
    return { type: adminConstants.CREATE_LEAGUE_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.CREATE_LEAGUE_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.CREATE_LEAGUE_FAILURE };
  }
}

function editLeague(input, changedImage = false) {
  return (dispatch) => {
    dispatch(request());
    adminService
      .editLeague(input, changedImage)
      .then((res) => {
        console.log(res);
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Add new item to normalized data & sort
        const data = res[0].data.updateLeague;
        const { byId } = store.getState().admin.leagues;
        const newById = { ...byId, [input.id]: { ...byId[input.id], ...data } };
        dispatch(success({ byId: newById }));
        dispatch(alertActions.success('League updated successfully.'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error updating league.'));
      });
  };

  function request() {
    return { type: adminConstants.EDIT_LEAGUE_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.EDIT_LEAGUE_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.EDIT_LEAGUE_FAILURE };
  }
}

function createTeam(input) {
  return (dispatch) => {
    dispatch(request());
    adminService
      .addTeam(input)
      .then((res) => {
        console.log(res);
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Add new item to normalized data & sort
        const data = res[0].data.createTeam;
        const { allIds, byId } = store.getState().admin.teams;
        const newAllIds = dataUtilities.sort(
          adminConstants.SORT_NAME_DESC,
          byId,
          allIds,
          data
        );
        dispatch(success({ allIds: newAllIds, byId: byId }));
        dispatch(alertActions.success('Team created successfully.'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error creating team.'));
      });
  };

  function request() {
    return { type: adminConstants.CREATE_TEAM_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.CREATE_TEAM_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.CREATE_TEAM_FAILURE };
  }
}

function editTeam(input, changedImage = false) {
  return (dispatch) => {
    dispatch(request());
    adminService
      .editTeam(input, changedImage)
      .then((res) => {
        console.log(res);
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Add new item to normalized data & sort
        const data = res[0].data.updateTeam;
        const { byId } = store.getState().admin.teams;
        const newById = { ...byId, [input.id]: { ...byId[input.id], ...data } };
        dispatch(success({ byId: newById }));
        dispatch(alertActions.success('Team updated successfully.'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error updating team.'));
      });
  };

  function request() {
    return { type: adminConstants.EDIT_TEAM_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.EDIT_TEAM_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.EDIT_TEAM_FAILURE };
  }
}

function createAthlete(input) {
  return (dispatch) => {
    dispatch(request());
    adminService
      .addAthlete(input)
      .then((res) => {
        console.log(res);
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Add new item to normalized data & sort
        const data = res[0].data.createAthlete;
        const { allIds, byId } = store.getState().admin.athletes;
        const newAllIds = dataUtilities.sort(
          adminConstants.SORT_NAME_DESC,
          byId,
          allIds,
          data
        );
        dispatch(success({ allIds: newAllIds, byId: byId }));
        dispatch(alertActions.success('Athlete created successfully.'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error creating athlete.'));
      });
  };

  function request() {
    return { type: adminConstants.CREATE_ATHLETE_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.CREATE_ATHLETE_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.CREATE_ATHLETE_FAILURE };
  }
}

function editAthlete(input, changedImage = false, athlete) {
  return (dispatch) => {
    dispatch(request());
    adminService
      .editAthlete(input, changedImage, athlete)
      .then((res) => {
        console.log(res);
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Add new item to normalized data & sort
        const data = res[0].data.updateAthlete;
        const { byId } = store.getState().admin.athletes;
        const newById = { ...byId, [input.id]: { ...byId[input.id], ...data } };
        dispatch(success({ byId: newById }));
        dispatch(alertActions.success('Athlete updated successfully.'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error updating athlete.'));
      });
  };

  function request() {
    return { type: adminConstants.EDIT_ATHLETE_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.EDIT_ATHLETE_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.EDIT_ATHLETE_FAILURE };
  }
}

function getRosterAthletes(params = null) {
  const { allIds, byId } = store.getState().admin.athletes;

  return (dispatch) => {
    dispatch(request());

    if (allIds.length !== 0 && params === null) {
      dispatch(
        success({
          athletes: { entities: { athletes: byId }, result: allIds },
        })
      );
    } else {
      adminService
        .loadRosterAthletes(params)
        .then((res) => {
          console.log(res);
          const rawRoster = res.data.getRoster;
          const normalizedAthletes = normalize(
            rawRoster.athletes.items.map((rl) => rl.athlete),
            [athlete]
          );

          // Recombine results with existing results then sort
          // Must check for duplicates and remove
          const sortedResult = dataUtilities.sort(
            adminConstants.SORT_NAME_DESC,
            { ...byId, ...normalizedAthletes.entities.athletes },
            [...allIds, ...normalizedAthletes.result]
          );
          const filteredResult = sortedResult.filter((el, pos) => {
            return sortedResult.indexOf(el) === pos;
          });
          const sortedAthletes = {
            ...normalizedAthletes,
            result: filteredResult,
            id: rawRoster.teamId,
          };
          dispatch(
            success({ athletes: sortedAthletes, teamAthletes: filteredResult })
          );
        })
        .catch((error) => {
          console.log(error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error('Error loading roster athletes.'));
        });
    }
  };

  function request() {
    return { type: adminConstants.GET_ROSTER_ATHLETES_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.GET_ROSTER_ATHLETES_SUCCESS, data };
  }
  function failure() {
    return { type: adminConstants.GET_ROSTER_ATHLETES_FAILURE };
  }
}

function getTeamRosterAthletes(params = null) {
  const { allIds, byId } = store.getState().admin.athletes;

  return (dispatch) => {
    dispatch(request());

    if (allIds.length !== 0 && params === null) {
      dispatch(
        success({
          athletes: { entities: { athletes: byId }, result: allIds },
        })
      );
    } else {
      adminService
        .loadTeamRosterAthletes(params)
        .then((res) => {
          console.log(res);
          const rawTeam = res.data.getTeam;
          const rawAthletes = rawTeam.rosters.items.reduce(
            (arr, val) => arr.concat(val.athletes.items),
            []
          );
          const normalizedAthletes = normalize(
            rawAthletes.map((rl) => rl.athlete),
            [athlete]
          );

          // Recombine results with existing results then sort
          // Must check for duplicates and remove
          const sortedResult = dataUtilities.sort(
            adminConstants.SORT_NAME_DESC,
            { ...byId, ...normalizedAthletes.entities.athletes },
            [...allIds, ...normalizedAthletes.result]
          );

          const filteredResult = sortedResult.filter((el, pos) => {
            return sortedResult.indexOf(el) === pos;
          });

          const sortedAthletes = {
            ...normalizedAthletes,
            result: filteredResult,
          };
          dispatch(
            success({
              athletes: sortedAthletes,
              teamAthletes: filteredResult,
              id: rawTeam.id,
            })
          );
        })
        .catch((error) => {
          console.log(error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error('Error loading team roster athletes.'));
        });
    }

    function request() {
      return { type: adminConstants.GET_TEAM_ROSTER_ATHLETES_REQUEST };
    }
    function success(data) {
      return { type: adminConstants.GET_TEAM_ROSTER_ATHLETES_SUCCESS, data };
    }
    function failure(error) {
      return { type: adminConstants.GET_TEAM_ROSTER_ATHLETES_FAILURE };
    }
  };
}

function getTeamAthletes(teamId) {
  const { allIds, byId } = store.getState().admin.athletes;

  return (dispatch) => {
    dispatch(request());
    if (allIds.length === 0) {
      // need to fetch all athletes (this might be problematic when athletes # >>>)
      adminService
        .loadAthletes()
        .then((res) => {
          console.log(res);
          const normalizedAthletes = normalize(res.data.listAthletes.items, [
            athlete,
          ]);
          //const normalizedAthletes = normalize(res[1], [athlete]);
          const sortedResult = dataUtilities.sort(
            adminConstants.SORT_NAME_DESC,
            normalizedAthletes.entities.athletes,
            normalizedAthletes.result
          );
          const sortedAthletes = {
            ...normalizedAthletes,
            result: sortedResult,
          };

          const teamFilteredAthletes = dataUtilities.filterNested(
            teamId,
            normalizedAthletes.entities.athletes,
            sortedResult,
            'teamAffiliations'
          );

          console.log(sortedAthletes);

          dispatch(
            success({
              athletes: sortedAthletes,
              teamAthletes: teamFilteredAthletes,
              id: teamId,
            })
          );
        })
        .catch((error) => {
          console.log(error);
          dispatch(failure(error.toString()));
          dispatch(alertActions.error('Error loading athletes.'));
        });
    } else {
      const teamFilteredAthletes = dataUtilities.filterNested(
        teamId,
        byId,
        allIds,
        'teamAffiliations'
      );

      dispatch(success({ teamAthletes: teamFilteredAthletes, id: teamId }));
    }
  };

  function request() {
    return { type: adminConstants.GET_TEAM_ATHLETES_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.GET_TEAM_ATHLETES_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.GET_TEAM_ATHLETES_FAILURE };
  }
}

function createRoster(input) {
  return (dispatch) => {
    dispatch(request());
    adminService
      .addRoster(input)
      .then((res) => {
        console.log(res);
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Add new item to normalized data & sort
        const data = res.data.createRoster;
        const { allIds, byId } = store.getState().admin.rosters;
        dispatch(
          success({
            allIds: [...allIds, data.id],
            byId: { ...byId, [data.id]: data },
            roster: data,
          })
        );
        dispatch(alertActions.success('Roster created successfully.'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error creating roster.'));
      });
  };

  function request() {
    return { type: adminConstants.CREATE_ROSTER_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.CREATE_ROSTER_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.CREATE_ROSTER_FAILURE };
  }
}

// EditRoster will update 2x items separately, first roster itself, then loop of RosterLinks
function editRoster(input) {
  return (dispatch) => {
    dispatch(request());
    adminService
      .editRoster(input)
      .then((res) => {
        console.log(res);
        // Res is formatted for 3x returns [rosterChange, [createRosterLinks], [updateRosterLinks] ]
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Handle Roster title / time updates, need to normalize the rosterLinks
        const rosterData =
          res && res.length > 0 && res[0] ? res[0].data.updateRoster : null;
        const normalizedRoster = rosterData
          ? normalize(rosterData, roster)
          : null;

        // Handle combine of createRosterLinks and updateRosterLinks
        const creates =
          res && res.length > 1 && res[1]
            ? res[1].map((e) => {
                return e.data.createRosterLink;
              })
            : [];
        const updates =
          res && res.length > 2 && res[2]
            ? res[2].map((e) => {
                return e.data.updateRosterLink;
              })
            : [];

        const normalizedCreates =
          creates.length > 0 ? normalize(creates, [rosterLink]) : null;
        const normalizedUpdates =
          updates.length > 0 ? normalize(updates, [rosterLink]) : null;

        const { allIds, byId } = store.getState().admin.rosterLinks;
        const rosterLinksData = {
          allIds: [
            ...allIds,
            ...((normalizedCreates && normalizedCreates.result) || []),
          ],
          byId: {
            ...byId,
            ...((normalizedUpdates && normalizedUpdates.entities.rosterLinks) ||
              {}),
            ...((normalizedCreates && normalizedCreates.entities.rosterLinks) ||
              {}),
          },
        };

        dispatch(
          success({
            roster: normalizedRoster.entities.rosters[rosterData.id],
            rosterLinks: rosterLinksData,
          })
        );
        dispatch(alertActions.success('Roster updated successfully.'));
        dispatch(
          adminActions.getTeamRosterAthletes({ id: input.roster.teamId })
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error updating roster.'));
      });
  };

  function request() {
    return { type: adminConstants.EDIT_ROSTER_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.EDIT_ROSTER_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.EDIT_ROSTER_FAILURE };
  }
}

function updateTable(data) {
  return { type: adminConstants.UPDATE_TABLE_DATA, data };
}

function checkStreamPostgame(id, type = streamRequestType.CHECKVOD) {
  const { streams } = store.getState().admin;
  const { streamId, _version } = streams?.byId[id];

  return (dispatch) => {
    if (streams.allIds.length === 0) {
      throw new Error('Streams data have not been loaded.');
    }

    const req = {
      type: type,
      id: id,
      _version: _version,
      streamId: streamId, // where this streamId is the Wowza StreamId
    };

    dispatch(request());
    sessionService
      .streamRequest(req)
      .then((res) => {
        console.log(res);
        const data = res.data.createStreamWowza;
        dispatch(success(data));
      })
      .catch((error) => {
        console.log(error);
        const errorDetail =
          error?.errors?.length > 0 ? error.errors[0].message : error;
        dispatch(failure());
        dispatch(alertActions.error(errorDetail));
      });
  };

  function request() {
    return { type: adminConstants.CHECK_STREAM_POSTGAME_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.CHECK_STREAM_POSTGAME_SUCCESS, data };
  }
  function failure() {
    return { type: adminConstants.CHECK_STREAM_POSTGAME_FAILURE };
  }
}

function updateStreamURLs(payload) {
  return (dispatch) => {
    dispatch(request());
    const filteredPayload = payload.filter((el) => el !== false);

    adminService
      .updateStreams(filteredPayload)
      .then((res) => {
        console.log(res);
        const data = Object.assign(
          {},
          ...res.map((x) => ({ [x.data.updateStream.id]: x.data.updateStream }))
        );
        dispatch(success(data));
        dispatch(
          alertActions.success(
            `Stream${
              filteredPayload.length === 1 ? '' : 's'
            } updated successfully.`
          )
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure());
        dispatch(alertActions.error(error));
      });
  };

  function request() {
    return { type: adminConstants.UPDATE_STREAM_URLS_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.UPDATE_STREAM_URLS_SUCCESS, data };
  }
  function failure() {
    return { type: adminConstants.UPDATE_STREAM_URLS_FAILURE };
  }
}

function editUser(input, changedImage = false, user) {
  return (dispatch) => {
    dispatch(request());
    adminService
      .editUser(input, changedImage, user)
      .then((res) => {
        console.log(res);
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Add new item to normalized data & sort
        const data = res[0].data.updateUser;
        const { byId } = store.getState().admin.users;
        const newById = { ...byId, [input.id]: { ...byId[input.id], ...data } };
        dispatch(success({ byId: newById }));
        dispatch(alertActions.success('User updated successfully.'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error updating user.'));
      });
  };

  function request() {
    return { type: adminConstants.EDIT_USER_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.EDIT_USER_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.EDIT_USER_FAILURE };
  }
}

function updateJudges(input) {
  return (dispatch) => {
    dispatch(request());
    adminService
      .updateJudges(input)
      .then((res) => {
        //console.log(res);
        // Res is formatted for 2x returns [ [createSessionJudges], [updateSessionJudges] ]
        if (res.errors) {
          throw res.errors[0].message;
        }

        // Handle combine of createSessionJudges and updateSessionJudges
        const creates =
          res && res.length > 0 && res[0]
            ? res[0].map((e) => {
                return e.data.createSessionJudge;
              })
            : [];
        const updates =
          res && res.length > 1 && res[1]
            ? res[1].map((e) => {
                return e.data.updateSessionJudge;
              })
            : [];

        const normalizedCreates =
          creates.length > 0 ? normalize(creates, [sessionJudge]) : null;
        const normalizedUpdates =
          updates.length > 0 ? normalize(updates, [sessionJudge]) : null;

        const { allIds, byId } = store.getState().admin.sessionJudges;
        const newSessionJudgesData = {
          allIds: [
            ...allIds,
            ...((normalizedCreates && normalizedCreates.result) || []),
          ],
          byId: {
            ...byId,
            ...((normalizedUpdates &&
              normalizedUpdates.entities.sessionJudges) ||
              {}),
            ...((normalizedCreates &&
              normalizedCreates.entities.sessionJudges) ||
              {}),
          },
        };

        // Update session data with the new list
        const sessionsById = store.getState().admin.sessions.byId;
        const newSessionJudgeList = [
          ...sessionsById[input.sessionId].sessionJudges.items.filter(
            (id) =>
              !((normalizedUpdates && normalizedUpdates.result) || []).includes(
                id
              )
          ),
          ...((normalizedCreates && normalizedCreates.result) || []),
        ];
        const newSessionById = {
          ...sessionsById,
          ...{
            [input.sessionId]: {
              ...sessionsById[input.sessionId],
              sessionJudges: {
                items: newSessionJudgeList,
              },
            },
          },
        };

        dispatch(
          success({
            sessions: newSessionById,
            sessionJudges: newSessionJudgesData,
          })
        );
        dispatch(alertActions.success('Session judges updated successfully.'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure(error.toString()));
        dispatch(alertActions.error('Error updating session judges.'));
      });
  };

  function request() {
    return { type: adminConstants.UPDATE_SESSION_JUDGE_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.UPDATE_SESSION_JUDGE_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.UPDATE_SESSION_JUDGE_FAILURE };
  }
}

function updateLineupTitles(payload) {
  return (dispatch) => {
    dispatch(request());
    const filteredPayload = payload.filter((el) => el !== false);

    adminService
      .updateLineups(filteredPayload)
      .then((res) => {
        console.log(res);
        const data = Object.assign(
          {},
          ...flatMap(res).map((x) => {
            if (x?.data?.updateLineup?.id) {
              return { [x.data.updateLineup.id]: x.data.updateLineup };
            }
            if (x?.data?.deleteLineup?.id) {
              return { [x.data.deleteLineup.id]: x.data.deleteLineup };
            }
          })
        );
        dispatch(success(data));
        dispatch(
          alertActions.success(
            `Lineup${
              filteredPayload.length === 1 ? '' : 's'
            } updated successfully.`
          )
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure());
        dispatch(alertActions.error(error && error.errors?.[0]?.message));
      });
  };

  function request() {
    return { type: adminConstants.UPDATE_LINEUP_TITLES_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.UPDATE_LINEUP_TITLES_SUCCESS, data };
  }
  function failure() {
    return { type: adminConstants.UPDATE_LINEUP_TITLES_FAILURE };
  }
}
