import { Button, ButtonGroup } from 'react-bootstrap';
import { GenderType } from '../../models';
import selectGenderStyles from './selectgender.module.css';

function SelectGender(props) {
  const { setGender, gender } = props;
  const { MALE, FEMALE, COED } = GenderType;
  const { genderSelect } = selectGenderStyles;

  return (
    <div className={genderSelect}>
      <ButtonGroup>
        <Button
          variant="light"
          onClick={() => setGender(FEMALE)}
          active={gender === FEMALE}
        >
          Womens
        </Button>
        <Button
          variant="light"
          onClick={() => setGender(null)}
          active={!gender || gender === COED}
        >
          All
        </Button>
        <Button
          variant="light"
          onClick={() => setGender(MALE)}
          active={gender === MALE}
        >
          Mens
        </Button>
      </ButtonGroup>
    </div>
  );
}

export default SelectGender;
