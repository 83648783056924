import { Row, Col } from 'react-bootstrap';
import { numToApparatusM, numToApparatusW } from '../../utilities/conversions';
import { useSelector } from 'react-redux';
import * as Icons from '../helpers/icons';
import {
  roundScore,
  roundScoreLegacy,
  convertMillipointsToDisplay,
  convertPointsToMillipoints,
} from '../../utilities/scoring';
import { dualTeamBRotation } from '../../utilities/session';
import { sessionStatus, sessionViewType } from '../../redux/_constants';
import './roundsummary.css';
import { useDisplayConfig } from './hooks';
import producerStyles from './producer.module.css';
import { useRoutines } from '../../utilities/clips';

function RoundSummary({
  team,
  left,
  right,
  round,
  customRound,
  showName = false,
  isExperimental,
  showScores = true,
}) {
  const displayConfig = useDisplayConfig();
  const { color } = team || {};
  const gender = useSelector((state) => state.session.gender);
  const teamScoringType = useSelector((state) => state.session.teamScoring);
  const shouldShowScores = useSelector((state) => state.producer.scores);
  const isCompetition = useSelector((state) => state.session.competition);
  const isAlternating = useSelector((state) => state.session.alternating);
  const isPreLive = useSelector((state) =>
    [sessionStatus.LIVE, sessionStatus.PREGAME].includes(state.session.status)
  );
  const isScoreboardView = useSelector(
    (state) => state.session.view === sessionViewType.SCOREBOARD
  );
  const isLeftRightSame = useSelector(
    (state) => state.producer.teamA?.lineupId === state.producer.teamB?.lineupId
  );
  const adjRound =
    customRound ||
    (isAlternating && right && isPreLive && !isLeftRightSame
      ? dualTeamBRotation(round)
      : round);

  const lineup = useSelector(
    (state) => state.producer[`team${left ? 'A' : 'B'}`].lineup
  );

  const roundRoutines = useRoutines(team.lineupId, adjRound);
  const score = roundRoutines.length
    ? roundScore(roundRoutines, teamScoringType)
    : convertPointsToMillipoints(roundScoreLegacy(lineup, teamScoringType));

  let background = 'var(--background-haze)';

  if (left) {
    background = `linear-gradient(90deg, ${
      color ? color : background
    } 0% 3%, ${background} 3% 100%)`;
  }
  if (right) {
    background = `linear-gradient(90deg, ${background} 0% 97%, ${
      color ? color : background
    } 97% 100%)`;
  }

  const apparatusNum = adjRound;

  const apparatus = (abbv) => {
    if (gender === 'MALE') {
      return numToApparatusM(apparatusNum, abbv);
    }

    if (gender === 'FEMALE') {
      return numToApparatusW(apparatusNum, abbv);
    }
  };

  return (
    <div className={['roundSummary', producerStyles.roundSummary].join(' ')}>
      <Row style={{ height: '100%' }}>
        {isExperimental ? (
          <Col xs={4} className="spacer" />
        ) : (
          <>
            <Col
              className={['vCenter', 'logo', producerStyles.logo].join(' ')}
              style={{ background: background }}
              xs={2}
            >
              <span>{apparatus(true)}</span>
            </Col>
            <Col
              className={['vCenter', 'icon', producerStyles.icon].join(' ')}
              xs={2}
            >
              {isCompetition
                ? Icons?.[`${apparatus(true)}noText`] ??
                  apparatus(true)?.toLowerCase()
                : null}
            </Col>
          </>
        )}
        <Col
          className={['vCenter', 'team', producerStyles.team].join(' ')}
          xs={5}
        >
          <span>
            {showName
              ? isScoreboardView
                ? ''
                : apparatus(isScoreboardView)
              : isCompetition
              ? `Event Total`
              : `All Around Total`}
          </span>
        </Col>
        <Col
          className={['vCenter', 'score', producerStyles.score].join(' ')}
          xs={3}
        >
          {shouldShowScores && showScores && score
            ? convertMillipointsToDisplay(score, displayConfig.scorePrecision)
            : '--'}
        </Col>
      </Row>
    </div>
  );
}

export default RoundSummary;
