import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CompetitorList from './competitorlist';
import RoundDeltas from './rounddeltas';
import RoundSummary from './roundsummary';
import { useSelector, useDispatch } from 'react-redux';
import ScoreSheetHeader from './scoresheetheader';
import { sessionViewType, sessionRoleType } from '../../redux/_constants';
import { SessionSubscriptionManager } from './hooks';
import './main.css';
import { useHotkeys } from 'react-hotkeys-hook';
import { Html2Image } from '../helpers/html2image';
import { Button } from 'react-bootstrap';
import { producerActions } from '../../redux/_actions';
import { useQueryParams } from '../../utilities/query';
import Header from '../helpers/header';
import MenuBubble from '../helpers/menububble';
import UserBubble from '../helpers/userbubble';
import { eye, eyeSlash, printIcon } from '../helpers/icons';

const {
  SCOREBOARD,
  FULLSCREEN,
  FULLSCREENA,
  FULLSCREENB,
  MOBILE,
  MIRRORA,
  MIRRORB,
  SOLOPLAYER,
  DEFAULT,
} = sessionViewType;

const { ADMIN } = sessionRoleType;

export default function ScoreSheet({ sessionId }) {
  const showLeaderboard = useSelector((state) => state.producer.leaderboard);
  const view = useSelector((state) => state.session.view);
  const role = useSelector((state) => state.session.role);
  const numRounds = useSelector(
    (state) => state.session.apparatus.split('1').length - 1
  );
  const [showSummary, setShowSummary] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [fontMode, setFontMode] = useState(false);
  const isScoreboardView = view === SCOREBOARD;
  const isFullscreen = [FULLSCREEN, FULLSCREENA, FULLSCREENB].includes(view);
  const isMobile = view === MOBILE;
  const isAdmin = role === ADMIN;
  const isMirrorA = view === MIRRORA;
  const isMirrorB = view === MIRRORB;
  const isSoloPlayer =
    view === SOLOPLAYER || view === MOBILE || view === DEFAULT;
  const teamA = useSelector(
    (state) => state.producer[isMirrorB ? 'teamB' : 'teamA']
  );
  const teamB = useSelector(
    (state) => state.producer[isMirrorA ? 'teamA' : 'teamB']
  );

  const queryParams = useQueryParams();
  const queryScores = queryParams.get('scoresheet');
  const [showScores, setShowScores] = useState(queryScores ? queryScores.toUpperCase() === 'SCORES' : true);

  const dispatch = useDispatch();
  const fullLineup = JSON.parse(
    useSelector((state) =>
      state.session.lineups.items.find((l) => l.id === teamA.lineupId)
    ).lineupData
  );

  useHotkeys(
    's',
    (e) => {
      e.preventDefault();
      setShowSummary(!showSummary);
      if (showLeaderboard) {
        dispatch(producerActions.leaderboard(!showLeaderboard));
      }
    },
    [showSummary, showLeaderboard]
  );

  const handleScoreClick = () => {
    queryParams.set('scoresheet', !showScores ? 'SCORES' : '');
    setShowScores(!showScores);
  }

  useHotkeys(
    'd',
    (e) => {
      e.preventDefault();
      if (isScoreboardView) {
        document.documentElement.setAttribute(
          'data-theme',
          darkMode ? null : 'dark'
        );
        setDarkMode(!darkMode);
        console.log('Dark Mode: ', !darkMode);
      }
    },
    [darkMode, isScoreboardView]
  );

  useHotkeys(
    'f',
    (e) => {
      e.preventDefault();
      if (isScoreboardView) {
        document.documentElement.setAttribute(
          'font-theme',
          fontMode ? null : '700'
        );
        setFontMode(!fontMode);
      }
    },
    [fontMode, isScoreboardView]
  );

  const allLineups = (
    <>
      {Array.from({ length: numRounds }, (_, index) => index + 1).map(
        (r, index) => {
          const scoreSheetTeam = {
            ...teamA,
            lineup: fullLineup?.[index + 1],
          };

          return (
            <Row
              className="lineups"
              key={`lineup_${index + 1}`}
              style={{ 
                marginBottom: index < numRounds - 1 ? '80px' : '0',
                pageBreakAfter: index < numRounds - 1 ? 'always' : 'avoid'
              }}
            >
              <Col>
                <Row className="headers" style={{ marginBottom: '1rem' }}>
                  <Col className="left">
                    <ScoreSheetHeader
                      teamSide="teamA"
                      left={true}
                      index={index + 1}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="left">
                    <CompetitorList
                      team={scoreSheetTeam}
                      left={true}
                      scoresheet={true}
                      scoresheetScores={showScores}
                      customRound={index + 1}
                    />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col className="left">
                    <RoundSummary
                      team={teamA}
                      round={r}
                      left={true}
                      showName={true}
                      showScores={showScores}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        }
      )}
    </>
  );

  const allRounds = (
    <Row className="lineups">
      <Col>
        <Row>
          <Col className="left">
            {Array.from({ length: numRounds }, (_, index) => index + 1).map(
              (r, index) => {
                return (
                  <div key={`roundsummary-left-${index}`}>
                    <RoundSummary
                      team={teamA}
                      round={r}
                      left={true}
                      showName={true}
                      showScores={showScores}
                    />
                    {index < numRounds - 1 ? <hr /> : null}
                  </div>
                );
              }
            )}
          </Col>
          <Col className="center" xs={1}>
            <RoundDeltas rounds={numRounds} />
          </Col>
          <Col className="right">
            {Array.from({ length: numRounds }, (_, index) => index + 1).map(
              (r, index) => {
                return (
                  <div key={`roundsummary-right-${index}`}>
                    <RoundSummary
                      team={teamB}
                      round={r}
                      right={true}
                      showName={true}
                      showScores={showScores}
                    />
                    {index < numRounds - 1 ? <hr /> : null}
                  </div>
                );
              }
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );

  return (
    <div
      className={[
        'main',
        'session',
        isMobile ? 'mobile' : null,
        isScoreboardView ? 'scoreboardView' : null,
      ].join(' ')}
    >
      <Header className="noprint">
        <MenuBubble />
        <div className="scoreSheetBlankButton">
          <Button
            variant="outline-secondary"
            onClick={handleScoreClick}
          >
            {showScores ? eye : eyeSlash}
          </Button>
        </div>
        <div className="scoreSheetPrintButton">
          <Button
            variant="outline-secondary"
            onClick={() => window.print()}
          >
            {printIcon}
          </Button>
        </div>
        <UserBubble />
      </Header>
      <Container
        className={
          isFullscreen
            ? 'fullscreen'
            : isSoloPlayer
            ? 'soloPlayer'
            : 'dualPlayer'
        }
      >
        {showSummary ? allRounds : allLineups}
      </Container>
      <Html2Image
        enabled={isAdmin}
        className={showLeaderboard ? 'modal' : 'main'}
      />
      <SessionSubscriptionManager sessionId={sessionId} />
    </div>
  );
}
