import { useState, forwardRef } from 'react';
import { Button, Row, Col, FormControl } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  copyToClipboard,
  prettifyXml,
  saveToFile,
} from '../../utilities/export';
import {
  generateSessionCardData,
  generateSessionYoutubeCreation,
  generateOBSTemplate,
} from '../../utilities/generate-session-url';
import { YYYYMMDD, GYMapparatusAbbv } from '../../utilities/conversions';
import axios from 'axios';
import { alertActions } from '../../redux/_actions';

const SNIPPET_TYPE = {
  MATCHCARD: 'Match Card',
  YOUTUBE: 'YouTube Creation',
  APIJSON: 'API JSON',
  APIXML: 'API XML',
  APISNAPSHOT: 'API Snapshot',
  OBSTEMPLATE: 'OBS Template',
  URLS: 'Session URLs',
  LINEUP: 'Lineup JSON',
};

export const SessionAdminFunctions = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const {
    MATCHCARD,
    YOUTUBE,
    APIJSON,
    APIXML,
    APISNAPSHOT,
    OBSTEMPLATE,
    URLS,
    LINEUP,
  } = SNIPPET_TYPE;
  const { sessionId } = props;
  const [text, setText] = useState('');
  const [type, setType] = useState(null);
  const session = useSelector((state) => state.admin.sessions.byId[sessionId]);
  const lineups = useSelector((state) => state.admin.lineups);
  const sessionTeams = useSelector((state) => state.admin.sessionTeams);
  const sessionKey = session.sessionKey;
  const sessionApparatus = GYMapparatusAbbv(session.gender);
  const teams = session.sessionTeams.items.map(
    (stId) => sessionTeams.byId[stId].team
  );

  function printObjectDetailed(obj) {
    let result = '';

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        result += `   ${key}: "${obj[key]}",\n`;
      }
    }

    return `{\n${result}}`;
  }

  const handleCopy = (data, type) => {
    copyToClipboard(data);
    dispatch(alertActions.success(`${type} data copied to clipboard.`));
  };

  const handleAsync = async (type, downloadFile = false) => {
    let urlType = '';

    switch (type) {
      case APIJSON:
        urlType = 'json';
        break;
      case APIXML:
        urlType = 'xml';
        break;
      case APISNAPSHOT:
        urlType = 'snapshot';
        break;
      default:
        break;
    }

    const req = `https://api.virti.us/session/${sessionKey}/${urlType}`;
    await axios
      .get(req, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        const data = response?.data;

        if (data) {
          let formattedData = '';

          if (urlType === 'json') {
            formattedData = JSON.stringify(data, null, 2);
          }

          if (urlType === 'xml') {
            formattedData = prettifyXml(data);
          }

          if (urlType === 'snapshot') {
            const snapshots = data.snapshots;
            const timestamp = new Date().toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              timeZoneName: 'short',
            });
            formattedData = `Snapshot taken at ${timestamp}\n\nDownload Links:\n${req}/xml\n${req}/json\n\nSnapshot Data:\n${JSON.stringify(
              snapshots,
              null,
              2
            )}`;
          }

          handleCopy(formattedData, type);
          setText(formattedData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleShow = (type) => {
    let cardData = '';
    dispatch(alertActions.clear());
    setType(type);

    switch (type) {
      case YOUTUBE:
        cardData = JSON.stringify(
          generateSessionYoutubeCreation(session),
          null,
          2
        );
        handleCopy(cardData, type);
        setText(cardData);
        break;
      case MATCHCARD:
        cardData = printObjectDetailed(generateSessionCardData(session, teams));
        handleCopy(cardData, type);
        setText(cardData);
        break;
      case APIJSON:
        handleAsync(type);
        break;
      case APIXML:
        handleAsync(type);
        break;
      case APISNAPSHOT:
        handleAsync(type);
        break;
      case OBSTEMPLATE:
        cardData = JSON.stringify(generateOBSTemplate(session), null, 2);
        const date = new Date(session.startAt);
        handleCopy(cardData, type);
        setText(cardData);
        saveToFile(`${YYYYMMDD(date, false)} - ${session.name}.json`, cardData);
        break;
      case LINEUP:
        const timestamp = new Date().toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          timeZoneName: 'short',
        });
        const sessionLineups = session.lineups.items
          .map((id) => lineups.byId[id])
          .sort((a, b) => a.order - b.order);

        const lineupData = {
          sessionKey: sessionKey,
          exportedAt: timestamp,
          lineups: sessionLineups,
        };
        cardData = JSON.stringify(lineupData, null, 2);
        handleCopy(cardData, type);
        setText(cardData);
        saveToFile(
          `lineups-${sessionKey}-${new Date()
            .toISOString()
            .replace(/[:.]/g, '-')}.json`,
          cardData
        );
        break;

      case URLS:
        const baseUrl = `https://virti.us/session?s=${sessionKey}`;
        const apiURL = `https://api.virti.us/session/${sessionKey}/`;
        const scoreboardLetters = Array.from({ length: teams.length }, (_, i) =>
          String.fromCharCode('a'.charCodeAt(0) + i)
        );
        const modifiers = [
          ...scoreboardLetters.map((letter) => `&scoreboard=${letter}`),
          ...sessionApparatus.map((app) => `&scoreboard=${app.toLowerCase()}`),
          ...sessionApparatus.map(
            (app) => `&scoreboard=${app.toLowerCase()}&theme=dark&size=sm`
          ),
          '&scoreboard=teams',
          '&scoreboard=teamsbyapparatus',
          '&leaderboard',
          '&beta=quad',
        ];
        const apiModifiers = ['json', 'xml'];
        cardData = 'Session:\n';
        cardData =
          cardData +
          baseUrl +
          '\n\nScoreboards:\n' +
          modifiers.map((mod) => `${baseUrl}${mod}`).join('\n');
        cardData =
          cardData +
          '\n\nAPI:\n' +
          apiModifiers.map((mod) => `${apiURL}${mod}`).join('\n');
        handleCopy(cardData, type);
        setText(cardData);
        break;
      default:
        break;
    }
  };

  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem',
  };

  const matchData = (
    <Row className="g-2" style={{ padding: '1rem' }}>
      <Col xs={4} style={buttonStyle}>
        <Button variant="secondary" onClick={() => handleShow(MATCHCARD)}>
          Match Card Data
        </Button>
      </Col>
      <Col xs={4} style={buttonStyle}>
        <Button variant="secondary" onClick={() => handleShow(YOUTUBE)}>
          Youtube Stream Creation
        </Button>
      </Col>
      <Col xs={4} style={buttonStyle}>
        <Button variant="secondary" onClick={() => handleShow(OBSTEMPLATE)}>
          OBS Template
        </Button>
      </Col>
      <Col xs={4} style={buttonStyle}>
        <Button variant="secondary" onClick={() => handleShow(APIJSON)}>
          API Data (JSON)
        </Button>
      </Col>
      <Col xs={4} style={buttonStyle}>
        <Button variant="secondary" onClick={() => handleShow(APIXML)}>
          API Data (XML)
        </Button>
      </Col>
      <Col xs={4} style={buttonStyle}>
        <Button variant="secondary" onClick={() => handleShow(APISNAPSHOT)}>
          API Snapshot
        </Button>
      </Col>
      <Col xs={4} style={buttonStyle}>
        <Button variant="secondary" onClick={() => handleShow(URLS)}>
          Session URLs
        </Button>
      </Col>
      <Col xs={4} style={buttonStyle}>
        <Button variant="secondary" onClick={() => handleShow(LINEUP)}>
          Export Lineup
        </Button>
      </Col>
    </Row>
  );

  const textArea = (
    <Row style={{ padding: '1rem' }}>
      {type === URLS || type === APISNAPSHOT ? (
        <div
          style={{
            minHeight: '50vh',
            width: '100%',
            padding: '1rem',
            backgroundColor: '#fff',
            border: '1px solid #ced4da',
            borderRadius: '.25rem',
            whiteSpace: 'pre-wrap', // Ensures spaces and line breaks are preserved
          }}
        >
          {text.split('\n').map((line, index) => {
            // Use the original line without trimming to preserve leading/trailing spaces
            const isURL = /^(https?:\/\/[^\s]+)$/i.test(line.trim());

            if (line === '') {
              // Render a blank line as <br>
              return (
                <div key={index}>
                  <br />
                </div>
              );
            }

            return (
              <div key={index}>
                {isURL ? (
                  type === APISNAPSHOT ? (
                    <a
                      href="#"
                      onClick={async (e) => {
                        e.preventDefault();
                        const url = line.trim();
                        const response = await axios.get(url, {
                          responseType: 'blob',
                        });
                        const blob = new Blob([response.data]);
                        const downloadUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        const timestamp = new Date()
                          .toISOString()
                          .replace(/[:.]/g, '-');
                        const fileExt = url.includes('/xml') ? 'xml' : 'json';
                        link.download = `snapshot-${sessionKey}-${timestamp}.${fileExt}`;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(downloadUrl);
                      }}
                    >
                      {line.trim()}
                    </a>
                  ) : (
                    <a
                      href={line.trim()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {line.trim()}
                    </a>
                  )
                ) : (
                  <span>{line}</span>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <FormControl
          as="textarea"
          style={{ minHeight: '50vh', padding: '1rem' }}
          value={text ?? ''}
          onChange={(e) => setText(e.target.value)}
        />
      )}
    </Row>
  );

  return (
    <>
      {matchData}
      {textArea}
    </>
  );
});
