import React, { useState } from 'react';
import { Container, Row, Col, ButtonGroup, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SetupAdmin from './admin/setupadmin';
import Header from './helpers/header';
import UserBubble from './helpers/userbubble';
import MenuBubble from './helpers/menububble';
import { adminModeType } from '../redux/_constants';
import { heroLogo } from './helpers/logos';
import { useUser } from '../user-provider';

function Admin() {
  const [mode, setMode] = useState(null);
  const { isSuperAdmin } = useUser();

  const handleMode = (mode) => {
    setMode(mode);
  };

  return (
    <div className="main">
      <Header>
        <UserBubble />
        <MenuBubble />
      </Header>
      <SetupAdmin
        mode={mode}
        modeType={adminModeType}
        handleMode={handleMode}
      />
      <Container>
        <Row style={{ height: 'calc(40vh - 72px)' }} />
        <Row style={{ height: '15vh' }}>
          <Col className="center" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div className="heroLogo"> {heroLogo} </div>
            <div className="heroMessage">
              Admin panel to manage competition organizations and participants.
            </div>
            <div className="mt-4 d-flex justify-content-center w-100" style={{ position: 'relative', zIndex: 1000 }}>
              <Link 
                to="/admin/dashboard"
                className="btn btn-secondary btn-lg"
                style={{ textDecoration: 'none' }}
              >
                Session Dashboard
              </Link>
            </div>
          </Col>
        </Row>
        <Row style={{ height: '45vh' }}>
          <Col className="center" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div className="loginContainer">
              <div className="d-flex flex-column align-items-center gap-3">
                <ButtonGroup>
                <Button
                  variant="outline-secondary"
                  onClick={() => handleMode(adminModeType.SESSION)}
                >
                  Session
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={() => handleMode(adminModeType.ATHLETE)}
                >
                  Athlete
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={() => handleMode(adminModeType.TEAM)}
                >
                  Team
                </Button>
                {isSuperAdmin && (
                  <>
                    <Button
                      variant="outline-secondary"
                      onClick={() => handleMode(adminModeType.JUDGE)}
                    >
                      Judge
                    </Button>
                    <Button
                      variant="outline-secondary"
                      onClick={() => handleMode(adminModeType.USER)}
                    >
                      User
                    </Button>
                    <Button
                      variant="outline-secondary"
                      onClick={() => handleMode(adminModeType.LEAGUE)}
                    >
                      League
                    </Button>
                  </>
                )}
                </ButtonGroup>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Admin;
