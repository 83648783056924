import { useHistory } from 'react-router-dom';
import { useUser } from '../../user-provider';
import { Button, ButtonGroup } from 'react-bootstrap';
import CalendarDropdown from './calendar-dropdown';
import { generateSessionUrl } from '../../utilities/generate-session-url';
import { format, addHours, isPast, addMinutes } from 'date-fns';
import { V_LOGO, MEDIAURL } from '../../utilities/constants';
import './matchup.css';

const convertTag = (tag) => {
  switch (tag) {
    case 'MALE':
      return 'Mens';
    case 'FEMALE':
      return 'Womens';
    case 'STREAMING':
      return 'Streaming';
    default:
      return tag;
  }
};

const FREEACCESS = 72;

const logoPath = (teamLogo) => {
  if (!!teamLogo) {
    return MEDIAURL + teamLogo;
  }

  return V_LOGO;
};

const showStreamLinks = (multiStream, handler) => {
  const indices = multiStream && Object.keys(multiStream);

  if (!indices.length) {
    return null;
  }

  return (
    <div className="streamLinks">
      <h4 className="streamLinkTitle">Individual Streams</h4>
      <ButtonGroup>
        {indices.map((i) => (
          <Button
            variant="light"
            onClick={(e) => handler(e, i)}
            key={i}
          >{`${multiStream?.[i]}`}</Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

function MatchUp({ match, disabled }) {
  const {
    sessionId,
    teamA,
    teamALogo,
    teamB,
    teamBLogo,
    teamC,
    teamCLogo,
    teamD,
    teamDLogo,
    teamE,
    teamELogo,
    teamF,
    teamFLogo,
    teamG,
    teamGLogo,
    teamH,
    teamHLogo,
    gameTime,
    title,
    multiStream,
    useBeta,
    tags,
  } = match;
  const vsTitle = [
    teamA,
    teamB ? teamB : null,
    teamC ? teamC : null,
    teamD ? teamD : null,
    teamE ? teamE : null,
    teamF ? teamF : null,
    teamG ? teamG : null,
    teamH ? teamH : null,
  ]
    .filter(Boolean)
    .join(' vs ');
  const matchUpTitle = title ? title : `${vsTitle}`;
  const matchUpDate = `${format(new Date(gameTime), "EEE M'/'dd'/'yyyy @ p")}`;
  const history = useHistory();
  const user = useUser();
  const calendarEventTitle = `Virtius: ${vsTitle}`;
  const calendarEventDescription = generateSessionUrl(sessionId, true);
  const fanEarlyBlock =
    user.isFan && !isPast(addMinutes(new Date(gameTime), -1440)) && false;

  const handler = (e, streamKey) => {
    const startAtDate = new Date(gameTime);
    const endAtDate = addHours(startAtDate, FREEACCESS);
    const isOngoing = isPast(startAtDate) && !isPast(endAtDate);
    const isComing = !isPast(startAtDate);
    const isStream = !!multiStream;
    const streamURL = isStream && streamKey ? `&stream=${streamKey}` : '';
    const betaURL = useBeta ? '&beta=fan' : '';
    const sessionURL = generateSessionUrl(`${sessionId}${betaURL}${streamURL}`);

    if (isComing || isOngoing || true) {
      // For matches that are upcoming or ongoing, allow public access
      history.push(sessionURL);
    } else {
      // For matches that have passed
      history.push(
        user.user
          ? user.user?.signInUserSession
            ? sessionURL
            : '/login'
          : '/signup'
      );
    }
  };

  return (
    <div className="heroMessage">
      <p className="matchTitle">{matchUpTitle}</p>
      <p className="matchDate">{matchUpDate}</p>
      {tags && tags.length > 0 && (
        <div className="tagPills">
          {tags.map((tag, index) => {
            const tagClass =
              tag === 'MALE'
                ? 'mens'
                : tag === 'FEMALE'
                ? 'womens'
                : tag === 'STREAMING'
                ? 'streaming'
                : 'default';

            if (tag === 'FEMALE' || tag === '') return null;

            return (
              <span key={index} className={`tagPill ${tagClass}`}>
                {convertTag(tag)}
              </span>
            );
          })}
        </div>
      )}
      <div className="matchLogos">
        <Button
          variant="outline-light"
          onClick={handler}
          className="matchButton"
          disabled={disabled || fanEarlyBlock}
        >
          <img src={logoPath(teamALogo)} alt={teamA} />
          {teamB && !teamD ? (
            <>
              <span className="vBlue">V</span>
              <span className="vGray">S</span>
            </>
          ) : null}
          {teamB && <img src={logoPath(teamBLogo)} alt={teamB} />}
          {teamC && !teamD ? (
            <>
              <span className="vBlue">V</span>
              <span className="vGray">S</span>
            </>
          ) : null}
          {teamC && <img src={logoPath(teamCLogo)} alt={teamC} />}
          {teamD && <img src={logoPath(teamDLogo)} alt={teamD} />}
          {teamE && <img src={logoPath(teamELogo)} alt={teamE} />}
          {teamF && <img src={logoPath(teamFLogo)} alt={teamF} />}
          {teamG && <img src={logoPath(teamGLogo)} alt={teamG} />}
          {teamH && <img src={logoPath(teamHLogo)} alt={teamH} />}
        </Button>
      </div>
      {!!multiStream ? showStreamLinks(multiStream, handler) : null}
      <div className="calendarAdd">
        <CalendarDropdown
          eventTitle={calendarEventTitle}
          eventDescription={calendarEventDescription}
          sessionId={sessionId}
          startAt={gameTime}
          handler={handler}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default MatchUp;
