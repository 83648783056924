import React, {
  useState,
  useMemo,
  useEffect,
  useImperativeHandle,
} from 'react';
import { Row, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import TableComponent from '../helpers/tablecomponent';
import { adminActions, alertActions } from '../../redux/_actions';
import { adminService } from '../../redux/_services';
import { dataUtilities } from '../../utilities/data';
import { Typeahead } from 'react-bootstrap-typeahead';
import { AffiliationStatus } from '../../models';

const filterEmpty = (list) => {
  if (list.length === 0) {
    return list;
  }
  return list.filter((el) => el !== '' && el !== '');
};

const filterJudges = (avail, assigned) => {
  if (!avail || !assigned) {
    return [];
  }
  return avail.filter((el) => {
    return !assigned.some((i) => i === el.id || i.id === el.id);
  });
};

// Notes: Currently does not restrict by Judging Credential, pulls all judges
export const SessionJudges = React.forwardRef((props, ref) => {
  const { sessionId, isEditing, isSaving, reset, setIsLoading } = props;
  const session = useSelector((state) => state.admin.sessions.byId[sessionId]);
  const sessionJudgesById = useSelector(
    (state) => state.admin.sessionJudges.byId
  );
  const sessionJudges = session.sessionJudges.items;
  const sessionJudgeList = sessionJudges
    .filter((id) => sessionJudgesById[id].status === AffiliationStatus.ACTIVE)
    .map((id) => sessionJudgesById[id].userId);
  const [judgeList, setJudgeList] = useState(sessionJudgeList || []);
  const dispatch = useDispatch();
  const [availJudges, setAvailJudges] = useState([]);
  const [allJudges, setAllJudges] = useState([]);

  useEffect(() => {
    if (allJudges.length === 0) {
      setIsLoading(true);
      adminService.loadJudges().then((res) => {
        setAllJudges(res?.data?.searchUsers?.items || []);
        setIsLoading(false);
      });
    }
  }, [allJudges.length, setIsLoading]);

  useEffect(() => {
    setAvailJudges(filterJudges(allJudges, judgeList));
  }, [allJudges, judgeList]);

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit();
    },
  }));

  const handleSubmit = () => {
    const { added, removed } = dataUtilities.checkListChange(
      sessionJudgeList,
      judgeList
    );

    if (added || removed) {
      const input = {
        added:
          added &&
          added.map((id) => {
            return {
              userId: id,
              sessionId: sessionId,
              status: AffiliationStatus.ACTIVE,
            };
          }),
        removed:
          removed &&
          removed.map((id) => {
            const removeId = sessionJudges.find(
              (sjId) => sessionJudgesById[sjId].userId === id
            );
            return {
              id: removeId,
              status: AffiliationStatus.INACTIVE,
              _version: sessionJudgesById[removeId]._version,
            };
          }),
        sessionId: sessionId,
      };

      dispatch(adminActions.updateJudges(input));
    } else {
      dispatch(alertActions.success('No changes made.'));
      reset();
      return;
    }
  };

  const emptyList = () => {
    return (
      <Row className="vCenter emptyList">
        <span>No scorekeepers assigned.</span>
      </Row>
    );
  };

  const headers = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col1',
        Cell: (r) => {
          return r.row.index + 10;
        },
      },
      {
        Header: 'Name',
        accessor: 'col2',
        Cell: (r) => {
          const { cell, row } = r;
          const i = row.index;

          return (
            <div className="sessionJudgeName">
              <Typeahead
                id="judgeTypeahead"
                clearButton
                filterBy={['name', 'email']}
                onChange={(selected) => {
                  const newList = Array.from(judgeList);
                  newList[i] = selected[0]?.id ?? '';
                  setJudgeList(filterEmpty(newList));
                }}
                labelKey="name"
                className={['judgeTypeahead', isEditing ? 'editing' : ''].join(
                  ' '
                )}
                disabled={!isEditing || isSaving}
                placeholder="Add judge..."
                options={availJudges}
                selected={cell.value !== '' ? [cell.value] : []}
                onKeyDown={(e) => onKeyDown(e, r)}
                renderMenuItemChildren={(option) => (
                  <div style={{ fontWeight: '500' }}>
                    {option.name}
                    <div style={{ padding: '0 1rem' }}>
                      <small>{option.email}</small>
                    </div>
                  </div>
                )}
              />
            </div>
          );
        },
      },
      {
        Header: 'Email',
        accessor: 'col3',
        Cell: (r) => {
          const { cell } = r;
          return cell.value !== '' ? cell.value : '-';
        },
      },
      {
        Header: 'Credentials',
        accessor: 'col4',
        Cell: (r) => {
          const { cell } = r;
          return cell.value !== '' ? credentials(cell.value) : '-';
        },
      },
    ],
    [isEditing, availJudges, judgeList, isSaving]
  );

  const judgeListData = useMemo(() => {
    if (allJudges.length === 0) {
      return [];
    }

    const result =
      judgeList &&
      judgeList.map((id, i) => {
        const { name, email, judgeType } = allJudges.find((el) => el.id === id);

        return {
          col1: i + 1,
          col2: name,
          col3: email ?? '-',
          col4: judgeType ?? '-',
        };
      });

    if (isEditing) {
      result.push({ col1: result.length + 1, col2: '', col3: '', col4: '' });
    }
    return Array.from(result);
  }, [judgeList, isEditing, allJudges]);

  const judgeTable = () => {
    const initialState = {
      sortBy: [{ id: 'col2', desc: false }],
    };

    return (
      <Row style={{ minHeight: '40vh' }}>
        <TableComponent
          columns={headers}
          data={Array.from(judgeListData)}
          //onClick={() => }
          initialState={initialState}
          textSort={false}
          updateable={false}
        />
      </Row>
    );
  };

  const onKeyDown = (e, i) => {
    e.stopPropagation();
  };

  const credentials = (list) => {
    if (list.length === 0 || list === '-') {
      return '';
    }

    const creds = list.map((c) => {
      return c.split('_').length > 0 ? c.split('_')[0] : '';
    });

    return creds.sort().join(', ');
  };

  const setupBody = () => {
    if (!isEditing && (!judgeList || judgeList.length === 0)) {
      return emptyList();
    } else {
      return judgeTable();
    }
  };

  return (
    <Form onSubmit={null} className="sessionJudges">
      {setupBody()}
    </Form>
  );
});
