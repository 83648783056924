import React, {
  useState,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  Button,
  Row,
  Col,
  Form,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { adminActions, alertActions } from '../../redux/_actions';
import { judgeType, imgType, FILE_SIZE } from '../../redux/_constants';
import * as Yup from 'yup';
import { SketchPicker } from 'react-color';
import { useDropzone } from 'react-dropzone';
import { Typeahead } from 'react-bootstrap-typeahead';
import { MEDIAURL } from '../../utilities/constants';
import { useImage } from '../../utilities/media';

export const UserProfile = React.forwardRef((props, ref) => {
  const { userId, isEditing, isSaving, reset, setIsLoading } = props;
  const { users } = useSelector((state) => state.admin);
  const user = users.byId[userId];
  const { parseImageFile: image } = useImage();
  const defaultColors = ['#00A2FF', '#5E5E5E', '#FFFFFF', '#000000'];
  const [colors, setColors] = useState(
    user?.colors ? JSON.parse(user.colors) : defaultColors
  );
  const lastImg = image(user?.profileImg);
  const [profileImg, setProfileImg] = useState(lastImg?.filename || '');

  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state);
  const hiddenFileInput = useRef(null);
  const [changedImg, setChangedImg] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    colors: Yup.array().of(Yup.string()).nullable(),
    handle: Yup.string().nullable(),
    profileImg: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        'FILE_SIZE',
        'Uploaded file is too big.',
        (value) => !changedImg || !value || (value && value.size <= FILE_SIZE)
      )
      .test(
        'FILE_FORMAT',
        'Uploaded file has unsupported format.',
        (value) =>
          !changedImg || !value || (value && imgType.includes(value.type))
      ),
    athleteId: Yup.string().nullable(),
    judgeType: Yup.array().of(Yup.string()).nullable(),
  });

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    initialValues: {
      name: user?.name || '',
      colors: user?.colors ? JSON.parse(user.colors) : null,
      handle: user?.handle || '',
      judgeType: user?.judgeType || [],
      profileImg: user?.profileImg ? JSON.parse(user.profileImg) : '',
      athleteId: user?.athleteId || '',
      id: user?.id,
      _version: user?._version,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      // Check if no change
      if (
        values.name === user.name &&
        (values.colors === user.colors ||
          JSON.stringify(values.colors) === JSON.stringify(user.colors)) &&
        values.handle === user.handle &&
        (values.judgeType === user.judgeType ||
          JSON.stringify(values.judgeType) ===
            JSON.stringify(user.judgeType)) &&
        !changedImg &&
        values.athleteId === user.athleteId
      ) {
        dispatch(alertActions.success('No changes made.'));
        reset();
        return;
      }

      setIsLoading(true);
      dispatch(alertActions.clear());
      dispatch(adminActions.editUser(values, changedImg, user));
      setChangedImg(false);
    },
  });

  //console.log(errors)
  //console.log(values)

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit();
    },
  }));

  const checkBase64 = (string) => {
    return string.substr(0, 5) === 'data:';
  };

  const colorButton = (index) => {
    return (
      colors && (
        <OverlayTrigger
          placement="bottom"
          trigger="click"
          rootClose
          overlay={
            <Popover id={`popover-colorpicker-${index}`}>
              <SketchPicker
                disableAlpha
                presetColors={[]}
                color={colors[index]}
                onChange={(value) => {
                  setFieldValue(`colors[${index}]`, value.hex);
                  colors[index] = value.hex;
                  setColors(colors);
                }}
              />
            </Popover>
          }
        >
          <Button
            className="colorSelect"
            disabled={isSaving || !isEditing}
            style={{
              marginLeft: index === 0 ? '0' : null,
              background: colors[index],
            }}
          ></Button>
        </OverlayTrigger>
      )
    );
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const handleLogoChange = (files) => {
        const fileUploaded = files;
        if (!fileUploaded) {
          return false;
        }

        if (!alert.clear) {
          dispatch(alertActions.clear());
        }

        let reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            if (fileUploaded.size > FILE_SIZE) {
              dispatch(alertActions.error('Image exceeds max 5MB.'));
              return false;
            }
            setChangedImg(true);
            setProfileImg(reader.result);
          };
          img.onerror = () => {
            dispatch(alertActions.error('Could not load image.'));
            return false;
          };
          img.src = e.target.result;
        };

        setFieldValue(`profileImg`, fileUploaded);
        reader.readAsDataURL(fileUploaded);
      };

      handleLogoChange(acceptedFiles);
    },
    [alert.clear, dispatch, setFieldValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const logoButton = () => {
    const handleClick = (event) => {
      hiddenFileInput.current.click();
    };

    return (
      <>
        <label>Photo</label>
        <Button
          variant="outline-light"
          disabled={isSaving || !isEditing}
          className="logoSelect"
          onClick={handleClick}
          style={{ flexDirection: profileImg ? 'column' : null }}
        >
          {profileImg ? (
            <img
              className="img-thumbnail"
              alt={`${user.name} Profile`}
              src={
                checkBase64(profileImg)
                  ? profileImg
                  : `${MEDIAURL}${profileImg}`
              }
            />
          ) : isDragActive ? (
            '+'
          ) : (
            '?'
          )}
        </Button>
        <input
          type="file"
          {...getInputProps()}
          ref={hiddenFileInput}
          onChange={(e) => onDrop(e.target.files[0])}
          style={{ display: 'none' }}
          accept="image/png,image/gif,image/jpeg"
        />
      </>
    );
  };

  const setupBody = () => {
    return (
      <>
        <Row>
          <div className="setupFormLine">
            <Col {...getRootProps()}>{logoButton()}</Col>
            <Col sm={8}>
              <Row>
                <label>Colors</label>
                {colorButton(0)}
                {colorButton(1)}
                {colorButton(2)}
                {colorButton(3)}
              </Row>
            </Col>
          </div>
        </Row>
        <Row>
          <Col>
            <label>
              Name
              {errors.name ? (
                <span className="form-error">{errors.name}</span>
              ) : null}
            </label>
            <Form.Control
              plaintext
              name="name"
              type="text"
              autoComplete="off"
              value={values.name ?? ''}
              onChange={handleChange}
              disabled={isSaving || !isEditing}
              placeholder="Enter full name"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Handle</label>
            <Form.Control
              plaintext
              name="slug"
              type="text"
              autoComplete="off"
              value={values.handle ?? ''}
              onChange={handleChange}
              //disabled={isSaving || !isEditing}
              disabled={true}
              placeholder="Coming soon..."
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Athlete ID</label>
            <Form.Control
              plaintext
              name="slug"
              type="text"
              autoComplete="off"
              value={values.athleteId ?? ''}
              onChange={handleChange}
              //disabled={isSaving || !isEditing}
              disabled={true}
              placeholder="Coming soon..."
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Judge Qualifications</label>
            <Typeahead
              onChange={(selected) => {
                setFieldValue('judgeType', selected);
              }}
              options={Object.values(judgeType)}
              id="judgeTypes"
              className="typeahead"
              multiple
              dropup
              autoComplete="off"
              disabled={isSaving || !isEditing}
              placeholder="Optional"
              selected={values.judgeType ? values.judgeType : []}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Coach Affiliations</label>
            <Typeahead
              onChange={(selected) => console.log(selected)}
              options={[]}
              id="userCoachAffiliations"
              multiple
              labelKey="name"
              selected={null}
              autoComplete="off"
              //disabled={isSaving || !isEditing || !athlete.gender}
              disabled={true}
              className="typeahead"
              placeholder="Coming soon..."
              filterBy={['name', 'id']}
              renderMenuItemChildren={(option) => option.name}
            />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Form onSubmit={handleSubmit} className="userProfile">
      {setupBody()}
    </Form>
  );
});
