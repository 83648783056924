import {
  useState,
  useContext,
  createContext,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PrefetchSessionFull from '../../apollo/queries/PrefetchSessionFull.graphql';
import {
  Nav,
  Form,
  Col,
  Row,
  Container,
  Button,
  Card,
  ToggleButtonGroup,
  ToggleButton,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import TableComponent from '../helpers/tablecomponent';
import CreateRotation from '../../apollo/mutations/CreateRotation.graphql';
import DeleteRotation from '../../apollo/mutations/DeleteRotation.graphql';
import CreateStage from '../../apollo/mutations/CreateStage.graphql';
import DeleteStage from '../../apollo/mutations/DeleteStage.graphql';
import CreateSquad from '../../apollo/mutations/CreateSquad.graphql';
import DeleteSquad from '../../apollo/mutations/DeleteSquad.graphql';
import { evaluatorConfigs } from '../../redux/_constants';
import {
  //ScoreType,
  AthleteContextLevel,
  AthleteContextDivision,
  GymApparatus,
  GenderType,
  StageStatus,
  SessionType,
  RotationStatus,
  RoutineStatus,
} from '../../models';
import { CreateSessionJudgeAssignment } from '../../apollo/mutations/CreateSessionJudgeAssignment.graphql';
import { DeleteSessionJudgeAssignment } from '../../apollo/mutations/DeleteSessionJudgeAssignment.graphql';
import { CreateStageJudge } from '../../apollo/mutations/CreateStageJudge.graphql';
import { DeleteStageJudge } from '../../apollo/mutations/DeleteStageJudge.graphql';
import { UpdateStageJudge } from '../../apollo/mutations/UpdateStageJudge.graphql';
import { CreateSessionRoster } from '../../apollo/mutations/CreateSessionRoster.graphql';
import { UpdateSessionRoster } from '../../apollo/mutations/UpdateSessionRoster.graphql';
import { DeleteSessionRoster } from '../../apollo/mutations/DeleteSessionRoster.graphql';
import { CreateRoster } from '../../apollo/mutations/CreateRoster.graphql';
import { UpdateRoster } from '../../apollo/mutations/UpdateRoster.graphql';
import { UpdateSession } from '../../apollo/mutations/UpdateSession.graphql';
import { CreateAthleteContext } from '../../apollo/mutations/CreateAthleteContext.graphql';
import { UpdateAthleteContext } from '../../apollo/mutations/UpdateAthleteContext.graphql';
import { CreateRosterAthleteContext } from '../../apollo/mutations/CreateRosterAthleteContext.graphql';
import { DeleteRosterAthleteContext } from '../../apollo/mutations/DeleteRosterAthleteContext.graphql';
import { SearchFullAthletes } from '../../apollo/queries/SearchFullAthletes.graphql';
import { SearchJudges } from '../../apollo/queries/SearchJudges.graphql';
import { CreateJudge } from '../../apollo/mutations/CreateJudge.graphql';
import { CreateStageStream } from '../../apollo/mutations/CreateStageStream.graphql';
import { DeleteStageStream } from '../../apollo/mutations/DeleteStageStream.graphql';
import { CreateLineupMember } from '../../apollo/mutations/CreateLineupMember.graphql';
import { DeleteLineupMember } from '../../apollo/mutations/DeleteLineupMember.graphql';
import { UpdateLineupMember } from '../../apollo/mutations/UpdateLineupMember.graphql';
import { CreateLineup } from '../../apollo/mutations/CreateLineup.graphql';
import { UpdateRoutine } from '../../apollo/mutations/UpdateRoutine.graphql';
import { debounce, last, flatMap } from 'lodash';
import { convertMillipointsToDisplay } from '../../utilities/scoring';
import FullscreenLoading from '../helpers/fullscreenloading';
import './SessionSetupScreen.css';
import * as Icons from '../helpers/icons';
import TeamTypeahead from '../helpers/teamtypeahead';
import { useRosters, useRosterAthletes } from '../session/hooks';
import { teamBrand } from '../../utilities/conversions';
import Header from '../helpers/header';
import UserBubble from '../helpers/userbubble';
import MenuBubble from '../helpers/menububble';

function TrashIcon({ alt }) {
  return (
    <img
      style={{ width: '1.25em' }}
      src={`${process.env.PUBLIC_URL}/icons/trash.svg`}
      alt={alt}
    />
  );
}

export function SessionSetupScreen() {
  const { sessionKey } = useParams();

  if (!sessionKey) {
    throw new Error('SessionSetupScreen requires a :sessionKey URL parameter');
  }

  return <SessionSetupScreenLoader sessionKey={sessionKey} />;
}

const SessionContext = createContext(null);

function SessionSetupScreenLoader({ sessionKey }) {
  const { data, loading, error } = useQuery(PrefetchSessionFull, {
    variables: {
      sessionKey,
    },
  });

  if (loading) {
    return (
      <FullscreenLoading
        message="Loading session setup..." /*isDarkMode={isDarkMode}*/
      />
    );
  }

  if (error) {
    console.error(error);
    return <div>Problem fetching session: {error.message}</div>;
  }

  if (data.SessionByKey.items.length === 0) {
    return <div>No session found for {sessionKey}</div>;
  }

  if (data.SessionByKey.items.length > 1) {
    console.error(`More than one session found for ${sessionKey}`);
  }

  const session = data.SessionByKey.items[0];

  return (
    <SessionContext.Provider value={session}>
      <SessionSetupScreenInner session={session} />
    </SessionContext.Provider>
  );
}

const PageContext = createContext(null);

const getApparatusPossibilitiesByGender = (gender) =>
  gender === GenderType.FEMALE
    ? [
        GymApparatus.BYE1,
        GymApparatus.VT,
        GymApparatus.BYE2,
        GymApparatus.UB,
        GymApparatus.BYE3,
        GymApparatus.BB,
        GymApparatus.BYE4,
        GymApparatus.FX,
        GymApparatus.BYE5,
      ]
    : [
        GymApparatus.BYE1,
        GymApparatus.FX,
        GymApparatus.BYE2,
        GymApparatus.PH,
        GymApparatus.BYE3,
        GymApparatus.SR,
        GymApparatus.BYE4,
        GymApparatus.VT,
        GymApparatus.BYE5,
        GymApparatus.PB,
        GymApparatus.BYE6,
        GymApparatus.HB,
        GymApparatus.BYE7,
      ];

const useApparatusList = (gender, alternating, squadCount) => {
  // TODO: filter events by session.apparatus
  if (gender === GenderType.FEMALE) {
    if (alternating && squadCount === 3) {
      return [
        GymApparatus.VT,
        GymApparatus.UB,
        GymApparatus.BYE2,
        GymApparatus.BB,
        GymApparatus.BYE3,
        GymApparatus.FX,
      ];
    }
    if (squadCount === 6) {
      return [
        GymApparatus.VT,
        GymApparatus.BYE1,
        GymApparatus.UB,
        GymApparatus.BB,
        GymApparatus.BYE2,
        GymApparatus.FX,
      ];
    }
    if (squadCount === 7) {
      return [
        GymApparatus.BYE1,
        GymApparatus.VT,
        GymApparatus.BYE2,
        GymApparatus.UB,
        GymApparatus.BB,
        GymApparatus.BYE3,
        GymApparatus.FX,
      ];
    }
    if (squadCount === 8) {
      return [
        GymApparatus.BYE1,
        GymApparatus.VT,
        GymApparatus.BYE2,
        GymApparatus.UB,
        GymApparatus.BYE3,
        GymApparatus.BB,
        GymApparatus.BYE4,
        GymApparatus.FX,
      ];
    }
    return [GymApparatus.VT, GymApparatus.UB, GymApparatus.BB, GymApparatus.FX];
  } else if (gender === GenderType.MALE) {
    if (alternating && squadCount === 4) {
      return [
        GymApparatus.BYE1,
        GymApparatus.FX,
        GymApparatus.PH,
        GymApparatus.SR,
        GymApparatus.BYE4,
        GymApparatus.VT,
        GymApparatus.PB,
        GymApparatus.HB,
      ];
    }
    return [
      GymApparatus.FX,
      GymApparatus.PH,
      GymApparatus.SR,
      GymApparatus.VT,
      GymApparatus.PB,
      GymApparatus.HB,
    ];
  } else {
    throw new Error(`Unsupported session gender ${gender}`);
  }
};

const useSessionEvents = () => {
  const { alternating, squads, gender } = useContext(SessionContext);

  return useApparatusList(
    gender,
    alternating,
    squads.items.filter((s) => !s._deleted).length
  );
};

const useSortedSquads = () => {
  const { squads } = useContext(SessionContext);
  return (
    squads?.items
      ?.filter((s) => !s._deleted)
      ?.sort((a, b) => (a.name > b.name ? 1 : -1)) || []
  );
};

const useSortedSessionTeams = () => {
  const { sessionTeams } = useContext(SessionContext);
  return (
    sessionTeams?.items
      ?.slice()
      .filter((st) => !st._deleted)
      .sort((a, b) => (a.order > b.order ? 1 : -1)) || []
  );
};

const useSortedRotations = () => {
  const { rotations } = useContext(SessionContext);
  return rotations.items
    .filter((rotation) => !rotation._deleted)
    .slice()
    .sort((a, b) => (a.order > b.order ? 1 : -1));
};

const useSortedSessionRosters = () => {
  const { rosters } = useContext(SessionContext);
  return rosters.items
    .filter((sessionRoster) => !sessionRoster._deleted)
    .slice()
    .sort((a, b) =>
      a.roster?.team?.name + a.roster?.title >
      b.roster?.team?.name + b.roster?.title
        ? 1
        : -1
    );
};

const useSortedSessionRosterLineups = () => {
  const sortedSessionRosters = useSortedSessionRosters();

  return sortedSessionRosters.slice().filter((sr) => {
    return !!sr?.lineupId;
  });
};

const useSortedLineups = () => {
  const { lineups } = useContext(SessionContext);
  return lineups.items
    .filter((lineup) => !lineup._deleted)
    .slice()
    .sort((a, b) => (a.order > b.order ? 1 : -1));
};

const useJudgePool = () => {
  const { judgeAssignments } = useContext(SessionContext);

  return judgeAssignments?.items?.filter((ja) => !ja._deleted) || [];
};

const useSessionScoreTypes = () => {
  const { judgePanel } = useContext(SessionContext);
  const evaluatorConfig =
    evaluatorConfigs[judgePanel] ?? evaluatorConfigs.DEFAULT;

  const scoreTypes = [];
  (evaluatorConfig.dPanel || []).forEach(({ type }) => scoreTypes.push(type));
  (evaluatorConfig.ePanel || []).forEach(({ type }) => scoreTypes.push(type));
  (evaluatorConfig.fPanel || []).forEach(({ type }) => scoreTypes.push(type));
  //(evaluatorConfig.svPanel || []).forEach(({ type }) => scoreTypes.push(type));
  (evaluatorConfig.jPanel || []).forEach(({ type }) => scoreTypes.push(type));
  //evaluatorConfig.nDeduct && scoreTypes.push(ScoreType.ND);
  //evaluatorConfig.stickBonus && scoreTypes.push(ScoreType.SB);
  return scoreTypes;
};

const getStagesByApparatus = (stages) =>
  stages.reduce((acc, stage) => {
    if (!stage._deleted) {
      acc[stage.apparatus] = stage;
    }
    return acc;
  }, {});

function getSessionRosterName(sessionRoster) {
  const curTeam = sessionRoster?.roster?.team;
  const teamBranding = curTeam && teamBrand(curTeam);

  return curTeam
    ? `${teamBranding?.name} (${sessionRoster.roster.title})`
    : sessionRoster.roster.title;
}

function getSessionRosterLineupName(sessionRoster, session, titleOnly = false) {
  if (sessionRoster?.lineupId && session) {
    const lineup = session.lineups.items.find(
      (l) => l.id === sessionRoster.lineupId
    );

    if (!lineup) {
      return '';
    }

    if (titleOnly) {
      return lineup.title || '';
    }

    const customTitle = lineup.title !== session.name;
    return customTitle
      ? `${lineup.team.name}: ${lineup.title}`
      : lineup.team.name;
  }

  return null;
}

function DebugLog({ log }) {
  return (
    <section>
      <Form.Group>
        <Form.Label>Log</Form.Label>
        <Form.Control
          readOnly
          as="textarea"
          rows={10}
          value={log}
        ></Form.Control>
      </Form.Group>
    </section>
  );
}

function SessionSetupScreenInner({ session }) {
  const [pageKey, setPageKey] = useState('INFO');
  const { sessionKey } = useParams();

  let page;

  switch (pageKey) {
    case 'INFO':
      page = <SessionInfo session={session} />;
      break;
    case 'ROSTERS':
      page = <SessionRosters session={session} />;
      break;
    case 'ROTATIONS':
      page = <SessionRotations session={session} />;
      break;
    case 'JUDGES':
      page = <SessionJudges session={session} />;
      break;
    case 'STREAMS':
      page = <SessionStreams session={session} />;
      break;
    case 'LINEUPS':
      page = <SessionLineups session={session} />;
      break;
    case 'ROUTINES':
      page = <SessionRoutines session={session} />;
      break;
    case 'SESSION':
      page = <div>Returning to session...</div>;
      break;
    case 'API (JSON)':
      page = <div>Opening session API (JSON)...</div>;
      break;
    case 'API (XML)':
      page = <div>Opening session API (XML)...</div>;
      break;
    default:
      page = <div>Unknown setup page {pageKey}</div>;
      break;
  }

  return (
    <div className="main">
      <Header>
        <UserBubble />
        <MenuBubble />
      </Header>
      <div className="d-flex">
        <Nav
          variant="pills"
          activeKey={pageKey}
          className="flex-column p-2"
          onSelect={(key) => key !== 'SESSION' && setPageKey(key)}
        >
          <Nav.Link eventKey="INFO">Info</Nav.Link>
          <Nav.Link eventKey="ROSTERS">Rosters</Nav.Link>
          <Nav.Link eventKey="ROTATIONS">Rotations</Nav.Link>
          <Nav.Link eventKey="JUDGES">Judges</Nav.Link>
          <Nav.Link eventKey="STREAMS">Streams</Nav.Link>
          <Nav.Link eventKey="LINEUPS">Lineups</Nav.Link>
          <Nav.Link eventKey="ROUTINES">Routines</Nav.Link>
          <Nav.Link eventKey="SESSION" href={`/session?s=${sessionKey}`}>
            Session ⏎
          </Nav.Link>
          <Nav.Link
            eventKey="SESSION"
            href={`https://localhost:3001/session?s=${sessionKey}`}
          >
            Dev ⏎
          </Nav.Link>
          <Nav.Link
            eventKey="SESSION"
            href={`https://virti.us/session?s=${sessionKey}`}
          >
            Prod ⏎
          </Nav.Link>
          <Nav.Link
            eventKey="API (JSON)"
            href={`https://api.virti.us/session/${sessionKey}/json`}
          >
            API (JSON) ⏎
          </Nav.Link>
          <Nav.Link
            eventKey="API (XML)"
            href={`https://api.virti.us/session/${sessionKey}/xml`}
          >
            API (XML)⏎
          </Nav.Link>
        </Nav>
        <div className="flex-column p-4 setup-pages">
          <PageContext.Provider value={{ pageKey, setPageKey }}>
            {page}
          </PageContext.Provider>
        </div>
      </div>
    </div>
  );
}

function useSessionConfiguration({
  type,
  squadCount,
  alternating,
  gender,
  manualRotations,
}) {
  let errorMessage;
  const squadCountInt = parseInt(squadCount, 10);
  const events = useApparatusList(gender, alternating, squadCountInt);

  const squads = ['A'];
  if (type === SessionType.DUAL) {
    if (alternating) {
      squads.push('B');
    }
  } else if (type === SessionType.MULTI && !isNaN(squadCountInt)) {
    if (squadCountInt > 2 * events.length) {
      errorMessage = `Can't have more squads (${squadCountInt}) than 2 flights of (${events.length}) events`;
    }

    for (let i = 1; i < Math.min(squadCountInt, 2 * events.length); i++) {
      squads.push(String.fromCharCode('A'.charCodeAt(0) + i));
    }
  }

  if (alternating && events.length % squads.length !== 0) {
    errorMessage = `Alternating format doesn't work for ${squads.length} squads with ${events.length} events!`;
  }

  const numRotations = Math.max(events.length, squadCountInt);

  const rotations = [];
  for (let r = 0; r < numRotations; r++) {
    rotations[r] = {};
    for (let s = 0; s < squads.length; s++) {
      let eventIndex;
      if (alternating) {
        eventIndex =
          squads.length * Math.floor(r / squads.length) +
          ((r + s) % squads.length);
      } else {
        eventIndex = (r + s) % events.length;
      }
      rotations[r][events[eventIndex]] = squads[s];
    }
  }

  return { squads, rotations, events, errorMessage };
}

// function useSessionManualConfiguration({ rotations }) {
//   let errorMessage;
//   const squads = [];
//   const events = [];

//   for (let r = 0; r < events.length; r++) {
//     rotations[r] = {};
//     for (let s = 0; s < squads.length; s++) {
//       let eventIndex = (r + s) % events.length;
//       rotations[r][events[eventIndex]] = squads[s];
//     }
//   }

//   return { squads, rotations, events, errorMessage };
// }

// TODO: add this back
// const bySquad = (
//   <section>
//     <h3>Squads</h3>
//     <div className="d-flex">
//       {squads.map((squad, squadIndex) => (
//         <Card key={`squad${squadIndex}`}>
//           <Card.Header>Squad {squads[squadIndex]}</Card.Header>
//           <Card.Body>
//             {rotations.map((rotation, rotationIndex) => (
//               <p key={`rotation${rotationIndex}-stage${rotation}`}>
//                 Rotation {rotationIndex + 1} :{' '}
//                 {Object.keys(rotation)?.[squadIndex] || ''}
//               </p>
//             ))}
//           </Card.Body>
//         </Card>
//       ))}
//     </div>
//   </section>
// );

function SessionInfo({ session }) {
  const [type, setType] = useState(session.type);
  const [log, setLog] = useState('');
  const [updateSession] = useMutation(UpdateSession);
  const [updateSessionRoster] = useMutation(UpdateSessionRoster);
  const [configuring, setConfiguring] = useState(false);

  const sortedRotations = useSortedRotations();
  const sortedSessionRosters = useSortedSessionRosters();
  const sortedSessionRosterLineups = useSortedSessionRosterLineups();
  const sortedSessionTeams = useSortedSessionTeams();
  const sessionEvents = useSessionEvents();
  const sortedSquads = useSortedSquads();
  const sortedLineups = useSortedLineups();
  const squadsAssigned = sortedSquads.reduce((acc, squad) => {
    if (squad.rosters?.items?.length > 0) {
      acc++;
    }
    return acc;
  }, 0);
  //const scoreTypes = useSessionScoreTypes();
  const judgePool = useJudgePool();
  const [createSessionRoster] = useMutation(CreateSessionRoster);

  // console.log(session);
  // console.log(sortedRotations);
  // console.log(sortedSquads)
  // console.log(squadsAssigned)

  const isConfigured =
    sortedSessionTeams.length > 0 &&
    sortedSessionRosters.length >= sortedSessionTeams.length &&
    sortedSessionRosterLineups.length === sortedSessionRosters.length &&
    sortedRotations.length >= sessionEvents.length &&
    squadsAssigned >= sortedSessionRosters.length &&
    judgePool.length > 0;

  function handleAutoConfigure() {
    setConfiguring(true);
    handleTeamRosterAdd();
    handleRosterLineupAdd();
    //handleRotationAdd();
    //handleSquadAdd();
  }

  function handleTeamRosterAdd() {
    const defaultTeams = sortedSessionTeams.map((st) => st.team);
    const sortedSessionRosterIds = sortedSessionRosters.map((sr) => sr.id);
    const sessionLineups = sortedLineups;

    // Cancel if assigned rosters as many as teams
    if (defaultTeams.length <= sortedSessionRosters.length) {
      let date = new Date();
      const dateCancel = `${date.toLocaleString('en-US', {
        timeZoneName: 'short',
      })}: No additional rosters added.\n`;
      setLog(log + dateCancel);
      return;
    }

    defaultTeams.forEach((team, i) => {
      let isRosterAssigned = false;
      // let isLineupFound = false;

      const sortedTeamRosters = sortRosters(team?.rosters?.items);
      const teamRosterIds = sortedTeamRosters.map((r) => r.id);

      for (let i = 0; i < sortedSessionRosterIds.length; i++) {
        if (teamRosterIds.includes(sortedSessionRosters[i])) {
          isRosterAssigned = true;
          break;
        }
      }

      const lineup = sessionLineups.find((sl) => sl.teamId === team?.id);

      if (!isRosterAssigned) {
        createSessionRoster({
          variables: {
            input: {
              sessionId: session.id,
              rosterId: teamRosterIds[0],
              lineupId: lineup?.id,
              //squadStartPosition: 0,
            },
          },
        })
          .then((res) => {
            const date = new Date();
            const rosterLog = `${date.toLocaleString('en-US', {
              timeZoneName: 'short',
            })}: Roster ${sortedTeamRosters[0].title} added for ${team.name}\n`;

            let lineupLog = `${date.toLocaleString('en-US', {
              timeZoneName: 'short',
            })}: Lineup for ${team.name}: not found.\n`;

            if (lineup?.id) {
              lineupLog = `${date.toLocaleString('en-US', {
                timeZoneName: 'short',
              })}: Lineup attached to ${team.name}: ${
                res.data.createSessionRoster.roster.title
              } roster.\n`;
            }

            setLog((log) => log + rosterLog + lineupLog);

            if (i === defaultTeams.length - 1) {
              setConfiguring(false);
            }
          })
          .catch((err) => {
            setConfiguring(false);
          });
      }
    });
  }

  function handleRosterLineupAdd() {
    const sessionLineups = sortedLineups;

    sortedSessionRosters.forEach((sr) => {
      const lineup = sessionLineups.find(
        (sl) => sl.teamId === sr.roster.teamId
      );

      if (!lineup) {
        const date = new Date();
        const rosterLog = `${date.toLocaleString('en-US', {
          timeZoneName: 'short',
        })}: Lineup for ${sr.roster.team.name}: ${
          sr.roster.title
        } roster not found.\n`;
        setLog((log) => log + rosterLog);
        return;
      }

      updateSessionRoster({
        variables: {
          input: {
            id: sr.id,
            _version: sr._version,
            lineupId: lineup.id,
          },
        },
      }).then(() => {
        const date = new Date();
        const rosterLog = `${date.toLocaleString('en-US', {
          timeZoneName: 'short',
        })}: Lineup attached to ${sr.roster.team.name}: ${
          sr.roster.title
        } roster.\n`;
        setLog((log) => log + rosterLog);
      });
    });
  }

  // function handleRotationAdd() {}

  // function handleSquadAdd() {}

  return (
    <div>
      <h1>{session.name}</h1>
      <section>
        <Form.Group>
          <Form.Label>Type</Form.Label>
          <Form.Control
            as="select"
            value={type}
            onChange={(e) => {
              const t = e.target.value;
              setType(t);
              updateSession({
                variables: {
                  input: {
                    id: session.id,
                    _version: session._version,
                    type: t,
                  },
                },
              });
            }}
          >
            {Object.values(SessionType).map((s) => (
              <option key={s} value={s}>
                {s}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </section>
      <section>
        <Row>
          <Col className="center-line">{`Teams Detected: ${sortedSessionTeams.length}`}</Col>
          <Col className="center-line">{`Rosters Detected: ${sortedSessionRosters.length}`}</Col>
          <Col className="center-line">{`Lineups Linked: ${sortedSessionRosterLineups.length}`}</Col>
          <Col className="center-line">{`Rotations Detected: ${sortedRotations.length}`}</Col>
          <Col className="center-line">{`Squads Detected: ${sortedSquads.length}`}</Col>
          <Col className="center-line">{`Squads Assigned: ${squadsAssigned}`}</Col>
          <Col className="center-line">{`Judge Pool: ${judgePool.length}`}</Col>
          <Col
            className={[
              'center-line',
              isConfigured ? 'green-check' : 'red-x',
            ].join(' ')}
          >
            {`Configured: `}
            {isConfigured ? Icons.checkIcon : Icons.xIcon}
          </Col>
          <Col className="center-line">
            <Button
              //disabled={!!errorMessage || configuring}
              disabled={configuring || isConfigured}
              onClick={handleAutoConfigure}
            >
              Auto-Configure
            </Button>
          </Col>
        </Row>
      </section>
      <DebugLog log={log} />
    </div>
  );
}

function SessionLineups({ session }) {
  const sortedRotations = useSortedRotations();
  const { gender, rosters } = useContext(SessionContext);
  const apparatusesInOrder = getApparatusPossibilitiesByGender(gender);

  const rosterNamesBySessionRosterId = rosters.items.reduce(
    (acc, sessionRoster) => {
      if (!sessionRoster._deleted) {
        acc[sessionRoster.id] = sessionRoster.roster.team
          ? sessionRoster.roster.team.name
          : sessionRoster.roster.title;
      }
      return acc;
    },
    {}
  );

  return (
    <div>
      <h1>Lineups</h1>
      <div>
        {sortedRotations.map((rotation) => {
          const stagesByApparatus = rotation.stages.items.reduce(
            (acc, stage) => {
              if (!stage._deleted) {
                acc[stage.apparatus] = stage;
              }
              return acc;
            },
            {}
          );
          return (
            <section key={rotation.id}>
              <h3>Rotation {rotation.order + 1}</h3>
              <Container className="d-flex">
                {apparatusesInOrder.map(
                  (apparatus) =>
                    !apparatus.startsWith('BYE') &&
                    stagesByApparatus[apparatus] && (
                      <LineupCard
                        key={stagesByApparatus[apparatus].id}
                        stage={stagesByApparatus[apparatus]}
                        rosterNamesBySessionRosterId={
                          rosterNamesBySessionRosterId
                        }
                      />
                    )
                )}
              </Container>
            </section>
          );
        })}
      </div>
      {session.rosters.items.length === 0 && (
        <div>Please add rosters in order to specify their lineups</div>
      )}
    </div>
  );
}

function LineupCard({ stage, rosterNamesBySessionRosterId }) {
  const [isEditing, setIsEditing] = useState(false);
  const [deleteLineupMember] = useMutation(DeleteLineupMember);
  const [createLineupMember] = useMutation(CreateLineupMember);
  const [updateLineupMember] = useMutation(UpdateLineupMember);
  const typeaheadRef = useRef();
  const rosters = useRosters(stage.sessionId);

  const activeRosters = stage.squad.rosters.items
    .filter((sessionRoster) => !sessionRoster._deleted)
    .map((roster) => rosters[roster.id]);

  const lineupMembers = flatMap(
    activeRosters.map((sessionRoster) => {
      // console.log(sessionRoster);

      return sessionRoster.lineup.items.reduce((acc, lineupMember) => {
        if (
          !lineupMember._deleted &&
          lineupMember.apparatus === stage.apparatus
        ) {
          acc.push(lineupMember);
        }
        return acc;
      }, []);
    })
  ).sort((a, b) => (a.position > b.position ? 1 : -1));

  const lastLineupMember = last(lineupMembers);
  const nextPosition = lastLineupMember
    ? String.fromCharCode(lastLineupMember.position.charCodeAt(0) + 4)
    : 'A';
  const activeLineupMembersByAthleteContextId = lineupMembers.reduce(
    (acc, lineupMember) => {
      acc[lineupMember.athleteContextId] = lineupMember;
      return acc;
    },
    {}
  );
  const activeLineupMembersById = lineupMembers.reduce((acc, lineupMember) => {
    acc[lineupMember.id] = lineupMember;
    return acc;
  }, {});

  const availableRosterMembers = flatMap(
    activeRosters.map((sessionRoster) =>
      sessionRoster.roster.athleteContexts.items.reduce(
        (acc, rosterAthleteContext) => {
          if (
            !rosterAthleteContext._deleted &&
            !activeLineupMembersByAthleteContextId[
              rosterAthleteContext.athleteContextId
            ]
          ) {
            acc.push({
              sessionRosterId: sessionRoster.id,
              athleteContextId: rosterAthleteContext.athleteContextId,
              athleteName: rosterAthleteContext.athleteContext.athlete.name,
              rosterName: rosterNamesBySessionRosterId[sessionRoster.id],
            });
          }
          return acc;
        },
        []
      )
    )
  );

  const isDragDisabled = !isEditing || lineupMembers.length < 2;

  function handleDragEnd({ draggableId, destination }) {
    const { index: destinationIndex } = destination;
    const lineupMember = activeLineupMembersById[draggableId];
    const sourceIndex = lineupMembers.indexOf(lineupMember);

    function updatePosition(member, position) {
      return updateLineupMember({
        variables: {
          input: {
            id: member.id,
            _version: member._version,
            apparatus: stage.apparatus,
            position,
          },
        },
      });
    }

    function positionBetween(positionA, positionB) {
      if (positionA.length === positionB.length) {
        const a = positionA.charCodeAt(positionA.length - 1);
        const b = positionB.charCodeAt(positionB.length - 1);
        const mid = Math.floor((a + b) / 2);
        if (mid === a) {
          return `${positionA}a`;
        } else {
          return `${positionA.slice(
            0,
            positionA.length - 1
          )}${String.fromCharCode(mid)}`;
        }
      } else if (positionB.length > positionA.length) {
        return positionBetween(
          `${positionA}${Array(positionB.length - positionA.length)
            .fill('A')
            .join('')}`,
          positionB
        );
      } else {
        return positionBetween(
          positionA,
          `${positionB}${Array(positionA.length - positionB.length)
            .fill('z')
            .join('')}`
        );
      }
    }

    if (destinationIndex === sourceIndex) {
      return;
    } else if (lineupMembers.length === 2) {
      updatePosition(lineupMembers[0], lineupMembers[1].position);
      updatePosition(lineupMembers[1], lineupMembers[0].position);
    } else if (destinationIndex === 0) {
      updatePosition(lineupMember, lineupMembers[0].position);
      updatePosition(
        lineupMembers[0],
        positionBetween(lineupMembers[0].position, lineupMembers[1].position)
      );
    } else if (destinationIndex === lineupMembers.length - 1) {
      updatePosition(lineupMember, last(lineupMembers).position);
      updatePosition(
        last(lineupMembers),
        positionBetween(
          lineupMembers[lineupMembers.length - 2].position,
          last(lineupMembers).position
        )
      );
    } else {
      const position =
        destinationIndex < sourceIndex
          ? positionBetween(
              lineupMembers[destinationIndex - 1].position,
              lineupMembers[destinationIndex].position
            )
          : positionBetween(
              lineupMembers[destinationIndex].position,
              lineupMembers[destinationIndex + 1].position
            );

      updatePosition(lineupMember, position);
    }
  }
  const isSoloTeam = activeRosters.length === 1;

  return (
    <Card className="min" style={{ minWidth: '250px' }}>
      <Card.Header className="d-flex">
        <h4>{stage.apparatus}</h4>
        <Button
          className="ml-auto"
          variant={isEditing ? 'secondary' : 'outline-secondary'}
          onClick={() => setIsEditing(!isEditing)}
        >
          ✒
        </Button>
      </Card.Header>
      <Card.Body>
        {activeRosters.length === 0 && (
          <div>No rosters added to this squad</div>
        )}
        {isSoloTeam && <h5>{getSessionRosterName(activeRosters[0])}</h5>}
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable
            droppableId={`${stage.id}`}
            isDropDisabled={isDragDisabled}
          >
            {(provided) => (
              <Container {...provided.droppableProps} ref={provided.innerRef}>
                {lineupMembers.map((lineupMember, index) => (
                  <Draggable
                    key={lineupMember.id}
                    draggableId={lineupMember.id}
                    index={index}
                    isDragDisabled={isDragDisabled}
                  >
                    {(provided) => (
                      <Row
                        className="align-items-center py-2"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Col>
                          <div>
                            {index + 1}.{' '}
                            {lineupMember.athleteContext.athlete.name}
                          </div>
                          {!isSoloTeam && (
                            <div>
                              <small>
                                {
                                  rosterNamesBySessionRosterId[
                                    lineupMember.sessionRosterId
                                  ]
                                }
                              </small>
                            </div>
                          )}
                        </Col>
                        {isEditing && (
                          <Col>
                            <Button
                              variant="outline-danger"
                              onClick={() =>
                                deleteLineupMember({
                                  variables: {
                                    input: {
                                      id: lineupMember.id,
                                      _version: lineupMember._version,
                                    },
                                  },
                                })
                              }
                            >
                              <TrashIcon alt="Remove lineup member" />
                            </Button>
                          </Col>
                        )}
                      </Row>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Container>
            )}
          </Droppable>
        </DragDropContext>
        {isEditing && (
          <Typeahead
            id={`lineup-add-member-typeahead-${stage.id}`}
            ref={typeaheadRef}
            options={availableRosterMembers}
            onChange={(selectedRosterMembers) => {
              const selectedRosterMember = selectedRosterMembers[0];
              if (selectedRosterMember) {
                createLineupMember({
                  variables: {
                    input: {
                      sessionId: stage.sessionId,
                      sessionRosterId: selectedRosterMember.sessionRosterId,
                      apparatus: stage.apparatus,
                      athleteContextId: selectedRosterMember.athleteContextId,
                      position: nextPosition,
                    },
                  },
                }).then(() => {
                  if (typeaheadRef.current) {
                    typeaheadRef.current.clear();
                  }
                });
              }
            }}
            filterBy={['athleteName', 'rosterName']}
            labelKey="athleteName"
            clearButton
            placeholder="Add athlete to lineup..."
            renderMenuItemChildren={(option) => (
              <div style={{ fontWeight: '500' }}>
                {option.athleteName}
                {!isSoloTeam && (
                  <div style={{ padding: '0 1rem' }}>
                    <small>{option.rosterName}</small>
                  </div>
                )}
              </div>
            )}
          />
        )}
      </Card.Body>
    </Card>
  );
}

function SessionRosters({ session }) {
  const [selectedTeam, setSelectedTeam] = useState();
  const [selectedRosterId, setSelectedRosterId] = useState();
  const [
    createSessionRoster,
    { loading: isSessionRosterLoading },
  ] = useMutation(CreateSessionRoster);
  const [createRoster] = useMutation(CreateRoster);
  const sortedSessionRosters = useSortedSessionRosters();
  // const sortedSessionRosterLineups = useSortedSessionRosterLineups();
  // const sessionTeams = useSortedSessionTeams();
  // const defaultTeams = sessionTeams.map((st) => st.team);

  // console.log(teams);
  // console.log(sessionTeams);
  // console.log(defaultTeams);
  // console.log(sortedSessionRosters);

  // if (teams.length === 0) {
  //   teams.push({name: "Loading ..."})
  // }

  // function handleAutoAddTeamRosters() {
  //   sessionTeams.forEach((st) => {
  //     console.log(st);
  //   });
  //   return;
  // }

  function handleAddRoster() {
    createSessionRoster({
      variables: {
        input: {
          sessionId: session.id,
          rosterId: selectedRosterId,
        },
      },
    });
  }

  function handleCreateRoster() {
    createRoster({
      variables: {
        input: {
          title: `${session.name} Roster`,
        },
      },
    }).then(({ data }) => {
      return createSessionRoster({
        variables: {
          input: {
            sessionId: session.id,
            rosterId: data.createRoster.id,
          },
        },
      });
    });
  }

  return (
    <div>
      <h1>Rosters</h1>
      <Tabs>
        {sortedSessionRosters.map((sessionRoster, index) => (
          <Tab
            eventKey={sessionRoster.id}
            title={getSessionRosterName(sessionRoster)}
            key={`${sessionRoster.id}_${index}`}
          >
            <SessionRosterComponent sessionRoster={sessionRoster} key={index} />
          </Tab>
        ))}
        <Tab eventKey="add-new-roster-tab" title="➕">
          <Card>
            <Card.Header>
              <h2>Add Roster to Session</h2>
            </Card.Header>
            <Card.Body>
              <Row className="mb-3">
                <Col>
                  <TeamTypeahead
                    gender={session.gender}
                    clearOnSelect={false}
                    onSelected={(team) => {
                      const topTeamRosters = sortRosters(team.rosters.items);
                      const defaultRoster = (team && topTeamRosters) || [];
                      setSelectedTeam(team);
                      setSelectedRosterId(defaultRoster?.[0]?.id);
                      const result = new Promise((res) => {
                        res();
                      });
                      return result;
                    }}
                    prompt="Search for team..."
                    style={{ backgroundColor: 'inherit' }}
                  />
                </Col>
                <Col>
                  <Form.Control
                    as="select"
                    value={selectedRosterId}
                    disabled={!selectedTeam}
                    onChange={(e) => setSelectedRosterId(e.target.value)}
                  >
                    {!selectedTeam && (
                      <option key="empty" value="">
                        Select a roster...
                      </option>
                    )}
                    {selectedTeam &&
                      sortRosters(selectedTeam.rosters.items).map((roster) => (
                        <option key={roster.id} value={roster.id}>
                          {roster.title}
                        </option>
                      ))}
                  </Form.Control>
                </Col>
                <Col>
                  <Button
                    disabled={isSessionRosterLoading || !selectedRosterId}
                    onClick={handleAddRoster}
                  >
                    Add to Session
                  </Button>
                </Col>
              </Row>
              <Row className="px-3">
                <Button variant="secondary" onClick={handleCreateRoster}>
                  Create New Roster
                </Button>
              </Row>
            </Card.Body>
          </Card>
        </Tab>
      </Tabs>
    </div>
  );
}

function SessionRosterComponent({ sessionRoster }) {
  const [selectedTeamId, setSelectedTeamId] = useState();
  const session = useContext(SessionContext);
  const [rosterTitle, setRosterTitle] = useState(sessionRoster.roster.title);
  const [lineupId, setLineupId] = useState(sessionRoster.roster?.lineupId);
  const [deleteSessionRoster] = useMutation(DeleteSessionRoster);
  const [updateRoster] = useMutation(UpdateRoster);
  const [updateSessionRoster] = useMutation(UpdateSessionRoster);
  const [createLineup] = useMutation(CreateLineup);
  const [createAthleteContext] = useMutation(CreateAthleteContext);
  const { refetch } = useQuery(PrefetchSessionFull, {
    variables: { sessionKey: session.sessionKey },
  });
  const [createRosterAthleteContext] = useMutation(CreateRosterAthleteContext);
  const [deleteRosterAthleteContext] = useMutation(DeleteRosterAthleteContext);
  const [updateAthleteContext] = useMutation(UpdateAthleteContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [athleteSearchResults, setAthleteSearchResults] = useState(null);
  const apolloClient = useApolloClient();
  const [isEditing, setIsEditing] = useState(false);
  const sessionLineups = useSortedLineups();
  const typeaheadRef = useRef();
  const legacySessionRosterAthletes = useRosterAthletes(
    sessionRoster.roster.id
  );

  const activeAthleteContextsByAthleteId = sessionRoster.roster.athleteContexts.items.reduce(
    (acc, rosterAthleteContext) => {
      if (!rosterAthleteContext._deleted) {
        acc[rosterAthleteContext.athleteContext.athlete.id] =
          rosterAthleteContext.athleteContext;
      }
      return acc;
    },
    {}
  );

  function clearSearch() {
    setSearchTerm('');
    search('');
  }

  function search(searchTerm) {
    const trimmedSearchTerm = searchTerm.trim();
    setAthleteSearchResults(null);
    if (trimmedSearchTerm) {
      apolloClient
        .query({
          query: SearchFullAthletes,
          variables: {
            filter: {
              name: { matchPhrasePrefix: searchTerm.trim() },
              gender: { eq: session.gender },
            },
            limit: 100,
            sort: {
              direction: 'asc',
              field: 'name',
            },
          },
        })
        .then(({ data }) => setAthleteSearchResults(data.searchAthletes.items));
    }
  }
  const debouncedSearch = useCallback(debounce(search, 300), []);

  function handleUpdateRosterTitle() {
    updateRoster({
      variables: {
        input: {
          id: sessionRoster.roster.id,
          _version: sessionRoster.roster._version,
          title: rosterTitle,
        },
      },
    });
  }

  function handleRemoveSessionRoster() {
    deleteSessionRoster({
      variables: {
        input: {
          id: sessionRoster.id,
          _version: sessionRoster._version,
        },
      },
    });
  }

  function handleAttachLineupIdToRoster() {
    if (lineupId === 'CREATELINEUP') {
      createLineup({
        variables: {
          input: {
            teamId: curTeam.id,
            order: sessionLineups.length,
            title: `${teamBranding?.name} (Individuals)`,
            individuals: true,
            sessionId: session.id,
          },
        },
      }).then(({ data }) => {
        console.log(data);
        setLineupId(data.createLineup.id);
        updateSessionRoster({
          variables: {
            input: {
              id: sessionRoster.id,
              _version: sessionRoster._version,
              lineupId: data.createLineup.id,
            },
          },
        });
      });
    } else {
      updateSessionRoster({
        variables: {
          input: {
            id: sessionRoster.id,
            _version: sessionRoster._version,
            lineupId: lineupId,
          },
        },
      });
    }
  }

  function handleAttachTeamToRoster() {
    updateRoster({
      variables: {
        input: {
          id: sessionRoster.roster.id,
          _version: sessionRoster.roster._version,
          teamId: selectedTeamId,
        },
      },
    });
  }

  function handleConvertToAthleteContexts() {
    Promise.all(
      Object.values(legacySessionRosterAthletes).map((rosterLink) => {
        if (!rosterLink.active) {
          return Promise.resolve();
        }
        // TODO: allow adjustment of level
        const level = AthleteContextLevel.COLLEGE;
        let matchingContext = rosterLink.athlete.contexts.items.find(
          (athleteContext) =>
            athleteContext.level === level &&
            (!rosterLink.classYear ||
              athleteContext.division === `C_${rosterLink.classYear}`)
        );
        return Promise.resolve()
          .then(() => {
            if (!matchingContext) {
              const input = {
                athleteId: rosterLink.athlete.id,
                level,
              };
              if (rosterLink.classYear) {
                // TODO also hardcoded to college here
                input.division = `C_${rosterLink.classYear}`;
              }
              return createAthleteContext({
                variables: {
                  input,
                },
              }).then(({ data }) => data.createAthleteContext);
            } else {
              return matchingContext;
            }
          })
          .then((athleteContext) => {
            const connectedContext = sessionRoster.roster.athleteContexts.items.find(
              (rosterAthleteContext) =>
                rosterAthleteContext.athleteContext.id === athleteContext.id
            );
            if (connectedContext) {
              return connectedContext;
            }
            return createRosterAthleteContext({
              variables: {
                input: {
                  rosterId: sessionRoster.roster.id,
                  athleteContextId: athleteContext.id,
                },
              },
            }).then(({ data }) => data.createRosterAthleteContext);
          });
      })
    ).then(() => {
      return refetch();
    });
  }

  const sortedAthleteContexts = sessionRoster.roster.athleteContexts.items
    .filter((rac) => !rac._deleted)
    .slice()
    .sort((a, b) =>
      last(a.athleteContext.athlete.name.split(' ')) >
      last(b.athleteContext.athlete.name.split(' '))
        ? 1
        : -1
    );

  const curRoster = sessionRoster?.roster;
  const curTeam = curRoster?.team;
  const teamBranding = curTeam && teamBrand(curTeam);
  const curLineupName = getSessionRosterLineupName(sessionRoster, session);

  return (
    <Card key={sessionRoster.id} className="mb-3">
      <Card.Header className="d-flex align-items-center">
        <Row style={{ marginRight: '0' }}>
          <Col>
            {isEditing ? (
              <div>
                {curTeam && <h3>{teamBranding?.name}</h3>}
                <Row>
                  <Col xs={8}>
                    <input
                      className="form-control"
                      value={rosterTitle}
                      onChange={(e) => setRosterTitle(e.target.value)}
                    />
                  </Col>
                  <Col xs={4}>
                    <Button
                      disabled={rosterTitle === sessionRoster.roster.title}
                      onClick={() => handleUpdateRosterTitle()}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <h3 className="mb-0">{getSessionRosterName(sessionRoster)}</h3>
            )}
          </Col>
        </Row>
        <Row style={{ marginRight: '0' }}>
          <Col>
            {isEditing ? (
              <div>
                {sessionRoster.roster.team && (
                  <h3>{!isEditing ? curLineupName : 'Lineup'}</h3>
                )}
                <Row>
                  <Col xs={8}>
                    <Typeahead
                      style={{ display: 'inline-block' }}
                      id={`lineup-id-typeahead-${sessionRoster.id}`}
                      ref={typeaheadRef}
                      options={[
                        ...sessionLineups,
                        ...(curTeam
                          ? [
                              {
                                team: {
                                  name: '[+] Add New Lineup (Individuals)',
                                },
                                title: teamBranding?.name,
                                id: 'CREATELINEUP',
                              },
                            ]
                          : []),
                      ]}
                      onChange={(selected) => {
                        const selectedLineup = selected[0];
                        if (selectedLineup) {
                          setLineupId(selectedLineup.id);
                        }
                      }}
                      filterBy={['team', 'name']}
                      labelKey={(option) => `${option.team.name}`}
                      align="left"
                      clearButton
                      highlightOnlyResult={true}
                      placeholder={curLineupName ?? 'Add lineup to roster...'}
                      renderMenuItemChildren={(option) => (
                        <div style={{ fontWeight: '500' }}>
                          {option.team.name}: {option.title}
                        </div>
                      )}
                    />
                  </Col>
                  <Col xs={4}>
                    <Button
                      disabled={lineupId === sessionRoster.lineupId}
                      onClick={() => handleAttachLineupIdToRoster()}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <h3 className="mb-0">
                {sessionRoster?.lineupId
                  ? `${getSessionRosterLineupName(sessionRoster, session)}`
                  : null}
              </h3>
            )}
          </Col>
        </Row>
        <Row style={{ marginRight: '0' }}>
          <Col>
            {
              isEditing ? (
                <div>
                  {sessionRoster.roster.team && (
                    <h3>{!isEditing ? teamBranding?.name : 'Team'}</h3>
                  )}
                  <Row>
                    <Col xs={8}>
                      <TeamTypeahead
                        gender={session.gender}
                        clearOnSelect={false}
                        onSelected={(team) => {
                          setSelectedTeamId(team.id);
                          const result = new Promise((res) => {
                            res();
                          });
                          return result;
                        }}
                        prompt={teamBranding?.name ?? 'Add team to roster...'}
                        style={{ backgroundColor: 'inherit' }}
                      />
                    </Col>
                    <Col xs={4}>
                      <Button
                        disabled={
                          selectedTeamId === sessionRoster.roster.teamId
                        }
                        onClick={() => handleAttachTeamToRoster()}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </div>
              ) : null
              // <h3 className="mb-0">
              //   {sessionRoster?.lineupId
              //     ? `Lineup: ${getSessionRosterLineupName(
              //         sessionRoster,
              //         session
              //       )}`
              //     : null}
              // </h3>
            }
          </Col>
        </Row>
        <div className="ml-auto">
          <Button
            variant={isEditing ? 'secondary' : 'outline-secondary'}
            onClick={() => setIsEditing(!isEditing)}
          >
            ✒
          </Button>
          <Button
            className="ml-1"
            variant="outline-danger"
            onClick={() => handleRemoveSessionRoster()}
          >
            <TrashIcon alt="Remove roster from session" />
          </Button>
        </div>
      </Card.Header>
      <Card.Body>
        {Object.values(legacySessionRosterAthletes).length !== 0 &&
          sessionRoster.roster.athleteContexts.items.length === 0 && (
            <div>
              <div className="d-flex align-items-center">
                <h4>⚠️ Legacy RosterLinks</h4>
                <Button
                  className="ml-auto"
                  variant="link"
                  onClick={() => handleConvertToAthleteContexts(sessionRoster)}
                >
                  Convert to AthleteContexts
                </Button>
              </div>
              <div>
                {Object.values(legacySessionRosterAthletes).map(
                  (rosterLink) =>
                    rosterLink.active && (
                      <div key={rosterLink.id}>
                        {rosterLink.athlete.name} : {rosterLink.classYear}
                      </div>
                    )
                )}
              </div>
            </div>
          )}
        {(Object.values(legacySessionRosterAthletes).length === 0 ||
          sessionRoster.roster.athleteContexts.items.length !== 0) && (
          <div>
            <h4>AthleteContexts</h4>
            <div>
              {sortedAthleteContexts.map((rosterAthleteContext) => (
                <Row
                  key={rosterAthleteContext.id}
                  className="d-flex align-items-center"
                >
                  <Col>{rosterAthleteContext.athleteContext.athlete.name}</Col>
                  <Col>{rosterAthleteContext.athleteContext.level}</Col>
                  {isEditing ? (
                    <Col>
                      <Form.Control
                        as="select"
                        value={
                          rosterAthleteContext.athleteContext.division || ''
                        }
                        onChange={(e) => {
                          updateAthleteContext({
                            variables: {
                              input: {
                                id: rosterAthleteContext.athleteContext.id,
                                _version:
                                  rosterAthleteContext.athleteContext._version,
                                division: e.target.value,
                              },
                            },
                          });
                        }}
                      >
                        <option key="empty" value="">
                          Specify a division...
                        </option>
                        {Object.keys(AthleteContextDivision).map((key) => {
                          const value = AthleteContextDivision[key];
                          return (
                            (value.startsWith('C') ||
                              value.startsWith('JO')) && (
                              <option key={key} value={value}>
                                {last(value.split('_'))}
                              </option>
                            )
                          );
                        })}
                      </Form.Control>
                    </Col>
                  ) : (
                    <Col>
                      {last(
                        (
                          rosterAthleteContext.athleteContext.division || ''
                        ).split('_')
                      )}
                    </Col>
                  )}
                  {isEditing && (
                    <Col>
                      <Button
                        variant="outline-danger"
                        onClick={() =>
                          deleteRosterAthleteContext({
                            variables: {
                              input: {
                                id: rosterAthleteContext.id,
                                _version: rosterAthleteContext._version,
                              },
                            },
                          })
                        }
                      >
                        <TrashIcon alt="Remove athlete context from roster" />
                      </Button>
                    </Col>
                  )}
                </Row>
              ))}
              {isEditing && (
                <>
                  <Row>
                    <Col>
                      <h5>Add Athlete</h5>
                      <input
                        id={`athlete-search-${sessionRoster.id}`}
                        value={searchTerm}
                        placeholder="Athlete name..."
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                          debouncedSearch(e.target.value);
                        }}
                      />
                      <Button variant="outline-secondary" onClick={clearSearch}>
                        <TrashIcon alt="Clear search" />️
                      </Button>
                    </Col>
                  </Row>
                  {athleteSearchResults &&
                    athleteSearchResults.map((athlete) => {
                      return (
                        <RosterSearchResult
                          sessionRoster={sessionRoster}
                          athlete={athlete}
                          alreadyIncluded={
                            !!activeAthleteContextsByAthleteId[athlete.id]
                          }
                          clearSearch={clearSearch}
                          key={`${sessionRoster.id}_${athlete.id}`}
                        />
                      );
                    })}
                  {athleteSearchResults && athleteSearchResults.length === 0 && (
                    <Row>
                      <Col>
                        Create new Athlete for{' '}
                        <strong>{searchTerm.trim()}</strong> (TODO)
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

function RosterSearchResult({
  sessionRoster,
  athlete,
  alreadyIncluded,
  clearSearch,
}) {
  const [createRosterAthleteContext] = useMutation(CreateRosterAthleteContext);
  const [createAthleteContext] = useMutation(CreateAthleteContext);
  const [level, setLevel] = useState(AthleteContextLevel.COLLEGE);
  const [division, setDivision] = useState('');

  function handleAddExisting(athleteContext) {
    return createRosterAthleteContext({
      variables: {
        input: {
          rosterId: sessionRoster.roster.id,
          athleteContextId: athleteContext.id,
        },
      },
    }).then(() => {
      clearSearch();
    });
  }

  function handleAddNew() {
    const input = {
      athleteId: athlete.id,
      level,
    };
    if (division) {
      input.division = division;
    }
    createAthleteContext({
      variables: {
        input,
      },
    }).then(({ data }) => handleAddExisting(data.createAthleteContext));
  }

  const relevantDivisions = Object.keys(AthleteContextDivision).reduce(
    (acc, key) => {
      if (
        (level === AthleteContextLevel.COLLEGE && key.startsWith('C_')) ||
        (level === AthleteContextLevel.JO && key.startsWith('JO_'))
      ) {
        acc[key] = AthleteContextDivision[key];
      }
      return acc;
    },
    {}
  );

  return (
    <Card className="mt-2">
      <Card.Header>
        <strong>{athlete.name}</strong>
      </Card.Header>
      <Card.Body>
        {alreadyIncluded && <div>⚠️ Included above</div>}
        {!alreadyIncluded && (
          <>
            {athlete.contexts.items.map((athleteContext) => (
              <Row
                key={athleteContext.id}
                className="d-flex align-items-center mb-1"
              >
                <Col>{athleteContext.level}</Col>
                <Col>{last((athleteContext.division ?? '').split('_'))}</Col>
                <Col>
                  <Button onClick={() => handleAddExisting(athleteContext)}>
                    Add
                  </Button>
                </Col>
              </Row>
            ))}
            <Row key={`create-new-athlete-context-${athlete.id}`}>
              <Col>
                <Form.Control
                  as="select"
                  value={level}
                  onChange={(e) => setLevel(e.target.value)}
                >
                  <option key="empty" value="">
                    Select a level...
                  </option>
                  {Object.keys(AthleteContextLevel).map((levelKey) => {
                    const levelValue = AthleteContextLevel[levelKey];
                    return (
                      <option key={levelKey} value={levelValue}>
                        {last(levelKey.split('_'))}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
              <Col>
                <Form.Control
                  as="select"
                  value={division}
                  onChange={(e) => setDivision(e.target.value)}
                >
                  <option key="empty" value="">
                    Select a division...
                  </option>
                  {Object.keys(relevantDivisions).map((divisionKey) => {
                    const divisionValue = AthleteContextDivision[divisionKey];
                    return (
                      <option key={divisionKey} value={divisionValue}>
                        {last(divisionKey.split('_'))}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
              <Col>
                <Button onClick={() => handleAddNew()}>Create</Button>
              </Col>
            </Row>
          </>
        )}
      </Card.Body>
    </Card>
  );
}

function SessionStreams({ session }) {
  return (
    <div>
      <h1>Streams</h1>
      <SessionStreamPool />
      <SessionStreamAssignments />
    </div>
  );
}

function SessionStreamPool() {
  const session = useContext(SessionContext);

  function handleRemoveStream(stream) {
    console.log('TODO handle delete', stream);
  }

  const columnConfig = useMemo(() => [
    { Header: '#', accessor: 'not-used' },
    { Header: 'Index', accessor: 'index' },
    { Header: 'Ingest', accessor: 'ingestURL' },
    { Header: 'Output', accessor: 'outputURL' },
    { Header: 'RecordingId', accessor: 'recordingId' },
    {
      Header: '',
      accessor: '_version',
      Cell: ({ row }) => (
        <Button
          variant="outline-danger"
          onClick={() => handleRemoveStream(row.values)}
        >
          <TrashIcon alt="Remove stream from session" />
        </Button>
      ),
    },
  ]);

  return (
    <section>
      <h2>Pool</h2>
      <TableComponent
        columns={columnConfig}
        data={session.streams.items
          .filter((stream) => !stream._deleted)
          .slice()
          .sort((a, b) => (a.index > b.index ? 1 : -1))}
      />
    </section>
  );
}

function SessionStreamAssignments() {
  const session = useContext(SessionContext);
  const rotations = useSortedRotations();
  const events = useSessionEvents();
  const [createStageStream] = useMutation(CreateStageStream);
  const [deleteStageStream] = useMutation(DeleteStageStream);

  function copyStageAssignmentsByApparatus(templateStage) {
    function reduceStreamsById(list) {
      return list.reduce((acc, stageStream) => {
        if (!stageStream._deleted) {
          acc[stageStream.stream.id] = stageStream;
        }
        return acc;
      }, {});
    }

    const templateStreamsById = reduceStreamsById(templateStage.streams.items);

    session.rotations.items.forEach((rotation) => {
      if (!rotation._deleted && rotation.id !== templateStage.rotationId) {
        rotation.stages.items.forEach((stage) => {
          if (!stage._deleted && stage.apparatus === templateStage.apparatus) {
            const stageStreamsById = reduceStreamsById(stage.streams.items);

            Object.keys(templateStreamsById).forEach((streamId) => {
              const templateStageStream = templateStreamsById[streamId];
              if (!stageStreamsById[streamId]) {
                createStageStream({
                  variables: {
                    input: {
                      sessionId: session.id,
                      rotationId: rotation.id,
                      stageId: stage.id,
                      streamId: templateStageStream.streamId,
                    },
                  },
                });
              } else {
                // There's not actually an update case until the StageStream grows properties
                // const existingStageStream =
                //   stageStreamsById[streamId];
                // updateStageStream({
                //   variables: {
                //     input: {
                //       id: existingStageStream.id,
                //       _version: existingStageStream._version,
                //     },
                //   },
                // });
              }
            });

            Object.keys(stageStreamsById).forEach((streamId) => {
              if (!templateStreamsById[streamId]) {
                const existingStageStream = stageStreamsById[streamId];
                deleteStageStream({
                  variables: {
                    input: {
                      id: existingStageStream.id,
                      _version: existingStageStream._version,
                    },
                  },
                });
              }
            });
          }
        });
      }
    });
  }

  // Potentially add: copyStageAssignmentsBySquad

  return (
    <section>
      <h2>Stage Assignments</h2>
      <Container>
        {rotations.map((rotation) => {
          const stagesByApparatus = getStagesByApparatus(rotation.stages.items);
          return (
            <div key={rotation.id}>
              <h3>Rotation {rotation.order + 1}</h3>
              <Container>
                {events.map((apparatus) => {
                  const stage = stagesByApparatus[apparatus];
                  return !apparatus.startsWith('BYE') && !!stage ? (
                    <StageStreamCard
                      key={stage.id}
                      stage={stage}
                      copyStageAssignmentsByApparatus={
                        copyStageAssignmentsByApparatus
                      }
                    />
                  ) : null;
                })}
              </Container>
            </div>
          );
        })}
        {rotations.length === 0 && (
          <div>Please configure rotations before assigning streams</div>
        )}
      </Container>
    </section>
  );
}

function StageStreamCard({ stage, copyStageAssignmentsByApparatus }) {
  const session = useContext(SessionContext);
  const [isEditing, setIsEditing] = useState(false);
  const [deleteStageStream, { loading: isDeleteLoading }] = useMutation(
    DeleteStageStream
  );
  const [createStageStream, { loading: isCreateLoading }] = useMutation(
    CreateStageStream
  );

  const [selectedStreamId, setSelectedStreamId] = useState('');

  const activeStageStreams = stage.streams.items.filter(
    (stageStream) => !stageStream._deleted
  );

  const activeStreams = [...session.streams.items]
    .slice()
    .sort((a, b) => (a.index > b.index ? 1 : -1))
    .filter((stream) => !stream._deleted);

  return (
    <Card>
      <Card.Header className="d-flex">
        <h4>{stage.apparatus}</h4>
        <Button
          className="ml-auto"
          variant={isEditing ? 'secondary' : 'outline-secondary'}
          onClick={() => setIsEditing(!isEditing)}
        >
          ✒
        </Button>
      </Card.Header>
      <Card.Body>
        {activeStageStreams.map((stageStream) => (
          <Row key={stageStream.id} className="align-items-center">
            <Col>Stream {stageStream.stream.index}</Col>
            {isEditing && (
              <Col>
                <Button
                  variant="outline-danger"
                  disabled={isDeleteLoading}
                  onClick={() => {
                    deleteStageStream({
                      variables: {
                        input: {
                          id: stageStream.id,
                          _version: stageStream._version,
                        },
                      },
                    });
                  }}
                >
                  <TrashIcon alt="Remove stage stream" />
                </Button>
              </Col>
            )}
          </Row>
        ))}
        {isEditing && (
          <Row>
            <Col>
              <Form.Control
                as="select"
                value={selectedStreamId}
                onChange={(e) => setSelectedStreamId(e.target.value)}
              >
                <option key="empty" value="">
                  Select a stream...
                </option>
                {activeStreams.map(
                  (stream) =>
                    !stream._deleted && (
                      <option key={stream.id} value={stream.id}>
                        Stream {stream.index}
                      </option>
                    )
                )}
              </Form.Control>
            </Col>
            <Col>
              <Button
                disabled={!selectedStreamId || isCreateLoading}
                onClick={() => {
                  createStageStream({
                    variables: {
                      input: {
                        sessionId: stage.sessionId,
                        rotationId: stage.rotationId,
                        stageId: stage.id,
                        streamId: selectedStreamId,
                      },
                    },
                  });
                }}
              >
                Add
              </Button>
            </Col>
          </Row>
        )}
        {activeStageStreams.length !== 0 && (
          <Button
            variant="link"
            onClick={() => copyStageAssignmentsByApparatus(stage)}
          >
            Copy to other {stage.apparatus} stages
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}

function SessionJudges({ session }) {
  const sortedRotations = useSortedRotations();
  const scoreTypes = useSessionScoreTypes();
  const [availJudges, setAvailJudges] = useState([]);
  const [createSessionJudgeAssignment] = useMutation(
    CreateSessionJudgeAssignment
  );
  const [deleteSessionJudgeAssignment] = useMutation(
    DeleteSessionJudgeAssignment
  );
  const [selectedJudges, setSelectedJudges] = useState([]);
  const [createStageJudge, { loading: isCreateLoading }] = useMutation(
    CreateStageJudge
  );
  const [updateStageJudge] = useMutation(UpdateStageJudge);
  const [deleteStageJudge, { loading: isDeleteLoading }] = useMutation(
    DeleteStageJudge
  );
  const events = useSessionEvents();
  const apolloClient = useApolloClient();
  const [showCreateJudge, setShowCreateJudge] = useState(false);
  const [loadingJudges, setLoadingJudges] = useState(false);

  const activeJudgesByJudgeId = session.judgeAssignments.items.reduce(
    (acc, assignment) => {
      if (!assignment._deleted) {
        acc[assignment.judge.id] = assignment;
      }
      return acc;
    },
    {}
  );

  function searchJudges(query) {
    setLoadingJudges(true);
    const mensFilter = {
      or: [{ mensBrevet: { eq: true } }, { mensOptional: { eq: true } }],
    };
    const womensFilter = {
      or: [
        { womensBrevet: { eq: true } },
        { womensNational: { eq: true } },
        { womensLevel10: { eq: true } },
      ],
    };

    const filter = {
      ...(session.gender === GenderType.FEMALE ? womensFilter : mensFilter),
    };
    if (query) {
      filter.name = { matchPhrasePrefix: query };
    }

    apolloClient
      .query({
        query: SearchJudges,
        variables: {
          filter,
        },
      })
      .then(({ data }) => {
        setAvailJudges(
          data.searchJudges.items.filter(
            (judge) => !activeJudgesByJudgeId[judge.id]
          )
        );
        setLoadingJudges(false);
      });
  }

  useEffect(() => {
    searchJudges('');
  }, []);

  function handleCopyStageAssignments(templateStage) {
    function reduceActiveJudgesBySessionJudgeId(list) {
      return list.reduce((acc, j) => {
        if (!j._deleted) {
          acc[j.sessionJudgeAssignment.id] = j;
        }
        return acc;
      }, {});
    }

    const templateActiveJudgesBySessionJudgeId = reduceActiveJudgesBySessionJudgeId(
      templateStage.judges.items
    );

    session.rotations.items.forEach((rotation) => {
      if (!rotation._deleted && rotation.id !== templateStage.rotationId) {
        rotation.stages.items.forEach((stage) => {
          if (!stage._deleted && stage.apparatus === templateStage.apparatus) {
            const stageActiveJudgesBySessionJudgeId = reduceActiveJudgesBySessionJudgeId(
              stage.judges.items
            );

            Object.keys(templateActiveJudgesBySessionJudgeId).forEach(
              (sessionJudgeAssignmentId) => {
                const templateJudge =
                  templateActiveJudgesBySessionJudgeId[
                    sessionJudgeAssignmentId
                  ];
                if (
                  !stageActiveJudgesBySessionJudgeId[sessionJudgeAssignmentId]
                ) {
                  createStageJudge({
                    variables: {
                      input: {
                        sessionId: session.id,
                        rotationId: rotation.id,
                        stageId: stage.id,
                        sessionJudgeAssignmentId:
                          templateJudge.sessionJudgeAssignmentId,
                        scoreTypes: templateJudge.scoreTypes,
                      },
                    },
                  });
                } else {
                  const existingJudge =
                    stageActiveJudgesBySessionJudgeId[sessionJudgeAssignmentId];
                  updateStageJudge({
                    variables: {
                      input: {
                        id: existingJudge.id,
                        _version: existingJudge._version,
                        scoreTypes: templateJudge.scoreTypes,
                      },
                    },
                  });
                }
              }
            );

            Object.keys(stageActiveJudgesBySessionJudgeId).forEach(
              (sessionJudgeAssignmentId) => {
                if (
                  !templateActiveJudgesBySessionJudgeId[
                    sessionJudgeAssignmentId
                  ]
                ) {
                  const existingJudge =
                    stageActiveJudgesBySessionJudgeId[sessionJudgeAssignmentId];
                  deleteStageJudge({
                    variables: {
                      input: {
                        id: existingJudge.id,
                        _version: existingJudge._version,
                      },
                    },
                  });
                }
              }
            );
          }
        });
      }
    });
  }

  function StageJudgeCard({ rotation, stage }) {
    const [isEditing, setIsEditing] = useState(false);

    function NewJudgeInput() {
      const [sessionJudgeAssignmentId, setSessionJudgeId] = useState('');
      const [scoreTypeSelection, setScoreTypeSelection] = useState([]);

      function handleCreateStageJudge() {
        const input = {
          sessionId: session.id,
          rotationId: rotation.id,
          stageId: stage.id,
          sessionJudgeAssignmentId,
          scoreTypes: scoreTypeSelection,
        };
        createStageJudge({ variables: { input } });
      }

      const sortedJudges = [...session.judgeAssignments.items];
      sortedJudges.slice().sort((a, b) => {
        if (a.judge.name < b.judge.name) {
          return -1;
        }
        if (a.judge.name > b.judge.name) {
          return 1;
        }
        return 0;
      });

      //console.log(sortedJudges);

      return (
        <Row className="mt-4" key={`${stage.id}-new-stage-judge`}>
          <Col xs={5}>
            <Typeahead
              style={{ display: 'inline-block' }}
              id={`sessionJudge-id-typeahead-${stage.id}`}
              options={sortedJudges
                .filter((sja) => !sja._deleted)
                .map((sessionJudgeAssignment) => {
                  return sessionJudgeAssignment;
                })}
              onChange={(selected) => {
                const selectedJudge = selected[0];
                if (selectedJudge) {
                  setSessionJudgeId(selectedJudge.id);
                }
              }}
              labelKey={(option) => `${option?.judge?.name}`}
              align="left"
              clearButton
              highlightOnlyResult={true}
              placeholder="Select a judge..."
              renderMenuItemChildren={(option) => (
                <div style={{ fontWeight: '500' }}>{option?.judge?.name}</div>
              )}
            />
          </Col>
          <Col xs={5}>
            <ToggleButtonGroup
              name="scoreTypeSelection"
              className="apparatusToggle"
              type="checkbox"
              value={scoreTypeSelection}
              onChange={setScoreTypeSelection}
            >
              {scoreTypes.map((scoreType) => (
                <ToggleButton
                  variant="outline-secondary"
                  value={scoreType}
                  key={scoreType}
                >
                  {scoreType}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Col>
          <Col xs={2}>
            <Button
              onClick={handleCreateStageJudge}
              disabled={
                isCreateLoading ||
                !(scoreTypeSelection.length && sessionJudgeAssignmentId)
              }
            >
              Add
            </Button>
          </Col>
        </Row>
      );
    }

    function StageJudge({ stageJudge }) {
      function handleRemoveJudge() {
        deleteStageJudge({
          variables: {
            input: {
              id: stageJudge.id,
              _version: stageJudge._version,
            },
          },
        });
      }

      return (
        <Row className="mt-2" key={stageJudge.id}>
          <Col xs={5}>{stageJudge.sessionJudgeAssignment?.judge?.name}</Col>
          <Col xs={5}>
            <ToggleButtonGroup
              name="scoreTypeSelection"
              className="apparatusToggle"
              type="checkbox"
              value={stageJudge.scoreTypes}
              onChange={(scoreTypeSelection) => {
                const input = {
                  id: stageJudge.id,
                  scoreTypes: scoreTypeSelection,
                  _version: stageJudge._version,
                };
                updateStageJudge({ variables: { input } });
              }}
            >
              {scoreTypes.map((scoreType) => (
                <ToggleButton
                  variant="outline-secondary"
                  value={scoreType}
                  key={scoreType}
                >
                  {scoreType}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Col>
          {isEditing && (
            <Col xs={2}>
              <Button
                variant="outline-danger"
                disabled={isDeleteLoading}
                onClick={handleRemoveJudge}
              >
                <TrashIcon alt="Remove judge" />
              </Button>
            </Col>
          )}
        </Row>
      );
    }

    const activeJudges = stage.judges.items.filter((judge) => !judge._deleted);

    const rankIt = (types) => {
      let rank = types.length;

      for (let i = 0; i < scoreTypes.length; i++) {
        if (types.includes(scoreTypes[i])) {
          rank = i;
          break;
        }
      }

      return rank;
    };

    const sortedJudges = activeJudges.slice().sort((a, b) => {
      if (rankIt(a.scoreTypes) < rankIt(b.scoreTypes)) {
        return -1;
      }
      if (rankIt(a.scoreTypes) > rankIt(b.scoreTypes)) {
        return 1;
      }
      return 0;
    });

    return (
      <Card key={stage.id}>
        <Card.Header className="d-flex">
          <h4>{apparatusName(stage.apparatus)}</h4>
          <Button
            className="ml-auto"
            variant={isEditing ? 'secondary' : 'outline-secondary'}
            onClick={() => setIsEditing(!isEditing)}
          >
            ✒
          </Button>
        </Card.Header>
        <Card.Body>
          {sortedJudges.length === 0 && <div>No judges assigned yet</div>}
          {sortedJudges.map((stageJudge) => (
            <StageJudge key={stageJudge.id} stageJudge={stageJudge} />
          ))}
          {isEditing && session.judgeAssignments.items.length !== 0 && (
            <NewJudgeInput />
          )}
          {sortedJudges.length !== 0 && (
            <Row className="mt-2">
              <Col>
                <Button
                  variant="link"
                  onClick={() => handleCopyStageAssignments(stage)}
                >
                  Copy to all {stage.apparatus} stages
                </Button>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    );
  }

  function handleRemoveSessionJudge(sessionJudgeAssignment) {
    deleteSessionJudgeAssignment({
      variables: {
        input: {
          id: sessionJudgeAssignment.id,
          _version: sessionJudgeAssignment._version,
        },
      },
    });
  }

  return (
    <>
      <h1>Judges</h1>
      <div>
        <h2>Pool</h2>
        {session.judgeAssignments.items.map(
          (sessionJudgeAssignment) =>
            !sessionJudgeAssignment._deleted && (
              <Row
                key={sessionJudgeAssignment.id}
                className="d-flex align-items-center"
              >
                <Col>{sessionJudgeAssignment.judge.name}</Col>
                <Col>
                  {session.gender === GenderType.FEMALE ? (
                    <>
                      {sessionJudgeAssignment.judge.womensBrevet && (
                        <small className="mr-1">WBREV</small>
                      )}
                      {sessionJudgeAssignment.judge.womensNational && (
                        <small className="mr-1">WNAT</small>
                      )}
                      {sessionJudgeAssignment.judge.womensLevel10 && (
                        <small className="mr-1">WL10</small>
                      )}
                    </>
                  ) : (
                    <>
                      {sessionJudgeAssignment.judge.mensBrevet && (
                        <small className="mr-1">MBREV</small>
                      )}
                      {sessionJudgeAssignment.judge.mensOptional && (
                        <small className="mr-1">MOPT</small>
                      )}
                      {sessionJudgeAssignment.judge.mensCompulsory && (
                        <small className="mr-1">MCOM</small>
                      )}
                    </>
                  )}
                </Col>
                <Col>
                  <Button
                    variant="outline-danger"
                    onClick={() =>
                      handleRemoveSessionJudge(sessionJudgeAssignment)
                    }
                  >
                    <TrashIcon alt="Delete judge" />
                  </Button>
                </Col>
              </Row>
            )
        )}
        <Row>
          <Col>
            <AsyncTypeahead
              id="judgeTypeahead"
              clearButton
              filterBy={['name']}
              isLoading={loadingJudges}
              onChange={(selectedJudges) => {
                setSelectedJudges(selectedJudges);
                if (selectedJudges.length !== 0) {
                  createSessionJudgeAssignment({
                    variables: {
                      input: {
                        sessionId: session.id,
                        judgeId: selectedJudges[0].id,
                      },
                    },
                  }).then((res) => {
                    // console.log(res)
                    setSelectedJudges([]);
                  });
                } else {
                  setSelectedJudges([]);
                }
              }}
              onSearch={searchJudges}
              selected={selectedJudges}
              labelKey="name"
              className="judgeTypeahead editing"
              placeholder="Add judge to pool..."
              options={availJudges}
              onKeyDown={(e) => e.stopPropagation()}
              renderMenuItemChildren={(option) => (
                <div style={{ fontWeight: '500' }}>
                  {option.name}
                  <div style={{ padding: '0 1rem' }}>
                    {option.womensBrevet && (
                      <small className="mr-1">WBREV</small>
                    )}
                    {option.womensNational && (
                      <small className="mr-1">WNAT</small>
                    )}
                    {option.womensLevel10 && (
                      <small className="mr-1">WL10</small>
                    )}
                    {option.mensBrevet && <small className="mr-1">MBREV</small>}
                    {option.mensOptional && (
                      <small className="mr-1">MOPT</small>
                    )}
                    {option.mensCompulsory && (
                      <small className="mr-1">MCOM</small>
                    )}
                  </div>
                </div>
              )}
            />
          </Col>
        </Row>
        {!showCreateJudge && (
          <Button variant="link" onClick={() => setShowCreateJudge(true)}>
            Add missing judge
          </Button>
        )}
        {showCreateJudge && (
          <CreateJudgeComponent
            session={session}
            setShowCreateJudge={setShowCreateJudge}
          />
        )}
      </div>
      <div>
        <h2>Stage Assignments</h2>
        {sortedRotations.map((rotation, index) => {
          const stagesByApparatus = getStagesByApparatus(rotation.stages.items);

          return (
            <section className="mb-3" key={index}>
              <h3>Rotation {rotation.order + 1}</h3>
              <div className="d-flex flex-wrap">
                {events.map((apparatus) => {
                  const stage = stagesByApparatus[apparatus];
                  if (!stage || stage.apparatus.startsWith('BYE')) {
                    return null;
                  } else {
                    return (
                      <StageJudgeCard
                        key={stage.id}
                        stage={stage}
                        rotation={rotation}
                      />
                    );
                  }
                })}
              </div>
            </section>
          );
        })}
        {sortedRotations.length === 0 && (
          <div>Please configure rotations before assigning judges</div>
        )}
      </div>
    </>
  );
}

function CreateJudgeComponent({ session, setShowCreateJudge }) {
  const [newJudgeCredentials, setNewJudgeCredentials] = useState([]);
  const [newJudgeName, setNewJudgeName] = useState('');
  const [createJudge] = useMutation(CreateJudge);
  const [createSessionJudgeAssignment] = useMutation(
    CreateSessionJudgeAssignment
  );

  function handleCreateNewJudge() {
    createJudge({
      variables: {
        input: {
          name: newJudgeName,
          womensBrevet: newJudgeCredentials.indexOf('womensBrevet') !== -1,
          womensNational: newJudgeCredentials.indexOf('womensNational') !== -1,
          womensLevel10: newJudgeCredentials.indexOf('womensLevel10') !== -1,
          mensBrevet: newJudgeCredentials.indexOf('mensBrevet') !== -1,
          mensOptional: newJudgeCredentials.indexOf('mensOptional') !== -1,
          mensCompulsory: newJudgeCredentials.indexOf('mensCompulsory') !== -1,
        },
      },
    })
      .then(({ data }) => {
        return createSessionJudgeAssignment({
          variables: {
            input: {
              sessionId: session.id,
              judgeId: data.createJudge.id,
            },
          },
        });
      })
      .then(() => {
        setShowCreateJudge(false);
      });
  }

  return (
    <div>
      <h3>New Judge</h3>
      <Row className="align-items-center">
        <Col>
          <input
            placeholder="Judge Name"
            value={newJudgeName}
            onChange={(e) => setNewJudgeName(e.target.value)}
          />
        </Col>
        <Col>
          <ToggleButtonGroup
            name="scoreTypeSelection"
            className="apparatusToggle"
            type="checkbox"
            value={newJudgeCredentials}
            onChange={setNewJudgeCredentials}
          >
            <ToggleButton variant="outline-secondary" value="womensBrevet">
              WBREV
            </ToggleButton>
            <ToggleButton variant="outline-secondary" value="womensNational">
              WNAT
            </ToggleButton>
            <ToggleButton variant="outline-secondary" value="womensLevel10">
              WL10
            </ToggleButton>
            <ToggleButton variant="outline-secondary" value="mensBrevet">
              MBREV
            </ToggleButton>
            <ToggleButton variant="outline-secondary" value="mensOptional">
              MOPT
            </ToggleButton>
            <ToggleButton variant="outline-secondary" value="mensCompulsory">
              MCOM
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>
        <Col>
          <Button
            disabled={
              !newJudgeName ||
              Object.values(newJudgeCredentials).filter((v) => !!v).length === 0
            }
            onClick={handleCreateNewJudge}
          >
            Create
          </Button>
        </Col>
      </Row>
    </div>
  );
}

function SessionRotations({ session }) {
  const { gender } = useContext(SessionContext);
  const [type, setType] = useState(session.type);
  const [alternating, setAlternating] = useState(session.alternating);
  const [autoAssignSquads, setAutoAssignSquads] = useState(true);
  const [syncing, setSyncing] = useState(false);
  const [syncVersion, setSyncVersion] = useState(0);
  const { refetch } = useQuery(PrefetchSessionFull, {
    variables: { sessionKey: session.sessionKey },
  });

  const [rosterAddition, setRosterAddition] = useState({});
  const [createRotation] = useMutation(CreateRotation);
  const [deleteRotation] = useMutation(DeleteRotation);
  const [createStage] = useMutation(CreateStage);
  const [deleteStage] = useMutation(DeleteStage);
  const [createSquad] = useMutation(CreateSquad);
  const [deleteSquad] = useMutation(DeleteSquad);
  const [updateSessionRoster] = useMutation(UpdateSessionRoster);
  const [updateSession] = useMutation(UpdateSession);

  const activeSquads = useSortedSquads();
  const activeLineups = useSortedLineups();
  const sortedSessionRosters = useSortedSessionRosters();
  //console.log(sortedSessionRosters);

  const activeSquadsByName = activeSquads.reduce((acc, squad) => {
    acc[squad.name] = squad;
    return acc;
  }, {});

  const [squadCount, setSquadCount] = useState(
    activeSquads.length
      ? `${activeSquads.length}`
      : type === SessionType.SOLO
      ? '1'
      : type === SessionType.DUAL
      ? '2'
      : session.rosters.items.length
  );

  const {
    squads: desiredSquads,
    events,
    rotations: desiredRotations,
    errorMessage,
  } = useSessionConfiguration({
    type,
    squadCount,
    alternating,
    gender: session.gender,
  });

  const sortedRotations = useSortedRotations();
  const sessionRosters = useRosters(session.id);

  const nextRotationIndex = sortedRotations.length
    ? last(sortedRotations).order + 1
    : 0;
  const apparatusesInOrder = getApparatusPossibilitiesByGender(gender);

  function handleSync() {
    setSyncing(true);

    const activeStagesByApparatusByRotation = [];

    Promise.resolve()
      .then(() => {
        return updateSession({
          variables: {
            input: {
              id: session.id,
              _version: session._version,
              type,
              alternating,
            },
          },
        });
      })
      .then(() => {
        const operations = [];
        if (sortedRotations.length < desiredRotations.length) {
          for (
            let rotationIndex = sortedRotations.length;
            rotationIndex < desiredRotations.length;
            rotationIndex++
          ) {
            operations.push(
              createRotation({
                variables: {
                  input: {
                    sessionId: session.id,
                    order: rotationIndex,
                    status: RotationStatus.CREATED,
                  },
                },
              }).then(
                ({ data }) =>
                  (sortedRotations[rotationIndex] = data.createRotation)
              )
            );
          }
        } else if (sortedRotations.length > desiredRotations.length) {
          for (
            let rotationIndex = desiredRotations.length;
            rotationIndex < sortedRotations.length;
            rotationIndex++
          ) {
            operations.push(
              deleteRotation({
                variables: {
                  input: {
                    id: sortedRotations[rotationIndex].id,
                    _version: sortedRotations[rotationIndex]._version,
                  },
                },
              }).then(() => delete sortedRotations[rotationIndex])
            );
          }
        }
        return Promise.all(operations);
      })
      .then(() => {
        const operations = [];

        if (activeSquads.length < desiredSquads.length) {
          desiredSquads.forEach((desiredSquad) => {
            if (!activeSquadsByName[desiredSquad]) {
              operations.push(
                createSquad({
                  variables: {
                    input: {
                      sessionId: session.id,
                      name: desiredSquad,
                    },
                  },
                }).then(
                  ({ data }) =>
                    (activeSquadsByName[desiredSquad] = data.createSquad)
                )
              );
            }
          });
        } else if (activeSquads.length > desiredSquads.length) {
          activeSquads.forEach((activeSquad) => {
            if (
              desiredSquads.indexOf(activeSquad.name) === -1 ||
              activeSquadsByName[activeSquad.name].id !== activeSquad.id
            ) {
              operations.push(
                deleteSquad({
                  variables: {
                    input: {
                      id: activeSquad.id,
                      _version: activeSquad._version,
                    },
                  },
                }).then(() => delete activeSquadsByName[activeSquad.name])
              );
            }
          });
        }
        return Promise.all(operations);
      })
      .then(() => {
        return Promise.all(
          sortedRotations.map((rotation, rotationIndex) => {
            const operations = [];
            activeStagesByApparatusByRotation[
              rotationIndex
            ] = getStagesByApparatus(rotation.stages.items);

            Object.keys(desiredRotations[rotationIndex]).forEach(
              (apparatus) => {
                if (
                  !activeStagesByApparatusByRotation[rotationIndex][apparatus]
                ) {
                  operations.push(
                    createStage({
                      variables: {
                        input: {
                          sessionId: session.id,
                          rotationId: rotation.id,
                          status: StageStatus.CREATED,
                          squadId:
                            activeSquadsByName[
                              desiredRotations[rotationIndex][apparatus]
                            ].id,
                          apparatus,
                        },
                      },
                    }).then((data) => {
                      activeStagesByApparatusByRotation[rotationIndex][
                        apparatus
                      ] = data.createStage;
                      if (
                        !sortedRotations[rotationIndex]?.stages.items.find(
                          (stage) => stage?.id === data.createStage?.id
                        )
                      ) {
                        sortedRotations[rotationIndex].stages.items.push(
                          data.createStage
                        );
                      }
                    })
                  );
                }
              }
            );
            Object.keys(
              activeStagesByApparatusByRotation[rotationIndex]
            ).forEach((apparatus) => {
              if (
                !desiredRotations[rotationIndex][apparatus] &&
                !activeStagesByApparatusByRotation[rotationIndex][apparatus]
                  ._deleted
              ) {
                operations.push(
                  deleteStage({
                    variables: {
                      input: {
                        id:
                          activeStagesByApparatusByRotation[rotationIndex][
                            apparatus
                          ].id,
                        _version:
                          activeStagesByApparatusByRotation[rotationIndex][
                            apparatus
                          ]._version,
                      },
                    },
                  }).then(
                    () =>
                      delete activeStagesByApparatusByRotation[rotationIndex][
                        apparatus
                      ]
                  )
                );
              }
            });
            return Promise.all(operations);
          })
        );
      })
      .then(() => {
        const operations = [];

        if (autoAssignSquads) {
          sortedSessionRosters.forEach((sr) => {
            const lineup = activeLineups.find((sl) => sl.id === sr.lineupId);

            if (lineup) {
              const squadName = desiredSquads[lineup.order];
              operations.push(
                updateSessionRoster({
                  variables: {
                    input: {
                      id: sr.id,
                      _version: sr._version,
                      squadId: activeSquadsByName[squadName].id,
                      squadStartPosition: 0,
                    },
                  },
                })
              );
            }
          });
        }

        return Promise.all(operations);
      })
      .then(() => {
        return refetch().then(() => {
          setSyncing(false);
          setSyncVersion((v) => v + 1);
        });
      })
      .catch((error) => {
        console.error('Sync error:', error);
        setSyncing(false);
      });
  }

  function handleAddRosterToSquad(sessionRosterId, squadName, event) {
    const squadStartPosition =
      Math.max(
        -1,
        ...activeSquadsByName[squadName].rosters.items
          .filter((r) => !r._deleted)
          .map((sessionRoster) => sessionRoster.squadStartPosition)
      ) + 1;
    const sessionRoster = session.rosters.items.find(
      (sessionRoster) => sessionRoster.id === sessionRosterId
    );

    updateSessionRoster({
      variables: {
        input: {
          id: sessionRosterId,
          _version: sessionRoster._version,
          squadId: activeSquadsByName[squadName].id,
          squadStartPosition,
        },
      },
    }).then(() => setRosterAddition({ ...rosterAddition, [event]: '' }));
  }

  function handleRemoveSessionRosterFromSquad(sessionRosterId) {
    const sessionRoster = session.rosters.items.find(
      (sessionRoster) => sessionRoster.id === sessionRosterId
    );

    updateSessionRoster({
      variables: {
        input: {
          id: sessionRosterId,
          _version: sessionRoster._version,
          squadId: null,
          squadStartPosition: -1,
        },
      },
    });
  }

  return (
    <div>
      <h1>Rotations</h1>
      <Tabs>
        <Tab eventKey="rotation-auto-mode" title="Auto">
          <Row className="center-line">
            <Col className="col-3">
              <Form.Group>
                <Row>
                  <Col className="col-3 center-label">
                    <Form.Label>Type: </Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      as="select"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      {Object.values(SessionType).map((s) => (
                        <option key={s} value={s}>
                          {s}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            {type === SessionType.MULTI && (
              <Col className="col-3">
                <Form.Group>
                  <Row>
                    <Col className="col-3 center-label">
                      <Form.Label>Squads</Form.Label>
                    </Col>
                    <Col>
                      <Form.Control
                        type="number"
                        min="1"
                        max={2 * events.length}
                        value={squadCount}
                        onChange={(e) => setSquadCount(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            )}
            <Col className="col-2">
              <Form.Check
                id="alternating-switch"
                label="Alternating"
                checked={alternating}
                onChange={(e) => setAlternating(e.target.checked)}
              />
            </Col>
            <Col className="col-2">
              <Form.Check
                id="assign-squads-switch"
                label="Auto-Assign Squads"
                checked={autoAssignSquads}
                onChange={(e) => setAutoAssignSquads(e.target.checked)}
              />
            </Col>
            <Col className="col-2">
              <Button disabled={!!errorMessage || syncing} onClick={handleSync}>
                Sync
              </Button>
            </Col>
          </Row>
          {errorMessage && <div>{errorMessage}</div>}
          {desiredRotations.map((stages, index) => {
            const rotation = sortedRotations[index];
            // console.log(rotation);
            const stagesByApparatus = (rotation
              ? rotation.stages.items
              : []
            ).reduce((acc, stage) => {
              if (!stage._deleted) {
                acc[stage.apparatus] = stage;
              }
              return acc;
            }, {});
            // console.log(stagesByApparatus);
            return (
              <section className="mb-3" key={`${index}-${syncVersion}`}>
                <h3>Rotation {index + 1}</h3>
                <div className="d-flex flex-wrap">
                  {events.map((event) => {
                    return (
                      <Card
                        key={`rotation${index}-${event}`}
                        style={{ minWidth: '250px', margin: '0.5rem' }}
                        bg={
                          !stages[event] || stagesByApparatus[event]
                            ? 'light'
                            : 'warning'
                        }
                      >
                        <Card.Header>
                          <h4>{apparatusName(event)}</h4>
                        </Card.Header>
                        <Card.Body>
                          {stages[event] && (
                            <>
                              <h5>{`Squad ${stages[event]}`}</h5>
                              {index === 0 &&
                                activeSquadsByName[stages[event]] && (
                                  <>
                                    <div>
                                      {activeSquadsByName[
                                        stages[event]
                                      ].rosters.items.map((sr) => {
                                        const sessionRoster =
                                          sessionRosters?.[sr.id];

                                        if (!sessionRoster) {
                                          return null;
                                        }

                                        const lineupTitle = getSessionRosterLineupName(
                                          sessionRoster,
                                          session,
                                          true
                                        );
                                        const customLineupTitle =
                                          lineupTitle !==
                                          sessionRoster.roster.team.name
                                            ? `: ${lineupTitle}`
                                            : '';

                                        return (
                                          <Row
                                            key={sessionRoster.id}
                                            className="d-flex align-items-left mb-1"
                                          >
                                            <Col className="col-8">
                                              {sessionRoster?.roster?.title &&
                                              sessionRoster.roster.team
                                                ? `${sessionRoster.roster.team.name}: ${sessionRoster.roster.title}${customLineupTitle}`
                                                : sessionRoster.roster.title
                                                ? sessionRoster.roster.title
                                                : sessionRoster.roster.team
                                                    .name}
                                            </Col>
                                            <Col>
                                              <Button
                                                variant="outline-danger"
                                                size="small"
                                                onClick={() =>
                                                  handleRemoveSessionRosterFromSquad(
                                                    sessionRoster.id
                                                  )
                                                }
                                              >
                                                <TrashIcon alt="Remove roster from squad" />
                                              </Button>
                                            </Col>
                                          </Row>
                                        );
                                      })}
                                    </div>
                                    <div>
                                      <Row>
                                        <Col className="col-8">
                                          <Form.Control
                                            as="select"
                                            value={rosterAddition[event]}
                                            onChange={(e) =>
                                              setRosterAddition({
                                                ...rosterAddition,
                                                [event]: e.target.value,
                                              })
                                            }
                                          >
                                            <option key="empty" value="">
                                              Select a roster...
                                            </option>
                                            {sortedSessionRosters.map(
                                              (sessionRoster) => {
                                                const lineupTitle = getSessionRosterLineupName(
                                                  sessionRoster,
                                                  session,
                                                  true
                                                );
                                                const customLineupTitle =
                                                  lineupTitle !==
                                                  sessionRoster.roster.team.name
                                                    ? `: ${lineupTitle}`
                                                    : '';

                                                return (
                                                  <option
                                                    key={sessionRoster.id}
                                                    value={sessionRoster.id}
                                                  >
                                                    {sessionRoster.roster
                                                      .title &&
                                                    sessionRoster.roster.team
                                                      ? `${sessionRoster.roster.team.name}: ${sessionRoster.roster.title}${customLineupTitle}`
                                                      : sessionRoster.roster
                                                          .title
                                                      ? sessionRoster.roster
                                                          .title
                                                      : sessionRoster.roster
                                                          .team.name}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </Form.Control>
                                        </Col>
                                        <Col>
                                          <Button
                                            disabled={!rosterAddition[event]}
                                            onClick={() =>
                                              handleAddRosterToSquad(
                                                rosterAddition[event],
                                                stages[event],
                                                event
                                              )
                                            }
                                          >
                                            Add
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </>
                                )}
                            </>
                          )}
                        </Card.Body>
                      </Card>
                    );
                  })}
                </div>
              </section>
            );
          })}
        </Tab>
        <Tab eventKey="rotation-manual-mode" title="Manual">
          {sortedRotations.map((rotation, index) => {
            const activeStagesByApparatus = rotation.stages.items.reduce(
              (acc, stage) => {
                if (!stage._deleted) {
                  acc[stage.apparatus] = stage;
                }
                return acc;
              },
              {}
            );
            return (
              <section key={index}>
                <h3>Rotation {rotation.order + 1}</h3>
                <div className="d-flex">
                  {apparatusesInOrder.map(
                    (apparatus, index) =>
                      activeStagesByApparatus[apparatus] && (
                        <ManualStageCard
                          stage={activeStagesByApparatus[apparatus]}
                          key={index}
                        />
                      )
                  )}
                  <NewStageCard
                    sessionId={session.id}
                    rotationId={rotation.id}
                    usedApparatuses={Object.keys(activeStagesByApparatus)}
                  />
                </div>
              </section>
            );
          })}
          <Button
            onClick={() =>
              createRotation({
                variables: {
                  input: {
                    sessionId: session.id,
                    order: nextRotationIndex,
                    status: RotationStatus.CREATED,
                  },
                },
              })
            }
          >
            Create Rotation
          </Button>
        </Tab>
      </Tabs>
    </div>
  );
}

function ManualStageCard({ stage }) {
  const [isEditing, setIsEditing] = useState(false);
  const [deleteStage] = useMutation(DeleteStage);
  const [rosterAddition, setRosterAddition] = useState('');
  const [updateSessionRoster] = useMutation(UpdateSessionRoster);
  const session = useContext(SessionContext);
  const sortedSessionRosters = useSortedSessionRosters();
  const sessionRosters = useRosters(stage.sessionId);

  // console.log(sortedSessionRosters)

  function handleRemoveSessionRosterFromSquad(sessionRosterId) {
    const sessionRoster = sessionRosters[sessionRosterId];

    updateSessionRoster({
      variables: {
        input: {
          id: sessionRosterId,
          _version: sessionRoster._version,
          squadId: null,
          squadStartPosition: -1,
        },
      },
    });
  }

  function handleAddRosterToSquad() {
    const squadStartPosition =
      Math.max(
        -1,
        ...stage.squad.rosters.items.map(
          (sessionRoster) => sessionRoster.squadStartPosition
        )
      ) + 1;

    const sessionRoster = sortedSessionRosters.find(
      (sessionRoster) => sessionRoster.id === rosterAddition
    );

    updateSessionRoster({
      variables: {
        input: {
          id: sessionRoster.id,
          _version: sessionRoster._version,
          squadId: stage.squad.id,
          squadStartPosition,
        },
      },
    }).then(() => setRosterAddition(''));
  }

  return (
    <Card key={stage.id}>
      <Card.Header className="d-flex">
        <h4>{stage.apparatus}</h4>
        <div className="ml-auto">
          <Button
            variant={isEditing ? 'secondary' : 'outline-secondary'}
            onClick={() => setIsEditing(!isEditing)}
          >
            ✒
          </Button>
          <Button
            className="ml-1"
            variant="outline-danger"
            onClick={() =>
              deleteStage({
                variables: {
                  input: {
                    id: stage.id,
                    _version: stage._version,
                  },
                },
              })
            }
          >
            <TrashIcon alt="Delete stage" />
          </Button>
        </div>
      </Card.Header>
      <Card.Body>
        <h5>Squad {stage.squad.name}</h5>
        <div>
          {stage.squad.rosters.items.map((sessionRoster) => {
            const lineupTitle = getSessionRosterLineupName(
              sessionRoster,
              session,
              true
            );
            const customLineupTitle =
              lineupTitle !== sessionRoster.roster.team.name
                ? `: ${lineupTitle}`
                : '';

            const squadTitle =
              sessionRoster.squadId === stage.squad.id
                ? `: ${stage.squad.name}`
                : '';

            // console.log(customLineupTitle);

            return (
              !sessionRoster._deleted && (
                <Row
                  key={sessionRoster.id}
                  className="d-flex align-items-center mb-1"
                >
                  <Col>
                    {sessionRoster.roster.title && sessionRoster.roster.team
                      ? `${sessionRoster.roster.team.name}: ${sessionRoster.roster.title}${customLineupTitle}${squadTitle}`
                      : sessionRoster.roster.title
                      ? sessionRoster.roster.title
                      : sessionRoster.roster.team.name}
                  </Col>

                  {isEditing && (
                    <Col>
                      <Button
                        variant="outline-danger"
                        size="small"
                        onClick={() =>
                          handleRemoveSessionRosterFromSquad(sessionRoster.id)
                        }
                      >
                        <TrashIcon alt="Remove session roster from squad" />
                      </Button>
                    </Col>
                  )}
                </Row>
              )
            );
          })}
        </div>
        {isEditing && (
          <div>
            <Row>
              <Col>
                <Form.Control
                  as="select"
                  value={rosterAddition}
                  onChange={(e) => setRosterAddition(e.target.value)}
                >
                  <option key="empty" value="">
                    Select a roster...
                  </option>
                  {sortedSessionRosters.map((sessionRoster) => {
                    const lineupTitle = getSessionRosterLineupName(
                      sessionRoster,
                      session,
                      true
                    );
                    const customLineupTitle =
                      lineupTitle !== sessionRoster.roster.team.name
                        ? `: ${lineupTitle}`
                        : '';

                    // console.log(sessionRoster, stage.squad.id, stage.squad.name)
                    const squadTitle =
                      sessionRoster.squadId === stage.squad.id
                        ? `: ${stage.squad.name}`
                        : '';
                    return (
                      <option key={sessionRoster.id} value={sessionRoster.id}>
                        {sessionRoster.roster.title && sessionRoster.roster.team
                          ? `${sessionRoster.roster.team.name}: ${sessionRoster.roster.title}${customLineupTitle}${squadTitle}`
                          : sessionRoster.roster.title
                          ? sessionRoster.roster.title
                          : sessionRoster.roster.team.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
              <Col>
                <Button
                  disabled={!rosterAddition}
                  onClick={() => handleAddRosterToSquad()}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

function NewStageCard({ sessionId, rotationId, usedApparatuses }) {
  const [selectedApparatus, setSelectedApparatus] = useState('');
  const [selectedSquadId, setSelectedSquadId] = useState('');
  const [newSquadName, setNewSquadName] = useState('');
  const { gender, squads } = useContext(SessionContext);
  const apparatuses = getApparatusPossibilitiesByGender(gender);
  const [createStage, { loading: creatingStage }] = useMutation(CreateStage);
  const [createSquad, { loading: creatingSquad }] = useMutation(CreateSquad);

  const activeSquads = squads.items.filter((squad) => !squad._deleted);

  function handleCreateStage() {
    (selectedSquadId === 'CREATE-NEW'
      ? createSquad({
          variables: {
            input: {
              sessionId,
              name: newSquadName,
            },
          },
        }).then(({ data }) => data.createSquad.id)
      : Promise.resolve(selectedSquadId)
    )
      .then((squadId) =>
        createStage({
          variables: {
            input: {
              sessionId,
              rotationId,
              apparatus: selectedApparatus,
              status: StageStatus.CREATED,
              squadId,
            },
          },
        })
      )
      .then(() => {
        setSelectedApparatus('');
        setSelectedSquadId('');
        setNewSquadName('');
      });
  }

  return (
    <Card style={{ minWidth: '100px' }} bg="warning" className="newStageCard">
      <Card.Header>
        <h4>New Stage</h4>
      </Card.Header>
      <Card.Body>
        <Form.Control
          as="select"
          value={selectedApparatus}
          onChange={(e) => {
            setSelectedApparatus(e.target.value);
          }}
        >
          <option key="empty" value="">
            Select an apparatus...
          </option>
          {apparatuses.map(
            (apparatus) =>
              usedApparatuses.indexOf(apparatus) === -1 && (
                <option key={apparatus} value={apparatus}>
                  {apparatus.indexOf('BYE') === 0 && ' - '}
                  {apparatusName(apparatus)}
                </option>
              )
          )}
        </Form.Control>
        <Form.Control
          as="select"
          value={selectedSquadId}
          onChange={(e) => {
            setSelectedSquadId(e.target.value);
          }}
        >
          <option key="empty" value="">
            Select a squad...
          </option>
          {activeSquads.map((squad) => (
            <option key={squad.id} value={squad.id}>
              {squad.name}
            </option>
          ))}
          <option key="create-new" value="CREATE-NEW">
            *Add New*
          </option>
        </Form.Control>
        {selectedSquadId === 'CREATE-NEW' && (
          <input
            value={newSquadName}
            onChange={(e) => setNewSquadName(e.target.value)}
            placeholder="New Squad Name"
          />
        )}
        <Button
          disabled={
            creatingStage ||
            creatingSquad ||
            !selectedApparatus ||
            !selectedSquadId ||
            (selectedSquadId === 'CREATE-NEW' && !newSquadName)
          }
          onClick={handleCreateStage}
        >
          Create
        </Button>
      </Card.Body>
    </Card>
  );
}

function sortRosters(rosters) {
  return rosters.slice().sort((a, b) => {
    // First priority: isDefault (true comes first)
    if (a.default !== b.default) {
      return b.default ? 1 : -1;
    }

    // Second priority: activeDate (most recent first)
    const aDate = a.activeDate ? new Date(a.activeDate) : new Date(0);
    const bDate = b.activeDate ? new Date(b.activeDate) : new Date(0);
    if (aDate.getTime() !== bDate.getTime()) {
      return bDate.getTime() - aDate.getTime();
    }

    // Third priority: title alphabetically
    return (a.title || '').localeCompare(b.title || '');
  });
}

function apparatusName(apparatus) {
  return apparatus.startsWith('BYE') ? 'BYE' : apparatus;
}

function SessionRoutines() {
  const { lineups, rotations, id } = useContext(SessionContext);
  const [updateRoutine] = useMutation(UpdateRoutine);
  const rosters = useRosters(id);

  const stagesByApparatusByTeam = {};
  const stagesByRotationByTeam = {};
  rotations.items.forEach((rotation) => {
    if (!rotation._deleted) {
      rotation.stages.items.forEach((stage) => {
        if (!stage._deleted) {
          const stageSquadRosters = stage.squad.rosters.items.map(
            (r) => rosters[r.id]
          );
          stageSquadRosters.forEach((sessionRoster) => {
            if (!sessionRoster._deleted) {
              const teamId = sessionRoster.roster.teamId;

              if (!teamId) {
                return;
              }

              if (!stagesByRotationByTeam[teamId]) {
                stagesByRotationByTeam[teamId] = [];
              }
              stagesByRotationByTeam[teamId][rotation.order] = stage;

              if (!stagesByApparatusByTeam[teamId]) {
                stagesByApparatusByTeam[teamId] = {};
              }
              stagesByApparatusByTeam[teamId][stage.apparatus] = stage;
            }
          });
        }
      });
    }
  });

  const events = useSessionEvents();

  function handleLinkRoutinesToStages(teamId, routinesByApparatus) {
    events.forEach((apparatus) => {
      routinesByApparatus[apparatus].forEach((routine) => {
        const stageId = stagesByApparatusByTeam[teamId][routine.apparatus].id;
        if (stageId && stageId !== routine.stageId) {
          updateRoutine({
            variables: {
              input: {
                id: routine.id,
                lineupId: routine.lineupId,
                order: routine.order,
                _version: routine._version,
                stageId,
              },
            },
          });
        }
      });
    });
  }

  return (
    <div>
      <h1>Routines</h1>
      <Tabs>
        {lineups.items.map((lineup, index) => {
          const {
            routinesByApparatus,
            isFullyLinked,
          } = lineup.routines.items.reduce(
            ({ routinesByApparatus, isFullyLinked }, routine) => {
              let newIsFullyLinked = isFullyLinked;
              if (
                !routine._deleted &&
                routine.status !== RoutineStatus.DELETED
              ) {
                if (!routinesByApparatus[routine.apparatus]) {
                  routinesByApparatus[routine.apparatus] = [routine];
                } else {
                  routinesByApparatus[routine.apparatus].push(routine);
                }
                newIsFullyLinked = isFullyLinked && routine.stageId;
              }

              return {
                routinesByApparatus,
                isFullyLinked: newIsFullyLinked,
              };
            },
            { routinesByApparatus: {}, isFullyLinked: true }
          );

          return (
            <Tab eventKey={lineup.id} title={lineup.team.name} key={index}>
              <Container>
                {!isFullyLinked ? (
                  <>
                    <Button
                      disabled={!stagesByApparatusByTeam[lineup.team.id]}
                      onClick={() =>
                        handleLinkRoutinesToStages(
                          lineup.team.id,
                          routinesByApparatus
                        )
                      }
                    >
                      Link to Stages
                    </Button>
                    {events.map(
                      (apparatus) =>
                        routinesByApparatus[apparatus] &&
                        routinesByApparatus[apparatus]?.map((routine) => (
                          <Row key={routine.id}>
                            <Col>{routine.athlete.name}</Col>
                            <Col>{routine.apparatus}</Col>
                            <Col>
                              {routine.stageId ? (
                                <span>Linked ✅</span>
                              ) : stagesByApparatusByTeam[lineup.team.id]?.[
                                  routine.apparatus
                                ]?.id ? (
                                <span>Available ⚠️</span>
                              ) : (
                                <span>Not available 🚫</span>
                              )}
                            </Col>
                          </Row>
                        ))
                    )}
                  </>
                ) : (
                  stagesByRotationByTeam[lineup.team.id] &&
                  stagesByRotationByTeam[lineup.team.id].map(
                    (stage, rotationOrder) => (
                      <Card key={stage.id}>
                        <Card.Header>
                          Rotation {rotationOrder + 1} : {stage.apparatus}
                        </Card.Header>
                        <Card.Body>
                          <Container>
                            {stage.routines.items.map((routine) => (
                              <Row>
                                <Col>{routine.athlete.name}</Col>
                                <Col>
                                  {convertMillipointsToDisplay(routine.score)}
                                </Col>
                              </Row>
                            ))}
                          </Container>
                        </Card.Body>
                      </Card>
                    )
                  )
                )}
              </Container>
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
}
