import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Button, Row, Alert, Form, ButtonGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./sessionadmin.css";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions, adminActions } from '../../redux/_actions';
import TableComponent from '../helpers/tablecomponent';
import { dateTime } from '../../utilities/conversions';
import SessionDetail from './sessiondetail';
import { accessType, sessionStatus, uiState } from '../../redux/_constants';
import { modeType } from '../../utilities/constants';
import { useHistory } from 'react-router-dom';
import { Loading, ScrollLoading } from '../helpers/loading';
import { SearchInput, addBold, noResults } from '../helpers/search';

function SessionAdmin(props) {
  const { setIsForm } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isScrollLoading, setIsScrollLoading] = useState(false);
  const { LIST, DETAIL, EDIT, DELETE } = modeType;
  const [mode, setMode] = useState(LIST);
  const [sessionId, setSessionId] = useState(null);
  const [sessionKey, setSessionKey] = useState(null);
  const alert = useSelector((state) => state.alert);
  const sessions = useSelector((state) => state.admin.sessions);
  const teams = useSelector((state) => state.admin.teams);
  const nextToken = useSelector(
    (state) => state.admin.sessions.tokens.nextToken
  );
  const searchQuery = useSelector(
    (state) => state.admin.sessions.tokens.searchQuery
  );
  const searchToken = useSelector(
    (state) => state.admin.sessions.tokens.searchToken
  );

  const [searchTerm, setSearchTerm] = useState(searchQuery || '');
  const [tempSearchTerm, setTempSearchTerm] = useState(searchQuery || ''); // used for async holding of query
  const [filters, setFilters] = useState({
    gender: null,
    status: null,
    start: null,
    demo: null,
    startDate: null,
    endDate: null
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const numSessions = sessions?.allIds?.length || 0;
  const isSearch = searchTerm !== '';
  let lastScrollTop = 0;

  useEffect(() => {
    if (!alert.clear) {
      setIsLoading(false);
    }
  }, [alert.clear]);

  useEffect(() => {
    dispatch(alertActions.clear());
  }, [dispatch]);

  useEffect(() => {
    if (numSessions === 0) {
      setIsLoading(true);
      dispatch(adminActions.getSessions());
    }
    setIsScrollLoading(false);
    setSearchTerm(tempSearchTerm);
  }, [sessions, dispatch, numSessions]);

  useEffect(() => {
    if (numSessions !== 0) {
      setIsLoading(false);
    }
  }, [teams, numSessions]);

  const sessionDetail = (e, row) => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    setSessionId(row.id);
    setSessionKey(row.key);
    setMode(DETAIL);
    setIsForm(true);
  };

  const back = () => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    setMode(LIST);
    setIsForm(false);
  };

  const headers = useMemo(
    () => [
      { Header: '#', accessor: 'order' },
      {
        Header: 'Name',
        accessor: 'name',
        maxWidth: 50,
        Cell: (r) => addBold(r.cell.value, searchTerm),
      },
      { Header: 'Type', accessor: 'type' },
      { Header: 'Gender', accessor: 'gender' },
      {
        Header: 'Key',
        accessor: 'key',
        Cell: ({ row }) => (
          <Link
            to={{
              pathname: '/session',
              search: `?s=${row.values.key}`,
              state: { fromAdmin: true },
            }}
          >
            {row.values.key}
          </Link>
        ),
      },
      {
        Header: 'Created',
        accessor: 'created',
        Cell: ({ row }) => {
          return dateTime(row.values.created);
        },
      },
      {
        Header: 'Start',
        accessor: 'start',
        Cell: ({ row }) => {
          return dateTime(row.values.start);
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (r) => {
          const { row, column, cell, updateMyData } = r;

          return (
            <Form.Control
              as="select"
              label="Status"
              id={`status-${row.original.id}`}
              value={cell.value}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                const payload = {
                  ...sessions.byId[row.original.id],
                  status: e.target.value,
                };
                dispatch(adminActions.editSession(payload));
                updateMyData(
                  row.index,
                  column.id,
                  e.target.value === '-' ? '' : e.target.value
                );
              }}
            >
              {Object.keys(sessionStatus).map((statusType) => (
                <option key={statusType} value={statusType}>
                  {statusType}
                </option>
              ))}
            </Form.Control>
          );
        },
      },
      {
        Header: 'Access',
        accessor: 'access',
        Cell: (r) => {
          const { row, column, cell, updateMyData } = r;
          return (
            <Form.Control
              as="select"
              label="Access"
              id={`access-${row.original.id}`}
              value={cell.value}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                const payload = {
                  ...sessions.byId[row.original.id],
                  access: e.target.value,
                };
                dispatch(adminActions.editSession(payload));
                updateMyData(
                  row.index,
                  column.id,
                  e.target.value === '-' ? '' : e.target.value
                );
              }}
            >
              {Object.keys(accessType).map((accessValue) => {
                return (
                  <option key={accessValue} value={accessValue}>
                    {accessValue}
                  </option>
                );
              })}
            </Form.Control>
          );
        },
      },
    ],
    [searchTerm, sessions]
  );

  const data = useMemo(() => {
    let filteredData = sessions.allIds.map((id, i) => {
      const {
        name,
        type,
        gender,
        sessionKey,
        createdAt,
        startAt,
        access,
        status /*athletes, league*/,
      } = sessions.byId[id];

      const row = {
        order: i + 1,
        name: name,
        type: type,
        gender: gender,
        key: sessionKey,
        created: createdAt,
        start: startAt,
        status: status,
        access: access,
        id: id,
      };
      return row;
    });

    // Apply filters
    if (filters.gender) {
      console.log('Filtering by gender:', filters.gender, typeof filters.gender);
      console.log('Before filter:', filteredData.length);
      filteredData = filteredData.filter(row => {
        console.log('Row gender:', row.gender, typeof row.gender);
        console.log('Comparison:', row.gender === filters.gender, JSON.stringify(row.gender), JSON.stringify(filters.gender));
        return row.gender === filters.gender;
      });
      console.log('After filter:', filteredData.length);
    }
    if (filters.status) {
      filteredData = filteredData.filter(row => row.status === filters.status);
    }
    if (filters.start === 'upcoming') {
      const today = new Date();
      const nextWeek = new Date(today);
      nextWeek.setDate(today.getDate() + 7);
      filteredData = filteredData.filter(row => {
        const startDate = new Date(row.start);
        return startDate >= today && startDate <= nextWeek;
      });
    }
    if (filters.demo === 'hide') {
      filteredData = filteredData.filter(row => {
        return !row.name.toLowerCase().includes('demo');
      });
    }
    if (filters.startDate) {
      filteredData = filteredData.filter(row => {
        const sessionDate = new Date(row.start);
        const startDate = new Date(filters.startDate);
        startDate.setHours(0, 0, 0, 0);
        
        if (filters.endDate) {
          const endDate = new Date(filters.endDate);
          endDate.setHours(23, 59, 59, 999);
          return sessionDate >= startDate && sessionDate <= endDate;
        }
        
        return sessionDate.toDateString() === startDate.toDateString();
      });
    }

    return filteredData;
  }, [sessions, filters]);

  const table = () => {
    const initialState = {
      sortBy: [{ id: 'start', desc: true }],
    };

    const empty = numSessions === 0 || data.length === 0;

    return empty ? (
      noResults('sessions')
    ) : (
      <TableComponent
        columns={headers}
        data={data}
        onClick={sessionDetail}
        initialState={initialState}
        searchTerm={searchTerm}
        textSort={true}
        showFooter={true}
        filterColumns={['name']}
      />
    );
  };

  const handleScroll = (e) => {
    const { scrollHeight, scrollTop, scrollLeft, clientHeight } = e.target;
    const bottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 1;
    const nextPage =
      (isSearch && searchToken !== null) ||
      (!isSearch && nextToken !== null && numSessions > 0);
    const vertScroll = scrollLeft === 0;

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;

    if (bottom && nextPage && vertScroll && !isScrollLoading) {
      setIsScrollLoading(true);
      dispatch(
        adminActions.getSessions({
          searchQuery: searchTerm,
          nextToken: isSearch ? searchToken : nextToken,
        })
      );
    }
  };

  const handleSearch = (query) => {
    setTempSearchTerm(query);

    if (query && query !== '') {
      dispatch(
        adminActions.getSessions({ searchQuery: query, nextToken: null })
      );
    } else {
      // clear search
      setSearchTerm(query);
    }
  };

  const adminBody = () => {
    return (
      <>
        {!alert.clear ? (
          <Row style={{ padding: '0 15px', margin: '1rem -15px' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
              style={{ textAlign: 'center', width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        {isLoading ? <Loading /> : table()}
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <Row className="vCenter" style={{ margin: '0 auto' }}>
          <Button
            variant="outline-primary"
            className="formButton"
            onClick={() => history.push(uiState.CREATE)}
            disabled={isLoading}
          >
            Add session
          </Button>
        </Row>
        <Row />
      </>
    );
  };

  const FilterPill = ({ label, value, filterKey, className = '' }) => (
    <Button
      variant={filters[filterKey] === value ? "secondary" : "outline-secondary"}
      className={`rounded-pill py-0 px-2 mx-0 ${className}`}
      size="sm"
      onClick={() => setFilters(prev => ({
        ...prev,
        [filterKey]: prev[filterKey] === value ? null : value
      }))}
    >
      {label}
    </Button>
  );

  const headerMsg = () => {
    return (
      <div style={{ width: '100%' }}>
        <div className="d-flex justify-content-between align-items-center">
          <span style={{ minWidth: '200px' }}>Session Admin</span>
          <div className="d-flex ms-auto" style={{ gap: '2px' }}>
            <FilterPill label="Male" value="MALE" filterKey="gender" />
            <FilterPill label="Female" value="FEMALE" filterKey="gender" />
            <FilterPill label="LIVE" value="LIVE" filterKey="status" />
            <FilterPill label="Hide Demos" value="hide" filterKey="demo" />
            <FilterPill label="+7 Days" value="upcoming" filterKey="start" />
            <div className="d-flex align-items-center">
              <DatePicker
                selectsRange={true}
                startDate={filters.startDate}
                endDate={filters.endDate}
                onChange={(update) => {
                  const [start, end] = update;
                  setFilters(prev => ({
                    ...prev,
                    startDate: start,
                    endDate: end
                  }));
                }}
                dateFormat="MM/dd/yyyy"
                className="date-picker-pill"
                placeholderText="Date Range"
                isClearable
                customInput={
                  <Button 
                    size="sm" 
                    variant={filters.startDate ? "secondary" : "outline-secondary"}
                    className="rounded-pill py-0 px-3"
                    style={{ minWidth: '160px' }}
                  >
                    {filters.startDate 
                      ? filters.endDate 
                        ? `${new Date(filters.startDate).toLocaleDateString()} - ${new Date(filters.endDate).toLocaleDateString()}`
                        : new Date(filters.startDate).toLocaleDateString()
                      : 'Date Range'}
                  </Button>
                }
              />
              {(filters.startDate || filters.endDate) && (
                <Button
                  size="sm"
                  variant="link"
                  className="ms-1"
                  onClick={() => setFilters(prev => ({
                    ...prev,
                    startDate: null,
                    endDate: null
                  }))}
                  title="Clear dates"
                >
                  ✕
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const listModal = () => {
    return (
      <>
        <Modal.Header className="searchHeader">
          {headerMsg()}
          {numSessions !== 0 ? (
            <SearchInput searchTerm={searchTerm} search={handleSearch} />
          ) : null}
        </Modal.Header>
        <Modal.Body className="setupForm" onScroll={handleScroll}>
          {adminBody()}
          {isScrollLoading ? <ScrollLoading /> : null}
        </Modal.Body>
        <Modal.Footer className="setupFooter">{footer()}</Modal.Footer>
      </>
    );
  };

  const modal = () => {
    switch (mode) {
      case EDIT:
        break;
      case DELETE:
        break;
      case LIST:
        return listModal();
      case DETAIL:
        return (
          <SessionDetail
            back={back}
            sessionId={sessionId}
            sessionKey={sessionKey}
          />
        );
      default:
        return null;
    }
  };

  return modal();
}

export default SessionAdmin;
