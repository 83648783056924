import { useEffect, useState, useRef } from 'react';
import { Modal, Tabs, Tab, Button, Row, Spinner, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from '../../redux/_actions';
import { adminActions } from '../../redux/_actions';
import { SessionSetup } from './sessionsetup';
import { SessionJudges } from './sessionjudges';
// import { SessionClips } from './sessionclips';
// import { SessionScores } from './sessionscores';
import { SessionStreams } from './sessionstreams';
import { SessionLineups } from './sessionlineups';
import { SessionRoutines } from './sessionroutines';
import { SessionAdminFunctions } from './sessionadminfunctions';
import { Link } from 'react-router-dom';
import { SessionSubscriptionManager } from '../session/hooks';

function SessionDetail(props) {
  const { back, sessionId, sessionKey } = props;
  const [isLoading, setIsLoading] = useState(props?.isLoading);
  const [isEditing, setIsEditing] = useState(false);
  const [key, setKey] = useState('setup');
  const [footerDisabled, setFooterDisabled] = useState(false);
  const { alert } = useSelector((state) => state);
  const { sessions } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const tabRef1 = useRef();
  const tabRef2 = useRef();
  const tabRef3 = useRef();
  const tabRef4 = useRef();
  const tabRef5 = useRef();
  const tabRef6 = useRef();
  const tabRef7 = useRef();
  const tabRef8 = useRef();
  const [session, setSession] = useState(null);
  const isReady = !!sessions.byId?.[sessionId]?.id;

  useEffect(() => {
    if (!alert.clear) {
      setIsLoading(false);
    }
  }, [alert.clear]);

  useEffect(() => {
    dispatch(alertActions.clear());
  }, [dispatch]);

  useEffect(() => {
    if (!session) {
      if (!isLoading) {
        setIsLoading(true);
        dispatch(adminActions.getSession(sessionKey));
      } else {
        const newSession = sessions.byId?.[sessionId];
        if (newSession) {
          setIsLoading(false);
          setSession(newSession);
        }
      }
    }
  }, [session, sessionKey, sessionId, adminActions, isReady, isLoading]);

  const handleClick = (e) => {
    switch (key) {
      case 'setup':
        if (isEditing) {
          save(tabRef1);
          setIsEditing(false);
        } else {
          setIsEditing(true);
        }
        break;
      case 'streams':
        //refresh(tabRef2);
        if (isEditing) {
          save(tabRef2);
          setIsEditing(false);
        } else {
          setIsEditing(true);
        }
        break;
      case 'lineups':
        if (isEditing) {
          save(tabRef3);
          setIsEditing(false);
        } else {
          setIsEditing(true);
        }
        break;
      case 'routines':
        if (isEditing) {
          save(tabRef4);
          setIsEditing(false);
        } else {
          setIsEditing(true);
        }
        break;
      case 'scores':
        if (isEditing) {
          save(tabRef5);
          setIsEditing(false);
        } else {
          setIsEditing(true);
        }
        break;
      case 'clips':
        if (isEditing) {
          save(tabRef6);
          setIsEditing(false);
        } else {
          setIsEditing(true);
        }
        break;
      case 'judges':
        if (isEditing) {
          save(tabRef7);
          setIsEditing(false);
        } else {
          setIsEditing(true);
        }
        break;
      default:
        break;
    }
  };

  const changeTab = (k) => {
    setIsEditing(false);
    setKey(k);
  };

  const buttonText = () => {
    if (isLoading) {
      return (
        <>
          {'Saving... '}
          <Spinner
            variant="primary"
            role="status"
            animation="border"
            size="sm"
          />
        </>
      );
    }

    if (isEditing) {
      return 'Save';
    }

    switch (key) {
      case 'setup':
        return 'Edit';
      case 'streams':
        return 'Edit';
      case 'lineups':
        return 'Edit';
      case 'judges':
        return 'Edit';
      case 'routines':
        return 'Edit';
      case 'clips':
        return 'Add';
      case 'scores':
        return 'Add';
      default:
        return 'Create';
    }
  };

  const save = (ref) => {
    ref && ref.current && ref.current.submit();
  };

  const reset = () => {
    setIsEditing(false);
    setIsLoading(false);
  };

  const body = () => {
    return (
      <>
        {!alert.clear ? (
          <Row style={{ margin: '0 0 1rem 0' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
              style={{ textAlign: 'center', width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        {!isReady ? null : (
          <Row>
            <Tabs
              id="sessionDetailTabs"
              transition={false}
              activeKey={key}
              onSelect={(k) => changeTab(k)}
              disabled={isLoading}
            >
              <Tab eventKey="setup" title="Setup">
                <SessionSetup
                  ref={tabRef1}
                  sessionId={sessionId}
                  isEditing={isEditing}
                  isSaving={isLoading}
                  reset={reset}
                  setIsLoading={setIsLoading}
                  footerToggle={setFooterDisabled}
                />
              </Tab>
              <Tab eventKey="streams" title="Streams">
                <SessionStreams
                  ref={tabRef2}
                  sessionId={sessionId}
                  isEditing={isEditing}
                  isSaving={isLoading}
                  reset={reset}
                  setIsLoading={setIsLoading}
                />
              </Tab>
              <Tab eventKey="lineups" title="Lineups">
                <SessionLineups
                  ref={tabRef3}
                  sessionId={sessionId}
                  isEditing={isEditing}
                  isSaving={isLoading}
                  reset={reset}
                  setIsLoading={setIsLoading}
                />
              </Tab>
              <Tab eventKey="routines" title="Routines">
                <SessionRoutines
                  ref={tabRef4}
                  sessionId={sessionId}
                  isEditing={isEditing}
                  isSaving={isLoading}
                  reset={reset}
                  setIsLoading={setIsLoading}
                />
              </Tab>
              {/* <Tab eventKey="scores" title="Scores">
                <SessionScores
                  ref={tabRef5}
                  sessionId={sessionId}
                  isEditing={isEditing}
                  isSaving={isLoading}
                  reset={reset}
                  setIsLoading={setIsLoading}
                />
              </Tab>
              <Tab eventKey="clips" title="Clips">
                <SessionClips
                  ref={tabRef6}
                  sessionId={sessionId}
                  isEditing={isEditing}
                  isSaving={isLoading}
                  reset={reset}
                  setIsLoading={setIsLoading}
                />
              </Tab> */}
              <Tab eventKey="judges" title="Scorekeepers">
                <SessionJudges
                  ref={tabRef7}
                  sessionId={sessionId}
                  isEditing={isEditing}
                  isSaving={isLoading}
                  reset={reset}
                  setIsLoading={setIsLoading}
                />
              </Tab>
              <Tab eventKey="admin" title="Admin">
                <SessionAdminFunctions
                  ref={tabRef8}
                  sessionId={sessionId}
                  isEditing={isEditing}
                  isSaving={isLoading}
                  reset={reset}
                  setIsLoading={setIsLoading}
                />
              </Tab>
            </Tabs>
          </Row>
        )}
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <Row className="vCenter" style={{ margin: '0 auto' }}>
          <Button
            variant="outline-primary"
            className="formButton"
            onClick={handleClick}
            disabled={isLoading || footerDisabled}
          >
            {buttonText()}
          </Button>
        </Row>
        <Row>
          <div className="modalLink">
            <span>Back to</span>
            <button
              type="button"
              className="linkButton"
              disabled={isLoading || footerDisabled}
              onClick={back}
            >
              Session list
            </button>
          </div>
        </Row>
      </>
    );
  };

  const headerMsg = () => {
    return (
      <>
        <span>Session Admin:&nbsp;</span>
        <span style={{ fontWeight: '400' }}>
          <Link
            to={{
              pathname: '/session',
              search: `?s=${sessions.byId[sessionId]?.sessionKey}`,
              state: { fromAdmin: true },
            }}
          >
            {sessions.byId[sessionId]?.name}
          </Link>
        </span>
      </>
    );
  };

  return (
    <>
      <Modal.Header className="adminHeader">{headerMsg()}</Modal.Header>
      <Modal.Body className="adminForm">{body()}</Modal.Body>
      <Modal.Footer className="adminForm">{footer()}</Modal.Footer>
      <SessionSubscriptionManager sessionId={sessionId} />
    </>
  );
}

export default SessionDetail;
