import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Button, Row, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions, adminActions } from '../../redux/_actions';
import AthleteForm from './athleteform';
import AthleteDetail from './athletedetail';
import TableComponent from '../helpers/tablecomponent';
import { modeType } from '../../utilities/constants';
import { displayName } from '../../utilities/conversions';
import { Loading, ScrollLoading } from '../helpers/loading';
import { SearchInput, addBold, noResults } from '../helpers/search';

function AthleteAdmin(props) {
  const { setIsForm } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isScrollLoading, setIsScrollLoading] = useState(false);
  const { LIST, DETAIL, EDIT, DELETE, ADD } = modeType;
  const [mode, setMode] = useState(modeType.LIST);
  const [athleteId, setAthleteId] = useState(null);
  const alert = useSelector((state) => state.alert);
  const athletes = useSelector((state) => state.admin.athletes);
  const teams = useSelector((state) => state.admin.teams);
  const nextToken = useSelector(
    (state) => state.admin.athletes.tokens.nextToken
  );
  const searchQuery = useSelector(
    (state) => state.admin.athletes.tokens.searchQuery
  );
  const searchToken = useSelector(
    (state) => state.admin.athletes.tokens.searchToken
  );

  const [searchTerm, setSearchTerm] = useState(searchQuery || '');
  const [tempSearchTerm, setTempSearchTerm] = useState(searchQuery || ''); // used for async holding of query
  const dispatch = useDispatch();
  const numAthletes = athletes?.allIds?.length || 0;
  const isSearch = searchTerm !== '';
  let lastScrollTop = 0;

  useEffect(() => {
    if (!alert.clear) {
      setIsLoading(false);
    }
  }, [alert.clear]);

  useEffect(() => {
    dispatch(alertActions.clear());
  }, [dispatch]);

  useEffect(() => {
    if (numAthletes === 0) {
      setIsLoading(true);
      dispatch(adminActions.getAthletes());
    }
    setIsScrollLoading(false);
    setSearchTerm(tempSearchTerm);
  }, [athletes, numAthletes, dispatch]);

  // Check to see if any Team Data has been loaded
  useEffect(() => {
    if (teams.allIds.length === 0) {
      setIsLoading(true);
      dispatch(adminActions.getTeams());
    }
  }, [teams, dispatch]);

  useEffect(() => {
    if (teams.allIds.length !== 0 && numAthletes !== 0) {
      setIsLoading(false);
    }
  }, [teams, athletes]);

  const athleteDetail = (e, row) => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    setAthleteId(row.id);
    setMode(DETAIL);
    setIsForm(true);
  };

  const back = () => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    setMode(LIST);
    setIsForm(false);
  };

  const add = () => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    setMode(ADD);
    setIsForm(true);
  };

  const headers = useMemo(
    () => [
      { Header: '#', accessor: 'order' },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: (r) => addBold(r.cell.value, searchTerm),
      },
      { Header: 'Gender', accessor: 'gender' },
      { Header: 'Teams', accessor: 'teams' },
      { Header: 'User', accessor: 'user' },
      { Header: 'Type', accessor: 'type' },
      { Header: 'Img', accessor: 'img' },
    ],
    [searchTerm]
  );

  const data = useMemo(() => {
    return athletes.allIds.map((id, i) => {
      const {
        name,
        gender,
        teamAffiliations,
        userId,
        type,
        profileImg,
      } = athletes.byId[id];

      const teamsParsed = teamAffiliations
        ? JSON.parse(teamAffiliations)
        : null;

      return {
        order: i + 1,
        name: name,
        gender: gender,
        teams:
          teamsParsed && teams.byId.length !== 0 && Array.isArray(teamsParsed)
            ? teamsParsed
                .map((id) =>
                  displayName(teams?.byId[id]?.name, teams?.byId[id]?.altNames)
                )
                .sort((a, b) => {
                  if (a.type < b.type) {
                    return -1;
                  }
                  if (a.type > b.type) {
                    return 1;
                  }
                  return 0;
                })
                .join(', ')
            : 'N/A',
        user: userId ? 'Yes' : 'No',
        type: type ? type : 'N/A',
        img: profileImg ? 'Yes' : 'No',
        id: id,
      };
    });
  }, [athletes, teams.byId]);

  const table = () => {
    const initialState = {
      sortBy: [{ id: 'name', desc: false }],
    };

    const empty = numAthletes === 0 || data.length === 0;

    return empty ? (
      noResults('athletes')
    ) : (
      <TableComponent
        columns={headers}
        data={data}
        textSort={true}
        onClick={athleteDetail}
        initialState={initialState}
        searchTerm={searchTerm}
        showFooter={true}
        filterColumns={['name']}
      />
    );
  };

  const handleScroll = (e) => {
    const { scrollHeight, scrollTop, scrollLeft, clientHeight } = e.target;
    const bottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 1;
    const nextPage =
      (isSearch && searchToken !== null) ||
      (!isSearch && nextToken !== null && numAthletes > 0);
    const vertScroll = scrollLeft === 0;

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;

    if (bottom && nextPage && vertScroll && !isScrollLoading) {
      setIsScrollLoading(true);
      dispatch(
        adminActions.getAthletes({
          searchQuery: searchTerm,
          nextToken: isSearch ? searchToken : nextToken,
        })
      );
    }
  };

  const handleSearch = (query) => {
    setTempSearchTerm(query);

    if (query && query !== '') {
      dispatch(
        adminActions.getAthletes({ searchQuery: query, nextToken: null })
      );
    } else {
      // clear search
      setSearchTerm(query);
    }
  };

  const adminBody = () => {
    return (
      <>
        {!alert.clear ? (
          <Row style={{ padding: '0 15px', margin: '1rem -15px' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
              style={{ textAlign: 'center', width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        {isLoading ? <Loading /> : table()}
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <Row className="vCenter" style={{ margin: '0 auto' }}>
          <Button
            variant="outline-primary"
            className="formButton"
            onClick={add}
            disabled={isLoading}
          >
            Add athlete
          </Button>
        </Row>
        <Row />
      </>
    );
  };

  const headerMsg = () => {
    return <span>Athlete Admin</span>;
  };

  const listModal = () => {
    return (
      <>
        <Modal.Header className="searchHeader">
          {headerMsg()}
          {numAthletes !== 0 && data?.length !== 0 ? (
            <SearchInput searchTerm={searchTerm} search={handleSearch} />
          ) : null}
        </Modal.Header>
        <Modal.Body className="setupForm" onScroll={handleScroll}>
          {adminBody()}
          {isScrollLoading ? <ScrollLoading /> : null}
        </Modal.Body>
        <Modal.Footer className="setupFooter">{footer()}</Modal.Footer>
      </>
    );
  };

  const modal = () => {
    switch (mode) {
      case ADD:
        return <AthleteForm back={back} />;
      case EDIT:
        break;
      case DELETE:
        break;
      case LIST:
        return listModal();
      case DETAIL:
        return <AthleteDetail back={back} athleteId={athleteId} />;
      default:
        return null;
    }
  };

  return modal();
}

export default AthleteAdmin;
