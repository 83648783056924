import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions } from '../../redux/_actions';
import { StreamType } from '../../models';
import { streamStatus } from '../../redux/_constants';

function StreamCreate({ show, onHide, sessionId }) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('Scoring');
  const [customTitle, setCustomTitle] = useState('');
  const [link, setLink] = useState('');
  const [titleType, setTitleType] = useState('preset');
  const linkInputRef = useRef(null);

  // Clear link field and focus input when modal opens for a new session
  useEffect(() => {
    if (show) {
      setLink('');
      // Small delay to ensure modal is rendered
      setTimeout(() => {
        linkInputRef.current?.focus();
      }, 100);
    }
  }, [show, sessionId]);

  const session = useSelector(state => state.admin.sessions.byId[sessionId]) || {};
  const streams = session.streams?.items || [];
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const finalTitle = titleType === 'preset' ? title : customTitle;
    
    const nextIndex = streams.length;
    
    dispatch(sessionActions.createStreamNoCam(nextIndex, {
      sessionId,
      type: StreamType.PRODUCTION,
      status: streamStatus.STARTED,
      title: finalTitle,
      outputURL: link,
      vodURL: link,
    }));
    
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Stream</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <label>Title Type</label>
            <Form.Control
              as="select"
              value={titleType}
              onChange={(e) => setTitleType(e.target.value)}
            >
              <option value="preset">Preset</option>
              <option value="custom">Custom</option>
            </Form.Control>
          </Form.Group>

          {titleType === 'preset' ? (
            <Form.Group className="mb-3">
              <label>Title</label>
              <Form.Control
                as="select"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              >
                <option value="Scoring">Scoring</option>
                <option value="Main">Main</option>
              </Form.Control>
            </Form.Group>
          ) : (
            <Form.Group className="mb-3">
              <label>Custom Title</label>
              <Form.Control
                type="text"
                value={customTitle}
                onChange={(e) => setCustomTitle(e.target.value)}
                placeholder="Enter custom title"
              />
            </Form.Group>
          )}

          <Form.Group className="mb-3">
            <label>Stream Link</label>
            <Form.Control
              type="text"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              placeholder="Enter stream link"
              ref={linkInputRef}
            />
          </Form.Group>

          <div className="d-flex justify-content-end" style={{ gap: '0.5rem' }}>
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Add Stream
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default StreamCreate;
