// 1-6, short is abbreviated
export const mApparatusAbbv = ['FX', 'PH', 'SR', 'VT', 'PB', 'HB'];

export const wApparatusAbbv = ['VT', 'UB', 'BB', 'FX'];

export const soloDefault = {
  judging: true,
  judgingRequired: true,
  now: false,
  competition: true,
  lineup: false,
  name: '',
  judgeCount: 0,
  teamA: '',
  teamB: '',
  apparatus: '100000',
  startAt: new Date(),
  gender: 'MALE',
  teamScoring: 'ALL',
  alternating: false,
  access: 'PUBLIC',
  hostId: '',
  hostType: 'TEAM',
};

export const dualDefault = {
  judging: true,
  judgingRequired: true,
  now: false,
  competition: true,
  lineup: true,
  name: '',
  judgeCount: 3,
  teamA: '',
  teamB: '',
  apparatus: '111111',
  startAt: new Date(),
  gender: 'MALE',
  teamScoring: 'TOP5OF6',
  alternating: false,
  access: 'PUBLIC',
  hostId: '',
  hostType: 'TEAM',
};

export const dualNCAAMDefault = {
  judging: true,
  judgingRequired: true,
  now: false,
  competition: true,
  lineup: true,
  name: '',
  judgeCount: 4,
  teamA: '',
  teamB: '',
  apparatus: '111111',
  startAt: new Date(),
  gender: 'MALE',
  teamScoring: 'TOP4',
  alternating: false,
  access: 'PUBLIC',
  hostId: '',
  hostType: 'TEAM',
};

export const dualNCAAWDefault = {
  judging: true,
  judgingRequired: true,
  now: false,
  competition: true,
  lineup: true,
  name: '',
  judgeCount: 4,
  teamA: '',
  teamB: '',
  apparatus: '1111',
  startAt: new Date(),
  gender: 'FEMALE',
  teamScoring: 'TOP5OF6',
  alternating: true,
  access: 'PUBLIC',
  hostId: '',
  hostType: 'TEAM',
};

export const multiDefault = {
  judging: true,
  judgingRequired: true,
  now: false,
  competition: true,
  lineup: true,
  name: '',
  judgeCount: 6,
  teamA: '',
  teamB: '',
  teamC: '',
  apparatus: '111111',
  startAt: new Date(),
  gender: 'MALE',
  teamScoring: 'TOP5OF6',
  alternating: false,
  access: 'PUBLIC',
  hostId: '',
  hostType: 'TEAM',
};

export const multiNCAAWDefault = {
  judging: true,
  judgingRequired: true,
  now: false,
  competition: true,
  lineup: true,
  name: '',
  judgeCount: 6,
  teamA: '',
  teamB: '',
  teamC: '',
  apparatus: '111100',
  startAt: new Date(),
  gender: 'FEMALE',
  teamScoring: 'TOP5OF6',
  alternating: false,
  access: 'PUBLIC',
  hostId: '',
  hostType: 'TEAM',
};

export const multiNCAAMDefault = {
  judging: true,
  judgingRequired: true,
  now: false,
  competition: true,
  lineup: true,
  name: '',
  judgeCount: 6,
  teamA: '',
  teamB: '',
  teamC: '',
  apparatus: '111111',
  startAt: new Date(),
  gender: 'MALE',
  teamScoring: 'TOP4',
  alternating: false,
  access: 'PUBLIC',
  hostId: '',
  hostType: 'TEAM',
};

export const rosterDefault = {
  title: 'Latest Roster',
  activeDate: new Date(),
};

export const lineupDefault = {
  title: 'Latest Lineup',
  session: 'Select session',
};

export const POLLING_INTERVAL = {
  MINUTE: 60000,
  SLOW: 10000,
  MED: 5000,
  FAST: 3000,
  SECOND: 1000,
  DOUBLE: 500,
  TENTH: 100,
};

export const CAMERA_DEFAULT_SETTINGS = {
  //url:
  port: '10000',
  name: 'Virtius Test Event',
  mode: 'va', // Video & Audio
  latency: '1000', // Needs to be tuned
  maxbw: '1500000',
  //pass:
  //streamId:
  camera: '0', // Rear camera
  res: '1280x720',
  fps: '60',
  bitrate: '4500', // Minimum
  format: 'avc',
  keyframe: '2',
  audiobit: '128',
  audiochannels: '2', // Trying stereo for mobile device
  audiosamples: '48000',
};

export const STREAM_LOCATION_CONFIG = {
  US_WEST_CALIFORNIA: 'us_west_california',
  US_WEST_OREGON: 'us_west_oregon',
  US_EAST_VIRGINIA: 'us_east_virginia',
  US_EAST_S_CAROLINA: 'us_east_s_carolina',
  US_CENTRAL_IOWA: 'us_central_iowa',
  SOUTH_AMERICA_BRAZIL: 'south_america_brazil',
  EU_IRELAND: 'eu_ireland',
  EU_GERMANY: 'eu_germany',
  EU_BELGIUM: 'eu_belgium',
  ASIA_PACIFIC_TAIWAN: 'asia_pacific_taiwan',
  ASIA_PACIFIC_S_KOREA: 'asia_pacific_s_korea',
  ASIA_PACIFIC_SINGAPORE: 'asia_pacific_singapore',
  ASIA_PACIFIC_JAPAN: 'asia_pacific_japan',
  ASIA_PACIFIC_INDIA: 'asia_pacific_india',
  ASIA_PACIFIC_AUSTRALIA: 'asia_pacific_australia',
};

export const STREAM_DEFAULT_CONFIG = {
  live_stream: {
    name: 'Virtius Test Stream',
    aspect_ratio_height: 1080,
    aspect_ratio_width: 1920,
    billing_mode: 'pay_as_you_go',
    broadcast_location: STREAM_LOCATION_CONFIG.US_WEST_CALIFORNIA,
    encoder: 'other_srt',
    transcoder_type: 'transcoded',
    vod_stream: true,
    recording: true,
    low_latency: true,
    idle_timeout: 3600,
  },
};

export const CAMERA_720_60_SETTINGS = {
  res: '1280x720',
  bitrate: '4500',
  height: 720,
  width: 1280,
  fps: 60,
};

export const CAMERA_720_30_SETTINGS = {
  res: '1280x720',
  bitrate: '3000',
  height: 720,
  width: 1280,
  fps: 30,
};

export const CAMERA_1080_60_SETTINGS = {
  res: '1920x1080',
  bitrate: '6000',
  height: 1080,
  width: 1920,
  fps: 60,
};

export const CAMERA_1080_30_SETTINGS = {
  res: '1920x1080',
  bitrate: '4800',
  height: 1080,
  width: 1920,
  fps: 30,
};

export const CAMERA_1440_60_SETTINGS = {
  res: '2560x1440',
  bitrate: '10000',
  height: 1440,
  width: 2560,
  fps: 60,
};

export const CAMERA_1440_30_SETTINGS = {
  res: '2560x1440',
  bitrate: '7200',
  height: 1440,
  width: 2560,
  fps: 30,
};

export const CAMERA_4K_60_SETTINGS = {
  res: '3840x2160',
  bitrate: '24000',
  height: 2160,
  width: 3840,
  fps: 60,
};

export const CAMERA_4K_30_SETTINGS = {
  res: '3840x2160',
  bitrate: '16000',
  height: 2160,
  width: 3840,
  fps: 30,
};

export const MEDIAURL = 'https://media.virti.us/';
export const V_LOGO = 'v_logo.svg';

export const modeType = {
  ADD: 'ADD',
  LIST: 'LIST',
  DETAIL: 'DETAIL',
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
};

export const statusType = {
  CREATING: 'CREATING',
  SAVING: 'SAVING',
  EDITING: 'EDITING',
  LOADING: 'LOADING',
  STANDBY: 'STANDBY',
};

export const DELTA_SENSITIVITY = {
  NCAAM: 20,
  NCAAM4: 20,
  NCAAW: 80,
  NCAAW2: 80,
  NCAAW2SV: 80,
  NCAAW4: 80,
  NCAAW6: 80,
  FIG4: 20,
  FIG5: 20,
  FIG6: 20,
  SINGLE: 30,
  FAN: 30,
  J4: 30,
  OPEN: 30,
  DEFAULT: 20,
};

export const SIMILAR_COLOR_THRESHOLD = 25; // for use in picking team colors

export const TIMING_SETTINGS = {
  PLAYER_LEADIN: 3,
};

export const SCOREBOARD_DWELL = 30000; // Time to stay on score before moving to another phase
export const SCOREBOARD_TIMEOUT = 300000; // Reset scoreboard time out

export const MAX_CLIP_SIZE = 250000000;

export const clipType = [
  'video/mp4',
  'video/mpeg',
  'video/mp2t',
  'video/webm',
  'video/quicktime',
];

export const LAG_THRESHOLD = 15;

export const DISCORD_LINK = 'https://discord.gg/dd4gszDRPb';
