import React from 'react';
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
  Form,
} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { producerActions, sessionActions } from '../../redux/_actions';
import {
  sessionStatus,
  sessionRoleType,
  sessionViewType,
} from '../../redux/_constants';
import { dateTime, teamScoring } from '../../utilities/conversions';
import { DISCORD_LINK } from '../../utilities/constants';
import { useQuery } from '@apollo/client';
import { RoutineStatus } from '../../models';
import './triggerpanel.css';
import { discordIcon } from '../helpers/icons';
import { useHotkeys } from 'react-hotkeys-hook';
import { useHistory } from 'react-router-dom';
import { useQueryParams, useQueryParamsAdv } from '../../utilities/query';
import { useEvalConfig } from './hooks';

import GetLineupWithRoutines from '../../apollo/queries/GetLineupWithRoutines.graphql';

function TriggerPanel({
  row = false,
  isExperimental = false,
  judgeType,
  judgeLock = false,
}) {
  const lineupIdA = useSelector((state) => state.producer.teamA.lineupId);
  const lineupLengthA = useSelector(
    (state) =>
      state.producer.teamA.lineup.filter(
        (potentialRoutine) => !!potentialRoutine.athleteId
      ).length
  );
  const lineupIdB = useSelector((state) => state.producer.teamB?.lineupId);
  const lineupLengthB = useSelector(
    (state) =>
      state.producer.teamB?.lineup?.filter(
        (potentialRoutine) => !!potentialRoutine.athleteId
      ).length
  );
  const round = useSelector((state) => state.producer.round);
  const numTeams = useSelector((state) => state.producer.teams.length);
  const streams = useSelector((state) => state.session.streams?.items);

  const { producer, session } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const basicParams = useQueryParams();
  const { queryParams, setQueryParams } = useQueryParamsAdv(
    {
      s: '',
      beta: '',
      judge: '',
      apparatus: '',
      evalslab: '',
    },
    true
  ); // using replace instead of push
  const streamType = basicParams.get('stream');
  const sessionKey = session.sessionKey;
  const { CREATED, PREGAME, LIVE, POSTGAME } = sessionStatus;
  const { ADMIN, JUDGE, PRODUCER, FAN, COACH } = sessionRoleType;
  const { roles } = useEvalConfig();
  const roleCheck = roles?.[judgeType?.toUpperCase()] || false;

  useHotkeys(
    'v',
    (e) => {
      e.preventDefault();
      handleView();
    },
    [session.view]
  );

  const { data: lineupDataA } = useQuery(GetLineupWithRoutines, {
    variables: { id: lineupIdA },
    skip: !lineupIdA,
  });

  const { data: lineupDataB } = useQuery(GetLineupWithRoutines, {
    variables: { id: lineupIdB },
    skip: !lineupIdB,
  });

  let completedRoutinesA = [];
  let completedRoutinesB = [];
  let roundComplete = false;
  if (lineupDataA && lineupDataB) {
    completedRoutinesA = lineupDataA.getLineup.routines.items.filter(
      (routine) =>
        routine.rotation === round && routine.status === RoutineStatus.COMPLETE
    );
    completedRoutinesB = lineupDataB.getLineup.routines.items.filter(
      (routine) =>
        routine.rotation === round && routine.status === RoutineStatus.COMPLETE
    );
    if (
      completedRoutinesA.length === lineupLengthA &&
      completedRoutinesB.length === lineupLengthB
    ) {
      roundComplete = round;
    }
  }

  const handleInfo = () => {
    return;
  };

  const handleClip = () => {
    dispatch(producerActions.clip(!producer.clip));
  };

  const handleLive = () => {
    if (producer.edit) {
      dispatch(producerActions.edit(!producer.edit));
    }
    dispatch(producerActions.live(!producer.live, roundComplete));
  };

  const handleEdit = () => {
    if (producer.live) {
      dispatch(producerActions.live(!producer.live, false));
    }
    dispatch(producerActions.edit(!producer.edit));
  };

  const handleUpload = () => {
    dispatch(producerActions.upload(!producer.upload));
  };

  const handleView = () => {
    const {
      DEFAULT,
      SOLOPLAYER,
      DUALPLAYER,
      DUALLINEUP,
      SOLOLINEUP,
      PLAYERDUAL,
      PLAYERMULTI,
      MULTIONLY,
    } = sessionViewType;
    let targetView = DEFAULT;

    switch (session.view) {
      case DEFAULT:
        if (session.role === JUDGE) {
          targetView = DUALLINEUP;
        } else {
          targetView = SOLOLINEUP;
        }
        break;
      case SOLOLINEUP:
        if (session.role === JUDGE) {
          targetView = DUALLINEUP;
        } else {
          targetView = SOLOPLAYER;
        }
        break;
      case DUALLINEUP:
        if (session.role === JUDGE) {
          targetView = SOLOLINEUP;
        } else {
          targetView = SOLOPLAYER;
        }
        break;
      case SOLOPLAYER:
        targetView = DUALPLAYER;
        break;
      case DUALPLAYER:
        targetView = PLAYERDUAL;
        break;
      case PLAYERDUAL:
        if (isExperimental) {
          targetView = MULTIONLY;
        } else if (session.role === JUDGE) {
          targetView = SOLOLINEUP;
        } else {
          targetView = DUALLINEUP;
        }
        break;
      case PLAYERMULTI:
        targetView = MULTIONLY;
        break;
      case MULTIONLY:
        targetView = PLAYERMULTI;
        break;
      default:
        targetView = DEFAULT;
        break;
    }
    dispatch(sessionActions.changeView(targetView));
  };

  // toggle undo mode to force active routine back one state
  const handleUndo = () => {
    dispatch(producerActions.undo());
  };

  const handleLeaderboard = () => {
    dispatch(producerActions.leaderboard(true));
  };

  const handleDiscord = () => {
    window.open(DISCORD_LINK, '_blank');
  };

  const handleStreams = (e, option) => {
    if (option !== null) {
      history.push(`/session?s=${sessionKey}&stream=${option}`);
    }
  };

  const undoButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      key="undoButton"
      overlay={<Tooltip id="popover-contained">Undo On Air / On Eval</Tooltip>}
    >
      <Button
        variant={producer.undo ? 'outline-primary' : 'outline-secondary'}
        onClick={handleUndo}
        disabled={!producer.live}
      >
        UNDO
      </Button>
    </OverlayTrigger>
  );

  const infoButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      arrowProps={false}
      trigger="click"
      key="infoButton"
      rootClose
      overlay={
        <Tooltip id="popover-contained" className="sessionInfo">
          <p className="title">{session.name}</p>
          <p className="date">{dateTime(session.startAt, true)}</p>
          <p className="teamscoring">{`Team: ${teamScoring(
            session.teamScoring
          )}`}</p>
        </Tooltip>
      }
    >
      <Button
        className="infoButton"
        variant="outline-secondary"
        onClick={handleInfo}
        disabled={false}
      >
        INFO
      </Button>
      {/*</span> */}
    </OverlayTrigger>
  );

  const editButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      overlay={<Tooltip id="popover-contained">Modify Lineups</Tooltip>}
      key="editButton"
    >
      <Button
        variant={producer.edit ? 'outline-primary' : 'outline-secondary'}
        onClick={handleEdit}
        //disabled={producer.live} // will tie later in with producer mode
      >
        EDIT
      </Button>
    </OverlayTrigger>
  );

  const viewButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      key="viewButton"
      overlay={<Tooltip id="popover-contained">Change View</Tooltip>}
    >
      <Button variant="outline-secondary" onClick={handleView} disabled={false}>
        VIEW
      </Button>
    </OverlayTrigger>
  );

  const streamButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      key="streamButton"
      rootClose
      overlay={<Tooltip id="popover-contained">Streams</Tooltip>}
    >
      <ButtonGroup>
        {streams.map((s, i) => {
          return (
            <Button
              key={i}
              className={
                streamType === '' + i || (streamType === null && i === 0)
                  ? 'selectedStream'
                  : null
              }
              variant="outline-secondary"
              onClick={(e) => handleStreams(e, i)}
              disabled={
                streamType === '' + i || (streamType === null && i === 0)
              }
            >
              {s.title}
            </Button>
          );
        })}
      </ButtonGroup>
    </OverlayTrigger>
  );

  const clipButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      key="clipButton"
      overlay={
        <Tooltip id="popover-contained">Mark Routine Start/Finish</Tooltip>
      }
    >
      <Button
        variant={producer.clip ? 'outline-success' : 'outline-secondary'}
        onClick={handleClip}
      >
        CLIP
      </Button>
    </OverlayTrigger>
  );

  const uploadButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      key="uploadButton"
      overlay={<Tooltip id="popover-contained">Upload Videos</Tooltip>}
    >
      <Button
        variant={producer.upload ? 'outline-success' : 'outline-secondary'}
        onClick={handleUpload}
      >
        UPLD
      </Button>
    </OverlayTrigger>
  );

  const liveButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      key="liveButton"
      overlay={
        <Tooltip id="popover-contained">
          {!producer.live ? 'Start Round' : 'End Round'}
        </Tooltip>
      }
    >
      <Button
        variant={producer.live ? 'outline-danger' : 'outline-secondary'}
        onClick={handleLive}
        disabled={session.status !== LIVE} // || producer.edit}
      >
        {session.status === LIVE
          ? producer.live
            ? 'STOP'
            : 'START'
          : numTeams === 3
          ? 'TRI'
          : numTeams === 4
          ? 'QUAD'
          : session.type}
      </Button>
    </OverlayTrigger>
  );

  const leaderboardButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      overlay={<Tooltip id="popover-contained">Show Leaderboard</Tooltip>}
      key="leaderboardButton"
      rootClose
    >
      <Button variant="outline-secondary" onClick={handleLeaderboard}>
        STATS
      </Button>
    </OverlayTrigger>
  );

  // const eventScoreButton = (
  //   <OverlayTrigger
  //     placement="top"
  //     delay={{ show: 700, hide: 0 }}
  //     overlay={<Tooltip id="popover-contained">Show Leaderboard</Tooltip>}
  //     key="leaderboardButton"
  //     rootClose
  //   >
  //     <Button variant="outline-secondary" onClick={handleLeaderboard}>
  //       {VS}
  //     </Button>
  //   </OverlayTrigger>
  // );

  const discordButton = (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      overlay={<Tooltip id="popover-contained">Join Discord</Tooltip>}
      key="discordButton"
    >
      <Button
        className="discordButton"
        variant="outline-secondary"
        onClick={handleDiscord}
      >
        {discordIcon} CHAT
      </Button>
    </OverlayTrigger>
  );

  const adminPanelRow = [
    infoButton,
    session.status === POSTGAME ? null : liveButton,
    leaderboardButton,
    viewButton,
    streamButton,
    editButton,
    session.status === LIVE ? undoButton : null,
    session.status === POSTGAME ? clipButton : null,
    [LIVE, POSTGAME].includes(session.status) ? uploadButton : null,
  ];

  const userPanelRow = [
    infoButton,
    // viewButton,
    streamButton,
    leaderboardButton,
    discordButton,
  ];

  const judgePanelRow = [
    judgeLock ? null : infoButton,
    session.status === LIVE && (!judgeType || !judgeType.match(/^J[2-9]$/))
      ? liveButton
      : null,
    //session.status === LIVE ? null : viewButton,
    //viewButton,
    !roleCheck ? leaderboardButton : null,
    [LIVE, PREGAME].includes(session.status) && !roleCheck ? editButton : null,
    session.status === LIVE && (!judgeType || !judgeType.match(/^J[2-9]$/))
      ? undoButton
      : null,
  ];

  const panelSwitcherRow = () => {
    switch (session.role) {
      case ADMIN:
        return adminPanelRow;
      case JUDGE:
        return judgePanelRow;
      case PRODUCER:
      case COACH:
      default:
        return userPanelRow;
    }
  };

  const userType = () => {
    //return "FAN";
    switch (session.role) {
      case ADMIN:
        return ADMIN;
      case PRODUCER:
        return PRODUCER;
      case COACH:
        return COACH;
      case JUDGE:
        if (roleCheck) {
          return `${JUDGE}: ${judgeType.toUpperCase()}`;
        } else if (isExperimental) {
          return judgeTypeSelector();
        } else {
          return JUDGE;
        }
      default:
        return FAN;
    }
  };

  const sessionStatusLabel = () => {
    switch (session.status) {
      case CREATED:
      case PREGAME:
        return 'PRE';
      case LIVE:
        return 'LIVE';
      case POSTGAME:
        return 'POST';
      default:
        return 'POST';
    }
  };

  const sessionStatusButton = () => {
    let options = {
      CREATED: 'CREATED',
      PREGAME: 'PRE',
      LIVE: 'LIVE',
      POSTGAME: 'POST',
    };

    return (
      <Form.Control
        as="select"
        label="Status"
        id="admin-session-status-menu"
        value={session.status}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={(e) => {
          const payload = {
            ...session,
            status: e.target.value,
          };
          dispatch(sessionActions.updateStatus(payload));
        }}
      >
        {Object.keys(options).map((statusType) => {
          return (
            <option key={statusType} value={statusType}>
              {options[statusType]}
            </option>
          );
        })}
      </Form.Control>
    );
  };

  const judgeTypeSelector = () => {
    let options = roles || {};

    if (!roles) {
      return null;
    }

    return (
      <Form.Control
        as="select"
        label="JudgeType"
        id="admin-session-judgetype-menu"
        onClick={(e) => {
          e.stopPropagation();
        }}
        defaultValue="Judge"
        onChange={(e) => {
          setQueryParams({
            ...queryParams,
            judge: e.target.value,
          });
        }}
      >
        <option value="Judge">JUDGE</option>
        {Object.keys(options).map((judgeType) => {
          return (
            <option key={judgeType} value={judgeType}>
              {judgeType}
            </option>
          );
        })}
      </Form.Control>
    );
  };

  const triggerPanelRow = (
    <div className="triggerPanelRow">
      <Row className="tPanelRowStyle">
        <Col className="tPanelStart vCenter" xs={1}>
          <span
            className={[
              'vCenter',
              producer.live ? 'blinkingLiveText' : null,
            ].join(' ')}
          >
            {session.role === ADMIN
              ? sessionStatusButton()
              : sessionStatusLabel()}
          </span>
        </Col>
        <Col xs={10}>
          <div className="tPanelRowButtons">{panelSwitcherRow()}</div>
        </Col>
        <Col className="tPanelEnd vCenter" xs={1}>
          <span className="vCenter">{userType()}</span>
        </Col>
      </Row>
    </div>
  );

  return triggerPanelRow;
}

export default TriggerPanel;
