import { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import './athletetypeahead.css';
import { useQuery } from '@apollo/client';
import { SearchFullAthletes } from '../../apollo/queries/SearchFullAthletes.graphql';
import { GetTeam } from '../../apollo/queries/GetTeam.graphql';

function calculateNameConfidence(input, filename) {
  // Normalize names for comparison
  const normalize = (str) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[_-]/g, ' ') // Replace underscores and hyphens with spaces
      .replace(/\.[^/.]+$/, ''); // Remove file extension

  const inputName = normalize(input);
  const fileName = normalize(filename);

  // Split names into parts
  const inputParts = inputName.split(' ');
  const fileParts = fileName.split(' ');

  // Compare each part individually
  let totalScore = 0;
  let matchedParts = 0;

  for (const inputPart of inputParts) {
    let bestPartScore = 0;

    for (const filePart of fileParts) {
      // Calculate Levenshtein distance for this part
      const distance = levenshteinDistance(inputPart, filePart);
      const maxLength = Math.max(inputPart.length, filePart.length);
      const similarity = 1 - distance / maxLength;

      // If parts are very similar (allowing for minor misspellings)
      if (similarity > 0.8) {
        bestPartScore = Math.max(bestPartScore, similarity);
      }
      // If one part contains the other (nickname handling)
      else if (inputPart.includes(filePart) || filePart.includes(inputPart)) {
        bestPartScore = Math.max(bestPartScore, 0.9);
      }
    }

    if (bestPartScore > 0) {
      totalScore += bestPartScore;
      matchedParts++;
    }
  }

  // Calculate final confidence score
  const finalScore = matchedParts > 0 ? totalScore / inputParts.length : 0;

  // Boost score for files that contain all name parts
  if (matchedParts === inputParts.length) {
    return Math.min(1, finalScore + 0.1);
  }

  return finalScore;
}

function levenshteinDistance(str1, str2) {
  const m = str1.length;
  const n = str2.length;
  const dp = Array(m + 1)
    .fill()
    .map(() => Array(n + 1).fill(0));

  for (let i = 0; i <= m; i++) dp[i][0] = i;
  for (let j = 0; j <= n; j++) dp[0][j] = j;

  for (let i = 1; i <= m; i++) {
    for (let j = 1; j <= n; j++) {
      if (str1[i - 1] === str2[j - 1]) {
        dp[i][j] = dp[i - 1][j - 1];
      } else {
        dp[i][j] =
          1 +
          Math.min(
            dp[i - 1][j], // deletion
            dp[i][j - 1], // insertion
            dp[i - 1][j - 1] // substitution
          );
      }
    }
  }
  return dp[m][n];
}

function TeamName({ teamId }) {
  const { data } = useQuery(GetTeam, {
    variables: {
      id: teamId,
    },
  });

  return <span className="mr-1">{data?.getTeam.name}</span>;
}

function AthleteBatchTypeahead({
  gender,
  onSelected,
  prompt,
  disabled = false,
  clearOnSelect = true,
  currentRoster = [],
}) {
  const handleAddToRoster = async () => {
    if (namesList.some((item) => item.added)) {
      // If items are already added, this acts as Close button
      setNamesList([]);
      // Clear the input field
      const inputElement = document.querySelector(
        '.athlete-batch-typeahead input[type="text"]'
      );
      if (inputElement) {
        inputElement.value = '';
      }
      return;
    }

    // Process each non-skipped name with a selected match or high confidence match
    const promises = namesList
      .filter(
        (item) =>
          !item.skipped &&
          (item.selectedMatch || (item.matches[0] && item.confidence >= 0.8))
      )
      .map((item) => onSelected(item.selectedMatch || item.matches[0]));

    await Promise.all(promises);

    // Mark processed items as added
    setNamesList((prev) =>
      prev.map((item) => ({
        ...item,
        added: !item.skipped && item.selectedMatch ? true : false,
      }))
    );
  };
  const [namesList, setNamesList] = useState([]); // [{name, matches: [], selectedMatch: null, history: [], skipped: false, added: false}]
  const client = useApolloClient();

  const searchForName = async (name) => {
    const { data } = await client.query({
      query: SearchFullAthletes,
      variables: {
        filter: {
          name: { matchPhrasePrefix: name.trim() },
          gender: { eq: gender },
        },
        limit: 3,
        sort: {
          direction: 'asc',
          field: 'name',
        },
      },
    });
    return data?.searchAthletes?.items || [];
  };

  const [singleSearchResults, setSingleSearchResults] = useState([]);
  const [showSingleResults, setShowSingleResults] = useState(false);

  const handleInputChange = async (e) => {
    const value = e.target.value;

    if (value.includes(',')) {
      setShowSingleResults(false);
      const names = value
        .split(',')
        .map((name) => name.trim())
        .filter((name) => name.length > 0);

      const namesWithMatches = await Promise.all(
        names.map(async (name) => {
          const matches = await searchForName(name);
          const bestMatch = matches[0] || null;
          const isAlreadyInRoster =
            bestMatch &&
            currentRoster.some(
              (athlete) => athlete.athlete?.id === bestMatch.id
            );

          // Calculate match confidence based on name similarity
          const confidence = bestMatch
            ? calculateNameConfidence(name, bestMatch.name)
            : 0;

          return {
            name,
            matches,
            selectedMatch: confidence >= 0.8 ? bestMatch : null, // Auto-select if confidence is high
            history: [name],
            skipped: isAlreadyInRoster || (bestMatch && confidence < 0.5), // Auto-skip if confidence is low
            alreadyInRoster: isAlreadyInRoster,
            confidence,
          };
        })
      );

      setNamesList(namesWithMatches);
    } else if (value.trim()) {
      setShowSingleResults(true);
      setNamesList([]);
      const results = await searchForName(value);
      setSingleSearchResults(results);
    } else {
      setShowSingleResults(false);
      setSingleSearchResults([]);
      setNamesList([]);
    }
  };

  const handleMatchSelection = (nameIndex, match) => {
    setNamesList((prev) =>
      prev.map((item, idx) =>
        idx === nameIndex ? { ...item, selectedMatch: match } : item
      )
    );
  };

  return (
    <div className="athlete-batch-typeahead">
      <div className="input-container">
        <input
          type="text"
          className="form-control"
          placeholder="Enter or paste comma-separated athlete name(s)..."
          onChange={(e) => {
            handleInputChange(e);
            if (e.target.value === '') {
              setNamesList([]);
            }
          }}
          disabled={disabled}
        />
      </div>
      {showSingleResults && singleSearchResults.length > 0 && (
        <div className="names-list mt-3">
          <ul className="list-unstyled">
            {singleSearchResults.map((athlete) => (
              <li
                key={athlete.id}
                className="single-result-item"
                onClick={() => {
                  onSelected(athlete);
                  setSingleSearchResults([]);
                  setShowSingleResults(false);
                  const inputElement = document.querySelector(
                    '.athlete-batch-typeahead input[type="text"]'
                  );
                  if (inputElement) {
                    inputElement.value = '';
                  }
                }}
              >
                <div className="d-flex align-items-center">
                  <span className="athlete-name">{athlete.name}</span>
                  <div className="team-names ml-3">
                    {JSON.parse(athlete.teamAffiliations || '[]').map(
                      (teamId) => (
                        <TeamName key={teamId} teamId={teamId} />
                      )
                    )}
                    {JSON.parse(athlete.teamAffiliations || '[]').length ===
                      0 && <span>No teams</span>}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {namesList.length > 0 && (
        <div className="names-list mt-3">
          <div className="header-container mb-3">
            <h6 className="mb-0">Names to Search ({namesList.length}):</h6>
            <div className="buttons-container">
              <button
                className="btn btn-primary btn-sm"
                onClick={handleAddToRoster}
                disabled={
                  !namesList.some(
                    (item) => !item.skipped && item.selectedMatch
                  ) && !namesList.some((item) => item.added)
                }
              >
                {namesList.some((item) => item.added)
                  ? 'Close'
                  : `Add to Roster (${
                      namesList.filter(
                        (item) => !item.skipped && item.selectedMatch
                      ).length
                    })`}
              </button>
              <button
                className="btn btn-secondary btn-sm"
                onClick={() => {
                  setNamesList([]);
                  const inputElement = document.querySelector(
                    '.athlete-batch-typeahead input[type="text"]'
                  );
                  if (inputElement) {
                    inputElement.value = '';
                  }
                }}
              >
                Cancel
              </button>
            </div>
          </div>
          <ul className="list-unstyled">
            {namesList.map((item, nameIndex) => (
              <li key={nameIndex} className="mb-3">
                <div
                  className={`d-flex align-items-center ${
                    item.skipped ? 'skipped' : ''
                  }`}
                >
                  <span className="athlete-number">{nameIndex + 1}.</span>
                  <input
                    type="text"
                    className="form-control name-input mr-3"
                    value={item.name || ''}
                    onChange={async (e) => {
                      const newName = e.target.value;
                      const matches = await searchForName(newName);
                      setNamesList((prev) =>
                        prev.map((nameItem, idx) =>
                          idx === nameIndex
                            ? {
                                ...nameItem,
                                name: newName,
                                matches,
                                selectedMatch: matches[0] || null,
                                history: [...nameItem.history, newName],
                              }
                            : nameItem
                        )
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'z' && (e.ctrlKey || e.metaKey)) {
                        e.preventDefault();
                        setNamesList((prev) =>
                          prev.map((nameItem, idx) => {
                            if (
                              idx === nameIndex &&
                              nameItem.history.length > 1
                            ) {
                              const newHistory = [...nameItem.history];
                              newHistory.pop(); // Remove current state
                              const previousName =
                                newHistory[newHistory.length - 1];
                              return {
                                ...nameItem,
                                name: previousName,
                                history: newHistory,
                              };
                            }
                            return nameItem;
                          })
                        );
                      }
                    }}
                  />
                  <div className="matches-container d-flex gap-2">
                    {item.matches.map((match, matchIndex) => (
                      <div
                        key={match.id}
                        className={`match-option mr-3 ${
                          item.selectedMatch?.id === match.id ? 'selected' : ''
                        }`}
                        onClick={() => handleMatchSelection(nameIndex, match)}
                      >
                        <div className="match-content">
                          <input
                            type="radio"
                            checked={item.selectedMatch?.id === match.id}
                            onChange={() =>
                              handleMatchSelection(nameIndex, match)
                            }
                          />
                          <span>{match.name}</span>
                        </div>
                        <div className="team-names">
                          {JSON.parse(match.teamAffiliations || '[]').map(
                            (teamId) => (
                              <TeamName key={teamId} teamId={teamId} />
                            )
                          )}
                          {JSON.parse(match.teamAffiliations || '[]').length ===
                            0 && <span>No teams</span>}
                        </div>
                      </div>
                    ))}
                    {item.matches.length === 0 && (
                      <div className="d-flex align-items-center gap-2">
                        <span className="text-muted">No matches found</span>
                      </div>
                    )}
                  </div>
                  <div className="skip-checkbox">
                    {item.added ? (
                      <div className="added-indicator">
                        <input type="checkbox" checked={true} readOnly />
                        <span>Added</span>
                      </div>
                    ) : (
                      <>
                        <input
                          type="checkbox"
                          checked={item.skipped}
                          onChange={() => {
                            if (!item.alreadyInRoster) {
                              setNamesList((prev) =>
                                prev.map((nameItem, idx) =>
                                  idx === nameIndex
                                    ? {
                                        ...nameItem,
                                        skipped: !nameItem.skipped,
                                      }
                                    : nameItem
                                )
                              );
                            }
                          }}
                        />
                        <span>
                          {item.alreadyInRoster ? 'Duplicate' : 'Skip'}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default AthleteBatchTypeahead;
