import {
  producerConstants,
  exportDataHeaders,
  defaultEvaluatorConfig,
  emptyTeam,
  emptyLineup,
  sessionStatus,
} from '../_constants';
import { SessionStatus } from '../../models';
import { store, persistor } from '../store';
import { displayName, teamBrand } from '../../utilities/conversions';
import { colorSelector, dualTeamBRotation } from '../../utilities/session';
import { alertActions } from '../_actions';

export const producerActions = {
  initialize,
  reset,
  setEnd,
  exportData,
  live,
  changeRound,
  edit,
  updateLineup,
  judge,
  clip,
  upload,
  showScores,
  sortLineup,
  syncProducer,
  force,
  forceSelect,
  resetLineup,
  undo,
  leaderboard,
  teamBannerChange,
};

function reset(data) {
  const cleanData = {};
  Object.assign(cleanData, data);

  // Reset all scores
  cleanData.teamA.roster = data.teamA.roster.map((a) => {
    return {
      name: a.name,
      score: null,
      startTime: a.startTime,
      endTime: a.endTime,
      evaluation: null,
      id: a.id,
    };
  });
  cleanData.teamB.roster = data.teamB.roster.map((a) => {
    return {
      name: a.name,
      score: null,
      startTime: a.startTime,
      endTime: a.endTime,
      evaluation: null,
      id: a.id,
    };
  });

  // Configure export header
  const initHeaders = initExportHeader(data.evaluatorConfig);
  cleanData.exportHeader = initHeaders;

  return { type: producerConstants.RESET, data: cleanData };
}

function judge(judge) {
  return { type: producerConstants.JUDGE, judge };
}

function clip(clip) {
  return { type: producerConstants.CLIP, clip };
}

function upload(upload) {
  return { type: producerConstants.UPLOAD, upload };
}

function edit(edit) {
  return { type: producerConstants.EDIT, edit };
}

function leaderboard(leaderboard) {
  return { type: producerConstants.LEADERBOARD, leaderboard };
}

function live(live, roundComplete) {
  const { completedRounds } = store.getState().producer;
  const newCompletedRounds = [...completedRounds];

  if (!live && roundComplete && !completedRounds.includes(roundComplete)) {
    newCompletedRounds.push(roundComplete);
  }

  return (dispatch) => {
    dispatch(success());

    // What if we didn't sync session producer here?  // This might be causing more issues for in person competitions (AY 3/3/22)
    /*
    dispatch(
      sessionActions.updateProducer({
        live,
        completedRounds: newCompletedRounds,
      })
    );
    */
  };

  function success(data) {
    return {
      type: producerConstants.LIVE,
      live,
      completedRounds: newCompletedRounds,
    };
  }
}

// Data can be preloaded for client demo
function initialize(data = null, teamData = null, syncData = null) {
  // teamData format comes from session load/action
  const { session } = store.getState();
  const normalizedData = session.sessionData;
  const isAlternating = session.alternating;
  const { LIVE, PREGAME } = SessionStatus;
  const shouldAlternate =
    [LIVE, PREGAME].includes(session.status) && isAlternating;
  const startingRound =
    syncData && syncData.round && session.status === sessionStatus.LIVE
      ? syncData.round
      : session.apparatus.indexOf('1') + 1;
  const persistedRound = store.getState().producer.round;

  let initData = null;

  const loadTeam = (team, order, alternating) => {
    // Load roster by latest active date
    const rosterIndex = team?.rosters?.items.findIndex(
      (e) => session.startAt >= normalizedData.rosters.byId[e.id].activeDate
    );

    const roster =
      (rosterIndex > -1 &&
        normalizedData.rosters.byId[
          team?.rosters?.items[rosterIndex].id
        ].athletes.items
          .map(
            (el) =>
              normalizedData.athletes.byId[
                normalizedData.rosterLinks.byId[el].athleteId
              ]
          )
          .sort((a, b) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) {
              return -1;
            }
            if (a.name.toUpperCase() > b.name.toUpperCase()) {
              return 1;
            }
            return 0;
          })) ||
      [];

    const lineupId =
      session.lineups.items.find(
        (el) =>
          normalizedData.sessionTeams.byId[el.sessionTeamId]?.order === order
      )?.id ?? null;

    const lineup =
      (lineupId &&
        normalizedData.lineups.byId[lineupId].lineupData &&
        JSON.parse(normalizedData.lineups.byId[lineupId].lineupData)?.[
          alternating ? dualTeamBRotation(startingRound) : startingRound
        ]) ??
      JSON.parse(JSON.stringify(emptyLineup)); // this lineup is just single round lineup

    const color = (team && JSON.parse(team?.colors)[0]) || emptyTeam.color;
    const logo =
      (team && JSON.parse(team?.logos)?.metaData.filename) || emptyTeam.logo;
    const lineupName =
      (lineupId && normalizedData.lineups.byId[lineupId]?.title) || null;
    const name = displayName(team.name, team.altNames);
    const names = team.altNames;
    const colors = JSON.parse(team.colors);

    return {
      ...Object.assign({}, JSON.parse(JSON.stringify(emptyTeam))),
      index: order,
      roster,
      lineupId,
      lineup,
      color,
      colors,
      logo,
      id: team.id,
      name: session.name !== lineupName ? lineupName : name,
      names,
      lineupName,
    };
  };

  // Special case for alternating duals
  const alternatingTeam =
    shouldAlternate && isAlternating && teamData.length === 2;

  const teams = teamData.map((team, i) =>
    loadTeam(team, i, alternatingTeam && i === 1)
  );

  if (data !== null) {
    initData = {
      ...data,
      exportHeader: initExportHeader(defaultEvaluatorConfig),
    };
  } else {
    initData = {
      teamA: {
        ...teams[0],
        home: true,
        name: displayName(
          teams[0].name,
          teams[0].names,
          teams[0].id === teams?.[1]?.id ? 0 : -1
        ),
      },
      teamB: teams?.[1] && {
        ...teams?.[1],
        name:
          teams?.[1] &&
          displayName(
            teams[1].name,
            teams[1].names,
            teams[1].id === teams[0].id ? 1 : -1
          ),
        color: teams?.[1] && colorSelector(teams[0].color, teams[1].colors),
      },
      round: persistedRound ?? startingRound, // need to add to schema
      loaded: true,
      edit: false,
      clip: false,
      judge: false,
      perTeam: 5,
      exportData: null,
      live: false,
      scores: true,
      teams,
      exportHeader: initExportHeader(defaultEvaluatorConfig),
    };
  }

  // Update and Tack on for live producer state
  if (syncData && session.status === sessionStatus.LIVE) {
    initData = {
      ...initData,
      ...syncData,
      teamA: {
        ...initData.teamA,
        ...syncData.teamA,
      },
      teamB: teams?.[1] && {
        ...initData.teamB,
        ...syncData.teamB,
      },
    };
  }

  return (dispatch) => {
    dispatch(success(initData));
  };

  function success(data) {
    return { type: producerConstants.INITIALIZE, data };
  }
}

// Internal function to configure export headers w/ evaluator config
function initExportHeader(evalConfig) {
  const dHeaders = evalConfig.dPanel.map(
    (item, i) => `D${evalConfig.dPanel.length > 1 ? i + 1 : ''}`
  );
  const eHeaders = evalConfig.ePanel.map(
    (item, i) => `E${evalConfig.ePanel.length > 1 ? i + 1 : ''}`
  );
  const initHeaders = [...exportDataHeaders, ...dHeaders, ...eHeaders];
  return initHeaders;
}

function setEnd(home, routineId) {
  /*
  const time = videoPlayer[`player${ home ? 'A' : 'B'}`]?.ref?._player.currentTime;
  const lineup = [...team.lineup];
  const lineupEntry = {...lineup[index]};
  lineupEntry.endTime = time;
  lineup[index] = lineupEntry;
  */
  const data = {
    team: home ? 'teamA' : 'teamB',
    //lineup: lineup
  };

  return { type: producerConstants.SET_END, data };
}

function exportData(data) {
  return { type: producerConstants.EXPORT_DATA, data };
}

function changeRound(newRound, complete = false) {
  const { teamA, teamB } = store.getState().producer;
  const { sessionData, status, alternating } = store.getState().session;

  const shouldAlternate =
    alternating &&
    teamA.lineupId !== teamB.lineupId &&
    [sessionStatus.PREGAME, sessionStatus.LIVE].includes(status);

  const data = {
    teamALineup: null,
    teamBLineup: null,
    round: newRound,
  };

  // Change to new lineup for both teams
  if (teamA) {
    data.teamALineup =
      (teamA.lineupId &&
        sessionData.lineups.byId[teamA.lineupId].lineupData &&
        JSON.parse(sessionData.lineups.byId[teamA.lineupId].lineupData)?.[
          newRound
        ]) ??
      JSON.parse(JSON.stringify(emptyLineup));
  }

  if (teamB) {
    data.teamBLineup =
      (teamB.lineupId &&
        sessionData.lineups.byId[teamB.lineupId].lineupData &&
        JSON.parse(sessionData.lineups.byId[teamB.lineupId].lineupData)?.[
          shouldAlternate ? dualTeamBRotation(newRound) : newRound
        ]) ??
      JSON.parse(JSON.stringify(emptyLineup));
  }

  return (dispatch) => {
    dispatch(success(data));
  };

  function success(data) {
    return { type: producerConstants.CHANGE_ROUND, data: data };
  }
}

function updateLineup(lineup, round = null) {
  // where team is 'A' or 'B'
  const producer = store.getState().producer;
  //const isAlternating = store.getState().session.alternating;

  const { teamA, teamB } = producer;
  const newLineup = lineup.lineupData
    ? JSON.parse(lineup.lineupData)[round ? round : producer.round]
    : JSON.parse(JSON.stringify(emptyLineup));

  // need to check if the updated lineup round is actually found, could be undefined

  return (dispatch) => {
    try {
      if (teamA && teamA.lineupId && teamA.lineupId === lineup.id) {
        dispatch(success({ team: 'A', newLineup })); // if newLineup is undefined don't change check in reducer
      }
      if (teamB && teamB.lineupId && teamB.lineupId === lineup.id) {
        dispatch(success({ team: 'B', newLineup })); // if newLineup is undefined don't change check in reducer
      }
    } catch (error) {
      dispatch(failure());
      dispatch(alertActions.error(error));
    }
  };

  function success(data) {
    return { type: producerConstants.UPDATE_LINEUP_SUCCESS, data };
  }
  function failure(error) {
    return { type: producerConstants.UPDATE_LINEUP_FAILURE };
  }
}

function showScores(show = true) {
  return { type: producerConstants.SHOW_SCORES, show };
}

function sortLineup() {
  return { type: producerConstants.SORT_LINEUP };
}

function resetLineup() {
  return { type: producerConstants.RESET_LINEUP };
}

function syncProducer(data = {}, reloadLineup = false) {
  // need to check update to round and thus lineup
  const round = store.getState().producer.round;

  return (dispatch) => {
    if (round !== data?.round || reloadLineup) {
      dispatch(producerActions.changeRound(data?.round || round));
      if (!reloadLineup) {
        dispatch(alertActions.sync(`Change to R${data.round}.`));
      }
    }
    dispatch(success(data));
  };

  function success(data) {
    return { type: producerConstants.SYNC_PRODUCER, data };
  }
}

function force() {
  return { type: producerConstants.FORCE };
}

function forceSelect(side, index, swap = false) {
  // swap used for alternating duals 2/4/6

  // remove side / index from
  //const newDoneEvalAthletes = { ...doneEvalAthletes };

  const data = {
    force: false,
  };

  return { type: producerConstants.FORCE_SELECT, data };
}

function undo() {
  return { type: producerConstants.UNDO };
}

function teamBannerChange(teamIndex, side) {
  const { teamA, teamB, round /*teams*/ } = store.getState().producer;
  const sessionData = store.getState().session.sessionData;
  const lineups = store
    .getState()
    .session.lineups.items.slice()
    .sort((a, b) => a.order - b.order)
    .filter((l) => !l._deleted);

  const data = {
    teamA: teamA,
    teamB: teamB,
  };

  const teams = lineups.map((l) => {
    const team = sessionData.teams.byId[l.teamId] || l.team;
    const { name } = teamBrand(team);
    return {
      ...team,
      id: l.teamId,
      lineupId: l.id,
      displayName: l.forceTitle ? l.title : name,
    };
  });

  const team = teams?.[teamIndex] || {};

  data[side] = {
    ...team,
    lineup:
      (teams[teamIndex].lineupId &&
        sessionData.lineups.byId[teams[teamIndex].lineupId].lineupData &&
        JSON.parse(
          sessionData.lineups.byId[teams[teamIndex].lineupId].lineupData
        )?.[round]) ??
      JSON.parse(JSON.stringify(emptyLineup)),
    home: side === 'teamA',
  };

  return (dispatch) => {
    dispatch(success(data));
    dispatch(producerActions.changeRound(round));
  };

  function success(data) {
    return {
      type: producerConstants.TEAM_BANNER_CHANGE,
      data: data,
    };
  }
}
