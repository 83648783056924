import React, { useEffect, useState, useMemo } from 'react';
import { Alert, Form, Button, Container, Modal } from 'react-bootstrap';
import SessionDetail from './sessiondetail';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './dashboard.css';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions, adminActions } from '../../redux/_actions';
import TableComponent from '../helpers/tablecomponent';
import { dateTime } from '../../utilities/conversions';
import {
  generateSessionYoutubeCreation,
  generateOBSTemplate,
  generateSessionCardData,
} from '../../utilities/generate-session-url';
import { accessType, sessionStatus } from '../../redux/_constants';
import { Loading, ScrollLoading } from '../helpers/loading';
import { SearchInput, addBold, noResults } from '../helpers/search';
import { downloadIcon, plusIcon } from '../helpers/icons';
import StreamCreate from './streamcreate';

function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [isScrollLoading, setIsScrollLoading] = useState(false);
  const alert = useSelector((state) => state.alert);
  const sessions = useSelector((state) => state.admin.sessions);
  const sessionTeams = useSelector((state) => state.admin.sessionTeams);
  const sessionJudges = useSelector((state) => state.admin.sessionJudges);
  const sessionStreams = useSelector((state) => state.admin.streams);
  // const teams = useSelector((state) => state.admin.teams?.items || []);
  // const nextToken = useSelector((state) => state.admin.sessions.tokens.nextToken);
  const searchQuery = useSelector(
    (state) => state.admin.sessions.tokens.searchQuery
  );
  // const searchToken = useSelector((state) => state.admin.sessions.tokens.searchToken);

  const [searchTerm, setSearchTerm] = useState(searchQuery || '');
  const [tempSearchTerm, setTempSearchTerm] = useState(searchQuery || '');
  const [filters, setFilters] = useState({
    gender: null,
    status: null,
    start: null,
    demo: null,
    startDate: null,
    endDate: null,
  });
  const [selectedSession, setSelectedSession] = useState(null);
  const [showStreamCreate, setShowStreamCreate] = useState(null);

  const dispatch = useDispatch();
  const numSessions = sessions?.allIds?.length || 0;
  const isSearch = searchTerm !== '';
  // let lastScrollTop = 0;

  useEffect(() => {
    dispatch(alertActions.clear());
    if (numSessions === 0) {
      setIsLoading(true);
      dispatch(adminActions.getSessions());
    }
  }, [dispatch, numSessions]);

  useEffect(() => {
    setIsLoading(false);
    setIsScrollLoading(false);
    setSearchTerm(tempSearchTerm);
  }, [sessions, tempSearchTerm]);

  const headers = useMemo(
    () => [
      { Header: '#', accessor: 'order' },
      {
        Header: 'Name',
        accessor: 'name',
        maxWidth: 50,
        Cell: (r) => addBold(r.cell.value, searchTerm),
      },
      { Header: 'Type', accessor: 'type' },
      { Header: 'Gender', accessor: 'gender' },
      {
        Header: 'Key',
        accessor: 'key',
        Cell: ({ row }) => (
          <Link
            to={{
              pathname: '/session',
              search: `?s=${row.values.key}`,
              state: { fromAdmin: true },
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.values.key}
          </Link>
        ),
      },
      {
        Header: 'RTN',
        accessor: 'rtnId',
      },
      {
        Header: 'Created',
        accessor: 'created',
        Cell: ({ row }) => dateTime(row.values.created),
      },
      {
        Header: 'Start',
        accessor: 'start',
        Cell: ({ row }) => dateTime(row.values.start),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (r) => {
          const { row, column, cell, updateMyData } = r;
          return (
            <Form.Control
              as="select"
              label="Status"
              value={cell.value}
              onChange={(e) => {
                const payload = {
                  ...sessions.byId[row.original.id],
                  status: e.target.value,
                };
                dispatch(adminActions.editSession(payload));
                updateMyData(row.index, column.id, e.target.value);
              }}
            >
              {Object.keys(sessionStatus).map((statusType) => (
                <option key={statusType} value={statusType}>
                  {statusType}
                </option>
              ))}
            </Form.Control>
          );
        },
      },
      {
        Header: 'Access',
        accessor: 'access',
        Cell: (r) => {
          const { row, column, cell, updateMyData } = r;
          return (
            <Form.Control
              as="select"
              label="Access"
              value={cell.value}
              onChange={(e) => {
                const payload = {
                  ...sessions.byId[row.original.id],
                  access: e.target.value,
                };
                dispatch(adminActions.editSession(payload));
                updateMyData(row.index, column.id, e.target.value);
              }}
            >
              {Object.keys(accessType).map((accessValue) => (
                <option key={accessValue} value={accessValue}>
                  {accessValue}
                </option>
              ))}
            </Form.Control>
          );
        },
      },
      {
        Header: 'Scorekeeper',
        accessor: 'scorekeepers',
        Cell: ({ row }) => {
          const scorekeepers = Array.isArray(row.original.scorekeepers)
            ? row.original.scorekeepers
            : [];
          return (
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {scorekeepers.map((email, index) => {
                return <div key={index}>{email || 'N/A'}</div>;
              })}
            </div>
          );
        },
      },
      {
        Header: 'Streams',
        accessor: 'streams',
        Cell: ({ row }) => {
          const streams = Array.isArray(row.original.streams)
            ? row.original.streams
            : [];
          return (
            <div className="streams-cell">
              {streams.map((stream, index) => (
                <div key={index}>{stream || 'N/A'}</div>
              ))}
              <Button
                variant="light"
                className="action-button mt-1"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowStreamCreate(row.original.id);
                }}
              >
                {plusIcon}
              </Button>
            </div>
          );
        },
      },
      {
        Header: 'YT',
        accessor: 'youtube',
        Cell: ({ row }) => {
          const session = sessions.byId[row.original.id];
          const filename = `${session.name.replace(/\s+/g, '_')}_youtube.json`;
          const content = JSON.stringify(
            generateSessionYoutubeCreation(session),
            null,
            2
          );
          return (
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => {
                const blob = new Blob([content], { type: 'application/json' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
              }}
            >
              {downloadIcon}
            </Button>
          );
        },
      },
      {
        Header: 'OBS',
        accessor: 'obs',
        Cell: ({ row }) => {
          const session = sessions.byId[row.original.id];
          const filename = `${session.name.replace(/\s+/g, '_')}_obs.json`;
          const content = JSON.stringify(generateOBSTemplate(session), null, 2);
          return (
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => {
                const blob = new Blob([content], { type: 'application/json' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
              }}
            >
              {downloadIcon}
            </Button>
          );
        },
      },
      {
        Header: 'Card',
        accessor: 'card',
        Cell: ({ row }) => {
          const session = sessions.byId[row.original.id];
          const filename = `${session.name.replace(/\s+/g, '_')}_card.json`;
          const cardTeams =
            session.sessionTeams?.items
              ?.map((st) => sessionTeams?.byId?.[st]?.team)
              .filter(Boolean) || [];
          const content = JSON.stringify(
            generateSessionCardData(session, cardTeams),
            null,
            2
          );
          return (
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => {
                const blob = new Blob([content], { type: 'application/json' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
              }}
            >
              {downloadIcon}
            </Button>
          );
        },
      },
    ],
    [searchTerm, sessions, dispatch]
  );

  const data = useMemo(() => {
    let filteredData = sessions.allIds.map((id, i) => {
      const {
        name,
        type,
        gender,
        sessionKey,
        createdAt,
        startAt,
        access,
        status,
        rtnId,
        sessionJudges: scorekeepers,
        streams,
      } = sessions.byId[id];

      return {
        order: i + 1,
        name: name,
        type: type,
        gender: gender,
        key: sessionKey,
        rtnId: rtnId || '',
        created: createdAt,
        start: startAt,
        status: status,
        access: access,
        id: id,
        scorekeepers:
          scorekeepers.items.map((sj) => sessionJudges.byId[sj]?.user?.email) ||
          [],
        streams:
          streams?.items?.map((stream) => {
            const streamId = typeof stream === 'string' ? stream : stream.id;
            return sessionStreams.byId[streamId]?.title;
          }) || [],
      };
    });

    if (filters.gender) {
      filteredData = filteredData.filter(
        (row) => row.gender === filters.gender
      );
    }
    if (filters.status) {
      filteredData = filteredData.filter(
        (row) => row.status === filters.status
      );
    }
    if (filters.start === 'upcoming') {
      const today = new Date();
      const nextWeek = new Date(today);
      nextWeek.setDate(today.getDate() + 7);
      filteredData = filteredData.filter((row) => {
        const startDate = new Date(row.start);
        return startDate >= today && startDate <= nextWeek;
      });
    } else if (filters.start === 'today') {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      filteredData = filteredData.filter((row) => {
        const startDate = new Date(row.start);
        return startDate >= today && startDate < tomorrow;
      });
    }
    if (filters.demo === 'hide') {
      filteredData = filteredData.filter((row) => {
        return !row.name.toLowerCase().includes('demo');
      });
    }
    if (filters.startDate) {
      filteredData = filteredData.filter((row) => {
        const sessionDate = new Date(row.start);
        const startDate = new Date(filters.startDate);
        startDate.setHours(0, 0, 0, 0);

        if (filters.endDate) {
          const endDate = new Date(filters.endDate);
          endDate.setHours(23, 59, 59, 999);
          return sessionDate >= startDate && sessionDate <= endDate;
        }

        return sessionDate.toDateString() === startDate.toDateString();
      });
    }

    return filteredData;
  }, [sessions, filters]);

  const FilterPill = ({ label, value, filterKey, className = '' }) => (
    <Button
      variant={filters[filterKey] === value ? 'secondary' : 'outline-secondary'}
      className={`rounded-pill py-0 px-2 mx-1 ${className}`}
      size="sm"
      onClick={() =>
        setFilters((prev) => ({
          ...prev,
          [filterKey]: prev[filterKey] === value ? null : value,
        }))
      }
    >
      {label}
    </Button>
  );

  const handleSearch = (query) => {
    setTempSearchTerm(query);
    if (query && query !== '') {
      dispatch(
        adminActions.getSessions({ searchQuery: query, nextToken: null })
      );
    } else {
      setSearchTerm(query);
    }
  };

  return (
    <Container fluid className="dashboard-container">
      <div className="dashboard-header">
        <div className="d-flex align-items-center mb-3">
          <h2 className="mb-0 me-3">Session Dashboard</h2>
          <SearchInput searchTerm={searchTerm} search={handleSearch} />
        </div>
        <div className="filter-container">
          <FilterPill label="Male" value="MALE" filterKey="gender" />
          <FilterPill label="Female" value="FEMALE" filterKey="gender" />
          <FilterPill label="LIVE" value="LIVE" filterKey="status" />
          <FilterPill label="Hide Demos" value="hide" filterKey="demo" />
          <FilterPill label="Today" value="today" filterKey="start" />
          <FilterPill label="+7 Days" value="upcoming" filterKey="start" />
          <DatePicker
            selectsRange={true}
            startDate={filters.startDate}
            endDate={filters.endDate}
            onChange={(update) => {
              const [start, end] = update;
              setFilters((prev) => ({
                ...prev,
                startDate: start,
                endDate: end,
              }));
            }}
            dateFormat="MM/dd/yyyy"
            className="date-picker-pill"
            placeholderText="Date Range"
            isClearable
            customInput={
              <Button
                size="sm"
                variant={filters.startDate ? 'secondary' : 'outline-secondary'}
                className="rounded-pill py-0 px-3"
              >
                {filters.startDate
                  ? filters.endDate
                    ? `${new Date(
                        filters.startDate
                      ).toLocaleDateString()} - ${new Date(
                        filters.endDate
                      ).toLocaleDateString()}`
                    : new Date(filters.startDate).toLocaleDateString()
                  : 'Date Range'}
              </Button>
            }
          />
          <Button
            variant="outline-primary"
            size="sm"
            className="rounded-pill py-0 px-3 ms-2"
            onClick={() => {
              const cardData = data.map((row) => {
                const session = sessions.byId[row.id];
                const cardTeams =
                  session.sessionTeams?.items
                    ?.map((st) => sessionTeams.byId?.[st]?.team)
                    .filter(Boolean) || [];
                return generateSessionCardData(session, cardTeams);
              });
              navigator.clipboard.writeText(JSON.stringify(cardData, null, 2));
            }}
          >
            Copy All Cards
          </Button>
        </div>
      </div>

      {!alert.clear && (
        <Alert
          dismissible
          onClose={() => dispatch(alertActions.clear())}
          variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
          className="dashboard-alert"
        >
          {alert.message}
        </Alert>
      )}

      <div className="dashboard-content">
        {isLoading || numSessions === 0 ? (
          <Loading />
        ) : data.length === 0 && !isSearch ? (
          noResults('sessions')
        ) : (
          <TableComponent
            columns={headers}
            data={data}
            initialState={{ sortBy: [{ id: 'start', desc: true }] }}
            searchTerm={searchTerm}
            textSort={true}
            showFooter={true}
            filterColumns={['name']}
            onClick={(e, row) => {
              // Don't trigger if clicking on a button or select
              if (
                e.target.tagName === 'BUTTON' ||
                e.target.tagName === 'SELECT' ||
                e.target.closest('button') ||
                e.target.closest('select')
              ) {
                return;
              }
              setSelectedSession(row.id);
            }}
          />
        )}
        {isScrollLoading && <ScrollLoading />}
      </div>

      <Modal
        show={selectedSession !== null}
        onHide={() => setSelectedSession(null)}
        size="xl"
        centered
        dialogClassName="session-modal"
      >
        {selectedSession && (
          <SessionDetail
            sessionId={selectedSession}
            sessionKey={sessions.byId[selectedSession]?.sessionKey}
            back={() => setSelectedSession(null)}
          />
        )}
      </Modal>

      <StreamCreate
        show={showStreamCreate !== null}
        onHide={() => setShowStreamCreate(null)}
        sessionId={showStreamCreate}
      />
    </Container>
  );
}

export default Dashboard;
