import { useState } from 'react';
import MatchUp from './matchup';
import SelectGender from '../setup/selectgender';
import { GenderType } from '../../models/index';
import { matches2025 as mMatches } from '../../utilities/cga';
import { matches2025 as wMatches } from '../../utilities/wcga2';
import matchupGridStyles from './matchupgrid.module.css';

function MatchupGrid() {
  const [gender, setGender] = useState(GenderType.COED);
  const { genderContainer } = matchupGridStyles;

  /*
  const [matchList, setMatchList] = useState([]);
  const apolloClient = useApolloClient();

  useEffect(() => {
    const list = [];

    matches.forEach((matchRow) => {
      matchRow.forEach((match) => {
        console.log(match);
        apolloClient
          .query({
            query: GetMatch,
            variables: {
              sessionKey: match.sessionId,
            },
          })
          .then(({ data }) => {
            list.push(data.SessionByKey.items[0]);
          });
      });
    });

    console.log(list);
    setMatchList(list);
  }, [apolloClient]);

  console.log(matchList);
  */

  const splitRowIfNeeded = (row, rowIndex, matches) => {
    while (row.length > 8) {
      // Find natural break points based on time gaps
      const timeGaps = [];
      for (let i = 1; i < row.length; i++) {
        const gap = new Date(row[i].gameTime).getTime() - 
                  new Date(row[i-1].gameTime).getTime();
        timeGaps.push({ index: i, gap });
      }
      
      // Sort gaps by size to find best split point
      timeGaps.sort((a, b) => b.gap - a.gap);
      
      // Split at largest gap, or at 8 items if no significant gaps
      const splitIndex = timeGaps[0].gap > 3600000 ? // 1 hour gap
        Math.min(timeGaps[0].index, 8) : 8;
      
      // Create new row with remainder
      const newRow = row.splice(splitIndex);
      matches.splice(rowIndex + 1, 0, newRow);
      
      // Continue with the next row if it also needs splitting
      row = newRow;
      rowIndex++;
    }
  };

  const selectMatches = (gender) => {
    // First get all matches based on gender filter
    let allMatches = [];
    switch (gender) {
      case GenderType.MALE:
        mMatches.forEach(row => allMatches.push(...row));
        break;
      case GenderType.FEMALE:
        wMatches.forEach(row => allMatches.push(...row));
        break;
      case GenderType.COED:
      default:
        wMatches.forEach(row => allMatches.push(...row));
        mMatches.forEach(row => allMatches.push(...row));
        break;
    }

    const now = new Date().getTime();
    const minute = 60 * 1000;
    const hour = 60 * minute;
    const day = 24 * hour;

    // Initialize sections with meaningful names
    const sections = {
      inProgress: [], // Matches happening now (-10min to +2hours from start time)
      justFinished: [], // Matches that just ended (last 3 hours)
      upcoming: [], // Next 24 hours
      recent: [], // Last 24 hours (excluding justFinished)
      future: [], // Beyond next 24 hours
      past: [], // Beyond last 24 hours
    };

    // Sort matches into sections
    allMatches.forEach(match => {
      const matchTime = new Date(match.gameTime).getTime();
      const timeDiff = matchTime - now;

      if (timeDiff >= -2 * hour && timeDiff <= 2 * hour) {
        sections.inProgress.push(match);
      } else if (timeDiff < -2 * hour && timeDiff >= -3 * hour) {
        sections.justFinished.push(match);
      } else if (timeDiff > hour) {
        const matchDate = new Date(match.gameTime).setHours(0, 0, 0, 0);
        const todayDate = new Date().setHours(0, 0, 0, 0);
        if (matchDate === todayDate) {
          sections.upcoming.push(match);
        } else {
          sections.future.push(match);
        }
      } else if (timeDiff < -3 * hour && timeDiff >= -day) {
        sections.recent.push(match);
      } else if (timeDiff < -day) {
        sections.past.push(match);
      }
    });

    // Sort each section by time appropriately
    sections.inProgress.sort((a, b) => new Date(a.gameTime) - new Date(b.gameTime));
    sections.justFinished.sort((a, b) => new Date(b.gameTime) - new Date(a.gameTime)); // Most recent first
    sections.upcoming.sort((a, b) => new Date(a.gameTime) - new Date(b.gameTime));
    sections.recent.sort((a, b) => new Date(b.gameTime) - new Date(a.gameTime)); // Most recent first
    sections.future.sort((a, b) => new Date(a.gameTime) - new Date(b.gameTime));
    sections.past.sort((a, b) => new Date(b.gameTime) - new Date(a.gameTime)); // Most recent first

    // Create final array with only non-empty sections
    const mergedMatches = [];
    
    if (sections.inProgress.length > 0) mergedMatches.push(sections.inProgress);
    if (sections.justFinished.length > 0) mergedMatches.push(sections.justFinished);
    if (sections.upcoming.length > 0) mergedMatches.push(sections.upcoming);
    if (sections.future.length > 0) mergedMatches.push(sections.future);

    // Handle previous matches - split them into initial row of 8
    const previousMatches = [...sections.recent, ...sections.past];
    if (previousMatches.length > 0) {
      // Split into chunks of 8
      for (let i = 0; i < previousMatches.length; i += 8) {
        const chunk = previousMatches.slice(i, i + 8);
        mergedMatches.push(chunk);
      }
    }

    // Apply additional splitting to any remaining long rows
    mergedMatches.forEach((row, index) => {
      if (row.length > 0) {
        splitRowIfNeeded(row, index, mergedMatches);
      }
    });

    return mergedMatches;
  };

  const matches = selectMatches(gender);
  
  // Create sections object from matches array
  const sections = {
    inProgress: matches[0] || [],
    justFinished: matches[2] || [],
    upcoming: matches[4] || [],
    recent: matches[6] ? matches[6].filter(m => {
      const timeDiff = new Date(m.gameTime).getTime() - new Date().getTime();
      return timeDiff >= -24 * 60 * 60 * 1000;
    }) : [],
    past: matches[6] ? matches[6].filter(m => {
      const timeDiff = new Date(m.gameTime).getTime() - new Date().getTime();
      return timeDiff < -24 * 60 * 60 * 1000;
    }) : [],
    future: matches[7] || []
  };

  return (
    <div className="heroGrid">
      <div className={genderContainer}>
        <SelectGender gender={gender} setGender={setGender} />
      </div>
      <ul>
        {matches &&
          matches.map((matchRow, i) => {
            let headText = '';

            if (matchRow.length === 0) {
              return null;
            }

            // Determine heading based on match times
            const firstMatchTime = new Date(matchRow[0].gameTime).getTime();
            const now = new Date().getTime();
            const timeDiff = firstMatchTime - now;
            const minute = 60 * 1000;
            const hour = 60 * minute;
            const day = 24 * hour;

            if (timeDiff >= -2 * hour && timeDiff <= 2 * hour) {
              headText = 'In Progress';
            } else if (timeDiff < -2 * hour && timeDiff >= -3 * hour) {
              headText = 'Just Finished';
            } else if (timeDiff > hour) {
              const matchDate = new Date(firstMatchTime).setHours(0, 0, 0, 0);
              const todayDate = new Date().setHours(0, 0, 0, 0);
              headText = matchDate === todayDate ? 'Coming Up' : 'Future Matches';
            } else {
              headText = 'Previous Matches';
            }

            return headText ? (
              <li key={i}>
                <h5>{headText}</h5>
                {matchRow.map((match, j) => (
                  <MatchUp match={match} key={j} />
                ))}
              </li>
            ) : null;
          })}
      </ul>
    </div>
  );
}

export default MatchupGrid;
