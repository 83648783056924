import awsconfig from '../aws-exports';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { Amplify } from 'aws-amplify';
import { createApolloClient } from './apollo';

export function setAmplifyConfig(
  authType = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
) {
  Amplify.configure({
    ...awsconfig,
    oauth: {
      ...awsconfig.oauth,
      domain: 'auth.virti.us',
      redirectSignIn: `${window.location.origin}/`,
      redirectSignOut: `${window.location.origin}/`,
    },
    aws_appsync_authenticationType: authType,
    Analytics: {
      disabled: true,
    },
  });

  return setApolloConfig(authType);
}

// Double duty for both Amplify and Apollo depending on auth mode
export function setApolloConfig(
  authType = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
) {
  return createApolloClient({
    ...awsconfig,
    aws_appsync_authenticationType: authType,
  });
}
