import { teamBrand, YYYYMMDD } from './conversions';
import dual_template from '../templates/obs_dual_template.json';
import quad_template from '../templates/obs_quad_template.json';

export function generateSessionUrl(sessionId, includeHost = false) {
  const prefix = includeHost ? 'https://virti.us' : '';

  return `${prefix}/session?s=${sessionId}`;
}

export function generateSessionCardData(session, teams = []) {
  const payload = {};

  if (!session) {
    return payload;
  }

  // Ensure required session fields exist
  payload['sessionId'] = session?.sessionKey || '';
  payload['gameTime'] = session?.startAt || '';
  payload['title'] = session?.name || '';
  payload['tags'] = [session?.gender] || [];

  // Validate teams is an array before attempting forEach
  if (Array.isArray(teams)) {
    teams.forEach((t, index) => {
      if (t) {
        // Check team exists
        const letter = String.fromCharCode(65 + index);
        const branding = teamBrand(t);
        payload[`team${letter}`] = branding?.name || '';
        payload[`team${letter}Logo`] = branding?.logo || '';
      }
    });
  }

  return payload;
}

export function generateSessionYoutubeCreation(session) {
  const payload = {};

  let titleGender = '';

  if (session.gender === 'FEMALE') {
    titleGender = 'Women';
  }

  if (session.gender === 'MALE') {
    titleGender = 'Men';
  }

  if (!session) {
    return payload;
  }

  payload['snippet'] = {
    title: `Virtius Scoring Stream - ${session?.name} - ${titleGender}'s NCAA Gymnastics`,
    scheduledStartTime: session?.startAt,
    description: `This is a NCAA ${titleGender}'s Gymnastics live scoring stream by Virtius.  Follow along here to see team scores, lineups, leaderboards, and individual score flashes just like they are shown in venue.  If you can't be there, but still want to see all the live scoring updates, you've come to the right place.\n\nTo dig into more detailed live scoring, leaderboards, and scorecard breakdowns, check out:\nhttps://virti.us/session?s=${session.sessionKey}`,
  };

  payload['contentDetails'] = {
    enableDvr: true,
    enableEmbed: true,
    recordFromStart: true,
    latencyPreference: 'ultralow',
  };

  payload['status'] = {
    privacyStatus: 'unlisted',
    selfDeclaredMadeForKids: false,
  };

  return payload;
}

export function generateOBSTemplate(session) {
  let payload = {};

  if (!session) {
    return payload;
  }

  const teamCount = session.sessionTeams.items.length || 0;
  let template = null;
  const title = session.name;
  const titleSize =
    title.length > 34
      ? 150
      : title.length > 28
      ? 190
      : title.length > 22
      ? 220
      : 240;
  const sessionKey = session.sessionKey;
  const dateTitle = `${YYYYMMDD(session.startAt)} - ${title}`;

  switch (teamCount) {
    case 1:
      break;
    case 2:
      template = dual_template;
      break;
    case 3:
    case 4:
    default:
      template = quad_template;
      break;
  }
  // console.log(dateTitle);
  const originalTemplate = JSON.stringify(template);
  let updatedTemplate = originalTemplate.replace(/<SESSION_KEY>/g, sessionKey);
  updatedTemplate = updatedTemplate.replace(/<DATE_TITLE>/g, dateTitle);
  updatedTemplate = updatedTemplate.replace(/<TITLE>/g, title);
  updatedTemplate = updatedTemplate.replace(/"<TITLE_SIZE>"/g, titleSize);
  payload = { ...JSON.parse(updatedTemplate) };

  return payload;
}
