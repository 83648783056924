import {
  sessionConstants,
  initialSessionState,
  streamStatus,
  sessionRoleType,
  sessionViewType,
  judgePanelType,
  genderType,
  sessionLoadingState,
} from '../_constants';

export function session(state = initialSessionState, action) {
  switch (action.type) {
    case sessionConstants.CREATE_REQUEST:
      return {
        ...initialSessionState,
      };
    case sessionConstants.CREATE_SUCCESS:
      return {
        ...state,
      };
    case sessionConstants.CREATE_FAILURE:
      return {
        ...state,
      };
    case sessionConstants.UPDATE_SUCCESS:
      return {
        ...state,
        ...action.session,
      };
    case sessionConstants.LOAD_REQUEST:
      return {
        ...state,
        request: action.key,
        loadingState: sessionLoadingState.LOADING,
      };
    case sessionConstants.LOAD_FAILURE:
      return {
        ...state,
        load: sessionConstants.LOAD_FAILURE,
        loadingState: action.loadingState,
      };
    case sessionConstants.LOAD_SUCCESS:
      return {
        ...state,
        ...action.session,
        loadingState: sessionLoadingState.LOADED,
        load: sessionConstants.LOAD_SUCCESS,
        request: null,
        sessionData: action.sessionData,
        role: action.role ?? sessionRoleType.FAN,
        view: state.view ?? sessionViewType.DEFAULT,
        teamScoring:
          action.session.teamScoring ?? initialSessionState.teamScoring,
        alternating: action.session.alternating ?? state.alternating,
        judgePanel:
          action.session.judgePanel ??
          (action.session.gender === genderType.FEMALE
            ? judgePanelType.NCAAW
            : judgePanelType.NCAAM),
      };
    case sessionConstants.UNLOAD_REQUEST:
      return {
        ...state,
        loadingState: null,
      };
    case sessionConstants.SYNC_SESSION_SUCCESS:
      return {
        ...state,
        name: action.data.name,
        teamScoring: action.data.teamScoring ?? state.teamScoring,
        alternating: action.data.alternating ?? state.alternating,
        status: action.data.status,
        producer: JSON.parse(action.data.producer),
        _version: action.data._version,
      };
    case sessionConstants.CREATE_STREAM_NOCAM_REQUEST:
      return {
        ...state,
        streams: {
          items: [
            ...(state.streams?.items ?? []),
            {
              index: action.index,
              status: action.status,
              id: 'FAKE-STREAM-ID',
              _version: 0,
            }
          ]
        }
      };
    case sessionConstants.CREATE_STREAM_REQUEST:
    case sessionConstants.START_STREAM_REQUEST:
    case sessionConstants.STOP_STREAM_REQUEST:
    case sessionConstants.DELETE_STREAM_REQUEST:
    case sessionConstants.RESET_STREAM_REQUEST:
      return {
        ...state,
        streams: {
          items: [
            ...(!!state.streams?.items.find((s) => s.index === action.index)
              ? state.streams.items.map((s) => {
                  return s.index === action.index
                    ? { ...s, status: action.status }
                    : s;
                })
              : [
                  ...(state.streams?.items ?? []),
                  {
                    index: action.index,
                    status: action.status,
                    id: 'FAKE-STREAM-ID',
                    _version: 0,
                  },
                ]),
          ],
        },
      };
    case sessionConstants.STOP_STREAM_FAILURE:
    case sessionConstants.RESET_STREAM_FAILURE:
      return {
        ...state,
        streams: {
          items: [
            ...state.streams.items.map((s) =>
              s.index === action.index
                ? { ...s, status: streamStatus.STARTED }
                : s
            ),
          ],
        },
      };
    case sessionConstants.START_STREAM_FAILURE:
    case sessionConstants.DELETE_STREAM_FAILURE:
      return {
        ...state,
        streams: {
          items: [
            ...state.streams.items.map((s) =>
              s.index === action.index
                ? { ...s, status: streamStatus.STOPPED }
                : s
            ),
          ],
        },
      };
    case sessionConstants.CREATE_STREAM_SUCCESS:
    case sessionConstants.CREATE_STREAM_NOCAM_SUCCESS:
    case sessionConstants.CHECK_STREAM_SUCCESS:
    case sessionConstants.DELETE_STREAM_SUCCESS:
    case sessionConstants.SYNC_NEW_STREAM_SUCCESS:
      return {
        ...state,
        streams: {
          items: [
            ...(!!state.streams?.items?.find((s) => s.index === action.index)
              ? (state.streams?.items ?? [])
                  .map((s) => (s.index === action.index ? action.stream : s))
                  .filter((s) => s.status !== streamStatus.DELETED)
              : [...(state.streams?.items ?? []), action.stream]),
          ],
        },
      };
    case sessionConstants.CREATE_STREAM_FAILURE:
    case sessionConstants.CREATE_STREAM_NOCAM_FAILURE:
      return {
        ...state,
        streams: {
          items: [
            ...state.streams.items.map((s) =>
              s.index === action.index ? { ...s, status: streamStatus.OFF } : s
            ),
          ],
        },
      };
    case sessionConstants.CHECK_STREAM_FAILURE:
      return {
        ...state,
        streams: {
          items: [
            ...state.streams.items.map((s) =>
              s.index === action.index ? { ...s, status: action.status } : s
            ),
          ],
        },
      };
    case sessionConstants.SUBSCRIBE_STREAM_SUCCESS:
      return {
        ...state,
        [`stream${action.index}sub`]: action.sub,
      };
    case sessionConstants.UNSUBSCRIBE_STREAM_SUCCESS:
      return {
        ...state,
        [`stream${action.index}sub`]: null,
      };
    case sessionConstants.SYNC_STREAM_STATUS_SUCCESS:
      return {
        ...state,
        streams: {
          items: [
            ...state.streams.items.map((s) =>
              s.index === action.index ? action.stream : s
            ),
          ],
        },
      };
    case sessionConstants.SUBSCRIBE_ALL_STREAMS_SUCCESS:
      return {
        ...state,
        streamSubs: action.sub,
      };
    case sessionConstants.UNSUBSCRIBE_ALL_STREAMS_SUCCESS:
      return {
        ...state,
        streamSubs: null,
      };
    case sessionConstants.SUBSCRIBE_SUCCESS:
      return {
        ...state,
        sub: action.sub,
      };
    case sessionConstants.UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        sub: null,
      };
    case sessionConstants.SUBSCRIBE_NEW_STREAM_SUCCESS:
      return {
        ...state,
        newStreamSub: action.sub,
      };
    case sessionConstants.UNSUBSCRIBE_NEW_STREAM_SUCCESS:
      return {
        ...state,
        newStreamSub: null,
      };
    case sessionConstants.CREATE_LINEUP_SUCCESS:
      return {
        ...state,
        sessionData: {
          ...state.sessionData,
          lineups: {
            allIds: [
              ...(state.sessionData.lineups?.allIds ?? []),
              ...action.data.allIds,
            ],
            byId: {
              ...state.sessionData.lineups?.byId,
              ...action.data.byId,
            },
          },
        },
        lineups: {
          items: [...state.lineups.items, ...action.data.lineups],
        },
      };
    case sessionConstants.UPDATE_LINEUP_REQUEST:
      return {
        ...state,
        sessionData: {
          ...state.sessionData,
          lineups: {
            ...state.sessionData.lineups,
            byId: {
              ...state.sessionData.lineups.byId,
              [action.data.id]: {
                ...state.sessionData.lineups.byId[action.data.id],
                lineupData: action.data.lineupData,
                _version: action.data._version + 1, // optimistic update to increment version
              },
            },
          },
        },
        lineups: {
          items: state.lineups.items.map((lineup) => {
            return lineup.id === action.data.id
              ? {
                  ...lineup,
                  lineupData: action.data.lineupData,
                  _version: action.data._version + 1,
                }
              : lineup;
          }),
        },
      };
    case sessionConstants.SYNC_LINEUP_SUCCESS:
    case sessionConstants.UPDATE_LINEUP_SUCCESS:
      return {
        ...state,
        sessionData: {
          ...state.sessionData,
          lineups: {
            ...state.sessionData.lineups,
            byId: {
              ...state.sessionData.lineups.byId,
              [action.data.id]: action.data,
            },
          },
        },
        lineups: {
          items: state.lineups.items.map((lineup) => {
            return lineup.id === action.data.id ? action.data : lineup;
          }),
        },
      };
    case sessionConstants.SUBSCRIBE_LINEUP_SUCCESS:
      return {
        ...state,
        [`lineup${action.channel}sub`]: action.sub,
      };
    case sessionConstants.UNSUBSCRIBE_LINEUP_SUCCESS:
      return {
        ...state,
        [`lineup${action.channel}sub`]: null,
      };
    case sessionConstants.SUBSCRIBE_ALL_LINEUPS_SUCCESS:
      return {
        ...state,
        lineupSubs: action.sub,
      };
    case sessionConstants.UNSUBSCRIBE_ALL_LINEUPS_SUCCESS:
      return {
        ...state,
        lineupSubs: null,
      };
    case sessionConstants.UPDATE_PRODUCER_SUCCESS:
      return {
        ...state,
        producer: action.data,
      };
    case sessionConstants.CHANGE_VIEW:
      return {
        ...state,
        view: action.view,
      };
    case sessionConstants.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        ...action.session,
      };
    case sessionConstants.GET_TEAMS_SUCCESS:
    case sessionConstants.JOIN_REQUEST:
    case sessionConstants.JOIN_SUCCESS:
    case sessionConstants.JOIN_FAILURE:
    case sessionConstants.CHECK_STREAM_REQUEST:
    case sessionConstants.START_STREAM_SUCCESS:
    case sessionConstants.RESET_STREAM_SUCCESS:
    case sessionConstants.STOP_STREAM_SUCCESS:
    case sessionConstants.SYNC_NEW_STREAM_BYPASS:
    default:
      return state;
  }
}
