const week2 = [
  {
    tags: ['MALE'],
    sessionId: '5QrEsXnwQI',
    gameTime: '2025-01-11T18:00:00.000Z',
    title: 'Michigan / Greenville / Simpson',
    teamA: 'Michigan',
    teamALogo: 'upload/images/team/OlF6zJbX8C_3TY7v_vL5S',
    teamB: 'Greenville',
    teamBLogo: 'upload/images/team/ENm2b6qi96JA3DRXNyvt9',
    teamC: 'Simpson',
    teamCLogo: 'upload/images/team/5iJiCZuUmn0V7FPYx6-Oh',
  },
];

const week3 = [
  {
    tags: ['MALE'],
    sessionId: 'O3MPbAk0bz',
    gameTime: '2025-01-14T02:00:00.000Z',
    title: 'California vs Stanford',
    teamA: 'California',
    teamALogo: 'upload/images/team/cFqM7q75rvGOTzliayNOC',
    teamB: 'Stanford',
    teamBLogo: 'upload/images/team/TxEa6Awt5quagSXQIhqtk',
  },
];

const week4 = [
  {
    sessionId: 'yZd3FxBQCm',
    gameTime: '2025-01-25T00:00:00.000Z',
    title: 'Simpson vs Navy',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Simpson',
    teamALogo: 'upload/images/team/5iJiCZuUmn0V7FPYx6-Oh',
    teamB: 'Navy',
    teamBLogo: 'upload/images/team/QtMgsTKjUwl0sEQwnlwNT',
  },
  {
    sessionId: 'a6Q8hnmwMz',
    gameTime: '2025-01-25T19:00:00.000Z',
    title: 'Springfield / Army / Michigan',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Springfield',
    teamALogo: 'upload/images/team/uGniv3T8oFPiagZqgWXwv',
    teamB: 'Army',
    teamBLogo: 'upload/images/team/wmICCck438BshXvBlPHV_',
    teamC: 'Michigan',
    teamCLogo: 'upload/images/team/OlF6zJbX8C_3TY7v_vL5S',
  },
  {
    sessionId: 'zbgG-nrEiC',
    gameTime: '2025-01-26T03:00:00.000Z',
    title: 'Stanford / Cal / Ohio State (Stanford Open)',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Stanford',
    teamALogo: 'upload/images/team/TxEa6Awt5quagSXQIhqtk',
    teamB: 'California',
    teamBLogo: 'upload/images/team/jfBUCDAAOrc9urkAN65iZ',
    teamC: 'Ohio State',
    teamCLogo: 'upload/images/team/1zvZAutg_lGASowMXuhnY',
  },
];

export const matches2025 = [week4, week3, week2];

// export function useMatch(key) {
//   const { data } = useQuery(GetMatch, {
//     variables: {
//       sessionKey: key,
//     },
//   });

//   return data;
// }
