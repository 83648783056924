/*global Rollbar */
import React, { useEffect, useState } from 'react';
import './App.css';
import Admin from './components/admin';
import Start from './components/start';
import Home from './components/home';
import Setup from './components/setup';
import Session from './components/session';
import { useDispatch } from 'react-redux';
import { alertActions } from './redux/_actions';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { useUser } from './user-provider';
import { SessionCreateScreen } from './components/screens/SessionCreateScreen';
import { SessionSetupScreen } from './components/screens/SessionSetupScreen';
import { ErrorBoundary } from 'react-error-boundary';
import Dashboard from './components/admin/dashboard';
import { Button, Collapse } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

const analytics = (conduit) => {
  if (window.gtag) {
    window.gtag('send', 'page_view', {
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }
};

function FullscreenErrorFallback({ error, resetErrorBoundary }) {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <div className="w-full d-flex" style={{ height: '100vh' }}>
      <div className="m-auto w-50">
        <h1>Major deduction</h1>
        <p>
          Looks like Virtius has taken a tumble, but our team has been notified
          and they're working to get the issue resolved.
        </p>
        <div>
          <Button onClick={resetErrorBoundary}>Try again</Button>
          <Button
            className="ml-1"
            variant="link"
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? 'Hide details' : 'See details'}
          </Button>
        </div>
        <Collapse in={showDetails}>
          <pre className="my-4 p-4 bg-light">{error.message}</pre>
        </Collapse>
      </div>
    </div>
  );
}

function App() {
  const { user, profile, isAuthenticating, client, isAdmin } = useUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const isSignedIn = user && user.signInUserSession && profile;

  useEffect(() => {
    const unlisten = history.listen((location) => {
      analytics();
      // Might need to remove the clear for scrolling history url changes
      dispatch(alertActions.clear());
    });
    return unlisten;
  }, [dispatch, history]);

  const AuthPage = ({ comp: Component, redirect, exact, path, adminOnly }) => {
    return (
      <Route
        exact={exact}
        path={path}
        render={(props) =>
          !isSignedIn || (adminOnly && !isAdmin) ? (
            <Redirect to={redirect} />
          ) : (
            <Component {...props} user={user} />
          )
        }
      />
    );
  };

  const PublicPage = ({ comp: Component }) => {
    return <Route render={(props) => <Component {...props} />} />;
  };

  const HomePage = ({ comp: Component, redirect, path }) => {
    let target = null;
    if (isSignedIn && !['/privacy', '/terms'].includes(path)) {
      target = <Redirect to={redirect} />;
    }

    return (
      <Route render={(props) => (target ? target : <Component {...props} />)} />
    );
  };

  return (
    !isAuthenticating && (
      <ApolloProvider client={client}>
        <ErrorBoundary
          onError={(error) => Rollbar.error(error)}
          FallbackComponent={FullscreenErrorFallback}
        >
          <Switch>
            <AuthPage
              exact
              path="/admin"
              comp={Admin}
              redirect="/"
              adminOnly={true}
            />
            <AuthPage
              exact
              path="/admin/dashboard"
              comp={Dashboard}
              redirect="/"
              adminOnly={true}
            />
            <AuthPage
              exact
              path="/setup"
              comp={Setup}
              redirect="/"
              adminOnly={true}
            />
            <AuthPage exact path="/scoresheet" comp={Session} redirect="/" />
            <AuthPage exact path="/start" comp={Start} redirect="/" />
            <AuthPage
              exact
              path="/create"
              comp={Start}
              redirect="/"
              adminOnly={true}
            />
            <AuthPage exact path="/join" comp={Start} redirect="/" />
            {/*<AuthPage exact path="/sessions" comp={Start} redirect="/" />*/}
            <PublicPage exact path="/session" comp={Session} />
            <AuthPage
              exact
              path="/session/create"
              comp={SessionCreateScreen}
              redirect="/"
              adminOnly={true}
            />
            <AuthPage
              exact
              path="/session/:sessionKey/setup"
              comp={SessionSetupScreen}
              redirect="/"
              adminOnly={true}
            />

            <HomePage exact path="/verify" comp={Home} />
            <HomePage exact path="/forgotpw" comp={Home} redirect="/start" />
            <HomePage exact path="/resetpw" comp={Home} redirect="/start" />
            <HomePage exact path="/signup" comp={Home} redirect="/start" />
            <HomePage exact path="/privacy" comp={Home} />
            <HomePage exact path="/terms" comp={Home} />
            <HomePage exact path="/login" comp={Home} redirect="/start" />
            <HomePage exact path="/" comp={Home} redirect="/start" />
            <Redirect from="*" to="/" />
          </Switch>
        </ErrorBoundary>
      </ApolloProvider>
    )
  );
}

export default App;
