import {
  useEffect,
  useState,
  useMemo,
  useImperativeHandle,
  useRef,
  useCallback,
  forwardRef,
} from 'react';
import {
  Row,
  Col,
  Form,
  ToggleButton,
  ToggleButtonGroup,
  Button,
} from 'react-bootstrap';
import { xIcon } from '../helpers/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { adminActions, alertActions } from '../../redux/_actions';
import { athleteYearType, FILE_SIZE } from '../../redux/_constants';
import * as Yup from 'yup';
import TableComponent from '../helpers/tablecomponent';
import * as Defaults from '../../utilities/constants';
import {
  strApparatusToArray,
  arrayToStrApparatus,
  dateTime,
} from '../../utilities/conversions';
import DatePicker from 'react-datepicker';
import { UpdateRosterLink } from '../../apollo/mutations/UpdateRosterLink.graphql';
import { UpdateAthlete } from '../../apollo/mutations/UpdateAthlete.graphql';
import { useRosterAthletes, useFullTeam } from '../session/hooks';
import { useMutation, useApolloClient } from '@apollo/client';
import { createRosterLink, useErrorHandler } from '../../apollo/helpers';
import AthleteBatchTypeahead from '../helpers/athletebatchtypeahead';
// import AthleteDetail from './athletedetail';
import { Loading } from '../helpers/loading';
import 'react-datepicker/dist/react-datepicker.css';
import './teamroster.css';
import { MEDIAURL } from '../../utilities/constants';
import { useImage } from '../../utilities/media';
import { useDropzone } from 'react-dropzone';
import Fuse from 'fuse.js';

const RosterTitleForm = forwardRef((props, ref) => {
  const {
    isEditing,
    isSaving,
    mode,
    setMode,
    roster,
    teamId,
    setIsLoading,
    reset,
    rosters,
    rosterData,
    handlePhotoMatching,
  } = props;
  const { table } = useSelector((state) => state.admin.status);
  const dispatch = useDispatch();
  const { modeType } = Defaults;
  const { LIST, CREATE, DETAIL } = modeType;

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit();
    },
  }));

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    activeDate: Yup.string().required('Required'),
    default: Yup.boolean().nullable(),
  });

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    initialValues: {
      teamId: teamId,
      activeDate:
        mode === CREATE || mode === LIST
          ? Defaults.rosterDefault.activeDate
          : new Date(roster?.activeDate),
      title:
        mode === CREATE || mode === LIST
          ? Defaults.rosterDefault.title
          : roster?.title,
      default: roster ? !!roster.default : false,
      id: roster ? roster.id : null,
      _version: roster ? roster._version : null,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      const titleChanged =
        values.activeDate.toJSON() !== roster.activeDate ||
        values.title !== roster.title ||
        values.default !== roster.default;
      const defaultOn = values.default && !roster.default;
      const rosterIdsNVersion = defaultOn
        ? rosters
            .filter((r) => r.id !== roster.id && r?.default)
            .map((r) => {
              return { id: r.id, _version: r._version };
            })
        : [];

      if (!titleChanged) {
        setMode(DETAIL);
        reset();
        return;
      } else {
        dispatch(
          adminActions.editRoster({
            title: titleChanged ? values : null,
            table: null,
            roster: roster,
            newData: table.newData,
            defaultOn,
            rosterIdsNVersion,
          })
        );
        setIsLoading(true);
      }
    },
  });

  return (
    <Row className="rosterTitles">
      <Form onSubmit={handleSubmit}>
        <div className="setupFormLine">
          <Col sm={6}>
            <label>
              Title
              {errors.title ? (
                <span className="form-error">{errors.type}</span>
              ) : null}
            </label>
            <Form.Control
              plaintext
              name="title"
              type="text"
              autoComplete="off"
              value={values.title}
              onChange={handleChange}
              disabled={isSaving || !isEditing}
              placeholder="Enter Roster Title"
            />
          </Col>
          <Col sm={3}>
            <label>
              Active Date
              {errors.activeDate ? (
                <span className="form-error">{errors.name}</span>
              ) : null}
            </label>
            <DatePicker
              name="activeDate"
              disabled={isSaving || !isEditing}
              selected={values.activeDate}
              onChange={(value) => setFieldValue('activeDate', value)}
              dateFormat="MM/dd/yyyy"
              popperPlacement="top-end"
            />
          </Col>
          <Col sm={3}>
            <div
              style={{
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <div
                style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
              >
                <div>
                  <label>Default</label>
                  <Form.Check
                    type="checkbox"
                    name="default"
                    checked={!!values?.default}
                    onChange={(event) =>
                      setFieldValue('default', event.target.checked)
                    }
                    disabled={isSaving || !isEditing}
                    style={{ paddingLeft: 0, height: '40px' }}
                  />
                </div>
                <div>
                  <label>Add Photos</label>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      const input = document.createElement('input');
                      // Support both directory and file selection
                      const dirInput = document.createElement('input');
                      dirInput.type = 'file';
                      dirInput.multiple = true;
                      dirInput.accept = 'image/*';
                      dirInput.webkitdirectory = true;

                      const fileInput = document.createElement('input');
                      fileInput.type = 'file';
                      fileInput.multiple = true;
                      fileInput.accept = 'image/*';

                      // Create handler with access to component scope
                      const handleFiles = (e) => {
                        const files = Array.from(e.target.files);
                        const athleteNames = Array.from(rosterData || []).map(
                          (athlete) => ({
                            name: athlete.name,
                            id: athlete.athlete?.id,
                            _version: athlete.athlete._version,
                            profileImg: athlete.athlete.profileImg,
                          })
                        );
                        handlePhotoMatching(files, athleteNames);
                      };

                      dirInput.onchange = handleFiles;
                      fileInput.onchange = handleFiles;

                      // Create container for inputs
                      const container = document.createElement('div');
                      container.style.display = 'none';
                      document.body.appendChild(container);
                      container.appendChild(dirInput);
                      container.appendChild(fileInput);

                      // Show native file picker dialog
                      fileInput.click();

                      // Cleanup
                      setTimeout(() => {
                        document.body.removeChild(container);
                      }, 1000);
                      input.onchange = (e) => {
                        const files = Array.from(e.target.files);
                        const athleteNames = rosterData.map((athlete) => ({
                          name: athlete.name,
                          id: athlete.athlete.id,
                        }));
                        handlePhotoMatching(files, athleteNames);
                      };
                      input.click();
                    }}
                    style={{ height: '40px', whiteSpace: 'nowrap' }}
                  >
                    Select Files
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Form>
    </Row>
  );
});

export const TeamRoster = forwardRef((props, ref) => {
  const {
    teamId,
    isEditing,
    isSaving,
    reset,
    setIsLoading,
    setIsEditing,
    setTabMode,
    tabMode,
  } = props;
  const { rosters, teams } = useSelector((state) => state.admin);
  const { alert } = useSelector((state) => state);
  const team = teams.byId[teamId];
  const [roster, setRoster] = useState(null);
  const [photoMatches, setPhotoMatches] = useState([]);
  const [showPhotoMatches, setShowPhotoMatches] = useState(false);

  const handlePhotoMatching = (files, athletes) => {
    const fuse = new Fuse(athletes, {
      keys: ['name'],
      threshold: 0.3, // More strict matching
      includeScore: true,
      minMatchCharLength: 2,
    });

    // Track which files have been matched
    const usedFiles = new Set();

    const matches = athletes.map((athlete) => {
      // Clean and normalize athlete name
      const athleteName = athlete.name.toLowerCase().trim();

      // Find potential matching files for this athlete
      const potentialMatches = files
        .filter((file) => !usedFiles.has(file)) // Only consider unused files
        .map((file) => {
          const fileName = file.name
            .replace(/\.[^/.]+$/, '') // Remove extension
            .toLowerCase()
            .trim();

          // Try exact match first
          if (fileName === athleteName) {
            return { file, score: 0, selected: false };
          }

          // Try last name match
          const athleteLastName = athleteName.split(' ').pop();
          if (fileName.includes(athleteLastName)) {
            return { file, score: 0.1, selected: false };
          }

          // Try exact first name match
          const athleteFirstName = athleteName.split(' ')[0];
          if (fileName.includes(athleteFirstName)) {
            return { file, score: 0.3, selected: false };
          }

          // Try partial first name match (for nicknames/variations)
          const partialFirstNameMatch = fileName
            .split(/[\s_-]/)
            .some((part) => {
              // Check if any part of filename starts with first 3+ chars of first name
              if (athleteFirstName.length >= 3) {
                return part
                  .toLowerCase()
                  .startsWith(athleteFirstName.toLowerCase().substring(0, 3));
              }
              return false;
            });
          if (partialFirstNameMatch) {
            return { file, score: 0.4, selected: false };
          }

          // Try fuzzy match as absolute last resort
          const searchResult = fuse.search(fileName)[0];
          return {
            file,
            score: searchResult?.score || 1,
            selected: false,
          };
        })
        .filter((match) => match.score < 0.7) // Only keep reasonable matches
        .sort((a, b) => a.score - b.score)
        .slice(0, 3); // Get top 3 matches

      // Mark best match's file as used if we found any matches
      if (potentialMatches.length > 0) {
        usedFiles.add(potentialMatches[0].file);
      }

      return {
        athlete,
        matches: potentialMatches,
        selectedFile: null,
      };
    });

    setPhotoMatches(matches);
    setShowPhotoMatches(true);
  };
  // const [athleteDetail, setAthleteDetail] = useState(null);
  const { modeType, mApparatusAbbv, wApparatusAbbv } = Defaults;
  const { LIST, DETAIL, CREATE, EDIT, ADD } = modeType;
  let abbv = { MALE: mApparatusAbbv, FEMALE: wApparatusAbbv };
  const dispatch = useDispatch();
  const formRef = useRef();
  const apolloClient = useApolloClient();
  const { parseImageFile: image, addImageFile, uploadImage } = useImage();

  const [updateAthlete] = useMutation(UpdateAthlete);
  const [updateRosterLink] = useMutation(UpdateRosterLink);

  const handleCreateRosterLinkErrors = useErrorHandler(
    'There was a problem creating the rosterLink'
  );
  const handleUpdateAthleteProfileImgErrors = useErrorHandler(
    'There was a problem updating the athlete profile image'
  );

  function removeAthleteFromRoster(e, row) {
    return updateRosterLink({
      variables: {
        input: {
          id: row.original.rosterLinkId,
          _version: row.original._version,
          active: false,
        },
      },
    });
  }

  function addAthleteToRoster(athlete) {
    setTabMode(ADD);

    const input = {
      rosterId: roster.id,
      athleteId: athlete.id,
      active: true,
    };

    // Keeping for Redux store update for team.roster & rostersById

    // dispatch(
    //   adminActions.editRoster({
    //     title: null,
    //     table: {
    //       creates: [0],
    //       added: [0],
    //     },
    //     roster,
    //     newData: [input],
    //   })
    // );

    return createRosterLink(apolloClient, input)
      .then((res) => {
        const newRosterLinks = [
          ...roster.athletes.items,
          res.data.createRosterLink,
        ];
        const newRoster = {
          ...roster,
          athletes: {
            items: newRosterLinks,
          },
        };

        setRoster(newRoster);

        const teamIds = JSON.parse(athlete.teamAffiliations || '[]');
        if (teamIds.indexOf(teamId) === -1) {
          teamIds.push(teamId);
        }

        return updateAthlete({
          variables: {
            input: {
              id: athlete.id,
              _version: athlete._version,
              teamAffiliations: JSON.stringify(teamIds),
            },
          },
        });
      })
      .catch(handleCreateRosterLinkErrors);
  }

  function updateAthleteProfileImg(img, imgMeta, athlete) {
    return uploadImage(img, JSON.parse(imgMeta)[0])
      .then(() => {
        updateAthlete({
          variables: {
            input: {
              id: athlete.id,
              _version: athlete._version,
              profileImg: imgMeta,
            },
          },
        });
      })
      .then(() => {
        dispatch(
          alertActions.success(`${athlete.name}'s profile image updated.`)
        );
      })
      .catch(handleUpdateAthleteProfileImgErrors);
  }

  const rosterAthleteRosterLinks = useRosterAthletes(roster?.id);
  const fullTeam = useFullTeam(teamId);

  const rosterDetail = (e, row) => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    setRoster(rosters.byId[row.id]);

    if (rosters.byId[row.id].athletes.items.length === 0) {
      setTabMode(EDIT);
      setIsEditing(true);
    } else {
      setTabMode(DETAIL);
      setIsEditing(false);
      setIsLoading(true);
    }
  };

  useEffect(() => {
    if (!alert.clear && alert.type === 'alert-success') {
      // Update to see if need to add new roster or selected roster
      if (tabMode === CREATE) {
        // assign last on AllIds array since it was added by push
        const id = rosters.allIds[rosters.allIds.length - 1];
        setRoster(rosters.byId[id]);
        setTabMode(EDIT);
      }

      // Handle the effects of a save operation and data updates
      if (tabMode === EDIT) {
        setTabMode(DETAIL);
        setRoster(rosters.byId[roster.id]); // need to set to new roster
        reset();
      }

      if (tabMode === ADD) {
        setRoster(rosters.byId[roster.id]); // need to set to new roster
        setIsLoading(false);
        reset();
      }

      fullTeam.refetch();
    }

    if (!rosterAthleteRosterLinks?.loading) {
      setIsLoading(false);
    }
  }, [
    alert,
    setTabMode,
    rosters,
    tabMode,
    CREATE,
    EDIT,
    DETAIL,
    ADD,
    reset,
    roster,
    rosterAthleteRosterLinks,
  ]);

  // useEffect(() => {
  //   if (teamId !== status.filterId && roster?.id) {
  //     //dispatch(adminActions.getTeamRosterAthletes({ id: teamId }));
  //     //dispatch(adminActions.getRosterAthletes({ id: roster?.id }));
  //     //dispatch(adminActions.getTeamAthletes(teamId));
  //   }
  // }, [teamId, dispatch, status.filterId, roster?.id]);

  useEffect(() => {
    if (roster) {
      //dispatch(adminActions.getTeamRosterAthletes({ id: teamId }));
      //dispatch(adminActions.getRosterAthletes({ id: roster?.id }));
      //dispatch(adminActions.getTeamAthletes(teamId));
    }
  }, [dispatch, roster]);

  useImperativeHandle(ref, () => ({
    submit() {
      if (!alert.clear) {
        dispatch(alertActions.clear());
      }
      formRef.current.submit();
    },
    create() {
      if (!alert.clear) {
        dispatch(alertActions.clear());
      }
      const input = { ...Defaults.rosterDefault, teamId: teamId };
      dispatch(adminActions.createRoster(input));
      setTabMode(CREATE);
    },
    back() {
      if (!alert.clear) {
        dispatch(alertActions.clear());
      }
      setIsEditing(false);
      setTabMode(LIST);
    },
    edit() {
      if (!alert.clear) {
        dispatch(alertActions.clear());
      }
      setTabMode(EDIT);
    },
  }));

  const rosterHeaders = useMemo(
    () => [
      { Header: '#', accessor: 'order' },
      {
        Header: 'Athlete',
        accessor: 'name',
        Cell: (r) => {
          const { row, cell } = r;
          const { athlete } = row.original;
          const lastImg = image(athlete.profileImg);
          const [img, setImg] = useState(lastImg.filename);

          const checkBase64 = (string) => {
            return string.substr(0, 5) === 'data:';
          };

          const handleClick = (e) => {
            hiddenFileInput.current.click();
          };

          const hiddenFileInput = useRef(null);

          const onDrop = useCallback(
            (acceptedFiles) => {
              const handleLogoChange = (files) => {
                const fileUploaded = Array.from(files).map((f) => f);
                if (!fileUploaded) {
                  return false;
                }

                let topImage = null;

                if (Array.isArray(fileUploaded)) {
                  topImage = fileUploaded[0];
                } else {
                  topImage = fileUploaded;
                }

                if (!alert.clear) {
                  dispatch(alertActions.clear());
                }

                let reader = new FileReader();
                reader.onload = (e) => {
                  const img = new Image();
                  img.onload = () => {
                    if (fileUploaded.size > FILE_SIZE) {
                      dispatch(alertActions.error('Image exceeds max 5MB.'));
                      return false;
                    }
                    setImg(reader.result);
                    const newData = addImageFile(topImage, athlete.profileImg);
                    updateAthleteProfileImg(topImage, newData, athlete);
                  };
                  img.onerror = () => {
                    dispatch(alertActions.error('Could not load image.'));
                    return false;
                  };
                  img.src = e.target.result;
                };

                reader.readAsDataURL(topImage);
              };

              handleLogoChange(acceptedFiles);
            },
            [alert.clear, dispatch]
          );

          const { getRootProps, getInputProps } = useDropzone({
            onDrop,
          });

          return (
            <Row>
              <Col xs={2} {...getRootProps()}>
                <Button
                  variant="light"
                  onClick={handleClick}
                  style={{
                    width: '100%',
                    padding: '0.2rem',
                    border: 'none',
                  }}
                >
                  {athlete.profileImg ? (
                    <img
                      className="img-thumbnail"
                      style={{ width: '75%', background: 'transparent' }}
                      alt={`${cell.value} Profile`}
                      src={checkBase64(img) ? img : `${MEDIAURL}${img}`}
                    />
                  ) : true ? (
                    '+'
                  ) : (
                    '?'
                  )}
                </Button>
                <input
                  type="file"
                  {...getInputProps()}
                  ref={hiddenFileInput}
                  onChange={(e) => onDrop(e.target.files)}
                  style={{ display: 'none' }}
                  accept="image/png,image/gif,image/jpeg"
                />
              </Col>
              <Col style={{ alignItems: 'center', display: 'flex' }}>
                {cell.value}
              </Col>
            </Row>
          );
        },
      },
      {
        Header: 'Class',
        accessor: 'classYear',
        Cell: (r) => {
          const { row, column, cell, updateMyData } = r;

          return (
            <Form.Control
              as="select"
              label="Class"
              id="class"
              value={cell.value ? cell.value : '-'}
              onChange={(e) => {
                updateMyData(
                  row.index,
                  column.id,
                  e.target.value === '-' ? '' : e.target.value
                );
              }}
              disabled={isSaving || !isEditing}
            >
              <option value="-">-</option>
              {Object.keys(athleteYearType).map((classYear) => {
                return (
                  <option key={classYear} value={classYear}>
                    {classYear}
                  </option>
                );
              })}
            </Form.Control>
          );
        },
      },
      {
        Header: 'Position',
        accessor: 'position',
        Cell: (r) => {
          const { row, column, cell, updateMyData } = r;

          return (
            <ToggleButtonGroup
              name="apparatus"
              className="apparatusToggle"
              type="checkbox"
              value={strApparatusToArray(cell.value ? cell.value : '000000')}
              onChange={(selection) => {
                updateMyData(
                  row.index,
                  column.id,
                  arrayToStrApparatus(selection)
                );
              }}
            >
              {abbv[team.gender].map((a, i) => {
                return (
                  <ToggleButton
                    variant="outline-secondary"
                    className="vCenter"
                    value={i}
                    key={i}
                    disabled={isSaving || !isEditing}
                  >
                    {a}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          );
        },
      },
      {
        Header: 'RTN ID',
        accessor: 'rtnId',
        isVisible: false,
        Cell: (r) => {
          const { row } = r;
          return (
            <Row>
              <Col className="rtnInput">
                <input
                  disabled={!isEditing}
                  value={row?.original?.rtnId || ''}
                  readOnly
                />
              </Col>
            </Row>
          );
        },
      },
      {
        Header: 'Remove',
        accessor: 'remove',
        isVisible: false,
        Cell: (r) => {
          const { row } = r;
          return (
            <Row>
              <Col>
                <Button
                  variant="light"
                  style={{ padding: '0.5rem', display: 'flex', color: 'red' }}
                  onClick={(e) => removeAthleteFromRoster(e, row)}
                >
                  {xIcon}
                </Button>
              </Col>
            </Row>
          );
        },
      },
    ],
    [isSaving, isEditing, abbv, team.gender]
  );

  const rosterData = useMemo(() => {
    return rosterAthleteRosterLinks
      ? []
          .concat(Object.values(rosterAthleteRosterLinks))
          .map((link, i) => {
            const { active, classYear, position, _version, id, athlete } = link;
            const { name } = athlete || {};

            return {
              order: i + 1,
              name: name ? name : '',
              classYear: classYear ? classYear : '',
              position: position ? position : '000000',
              athlete,
              rosterLinkId: id,
              update: false,
              active: active,
              _version: _version,
              rtnId: athlete?.rtnId,
            };
          })
          .filter((el) => el.active)
      : [];
  }, [roster, rosterAthleteRosterLinks, team]);

  const rosterForm = () => {
    let existsSelectedRow = false;
    const initialState = rosterData && {
      sortBy: [{ id: 'name', desc: false }],
      hiddenColumns: [!isEditing ? 'remove' : null],
      selectedRowIds: Object.assign(
        {},
        ...rosterData.map((x, i) => {
          if (x.active && !existsSelectedRow) {
            existsSelectedRow = true;
          }
          return { [i]: x.active };
        })
      ),
    };

    return (
      rosterData && (
        <div style={{ minHeight: '60vh' }} className="rosterSelection">
          {!existsSelectedRow &&
          //!isEditing &&
          !isSaving &&
          Object.keys(rosterAthleteRosterLinks).length === 0 ? (
            emptyRoster()
          ) : rosterAthleteRosterLinks?.loading ? (
            <div style={{ height: '60vh' }}>
              <Loading />
            </div>
          ) : (
            <Row className={isSaving || !isEditing ? 'disabled' : null}>
              <TableComponent
                columns={rosterHeaders}
                data={Array.from(rosterData)}
                //onClick={handleAthleteDetail}
                onClick={false}
                initialState={initialState}
                textSort={true}
                editable={false}
                updateable={false}
                disabled={!isEditing || isSaving}
              />
            </Row>
          )}
          {/*          {athleteDetail ? (
            <AthleteMiniDetail
              back={() => setAthleteDetail(null)}
              athleteId={athleteDetail.athleteId}
            />
          ) : null}*/}
          <div className="sticky-bottom-container">
            <AthleteBatchTypeahead
              gender={team.gender}
              onSelected={addAthleteToRoster}
              prompt="Add athlete to roster..."
              currentRoster={rosterData}
            />
            <RosterTitleForm
              isEditing={isEditing}
              isSaving={isSaving}
              roster={roster}
              mode={tabMode}
              teamId={team.id}
              setIsLoading={setIsLoading}
              ref={formRef}
              reset={reset}
              setMode={setTabMode}
              rosters={fullTeam.rosters.items}
              rosterData={rosterData}
              handlePhotoMatching={handlePhotoMatching}
            />
          </div>
        </div>
      )
    );
  };

  // const handleAthleteDetail = (e, row) => {
  //   if (!alert.clear) {
  //     dispatch(alertActions.clear());
  //   }
  //   console.log(row);
  //   setAthleteDetail(row);
  // };

  const emptyList = () => {
    return (
      <Row
        style={{ minHeight: '60vh', textAlign: 'center' }}
        className="vCenter"
      >
        <span>No rosters created.</span>
      </Row>
    );
  };

  const emptyRoster = () => {
    return (
      <Row
        style={{ minHeight: '60vh', textAlign: 'center' }}
        className="vCenter"
      >
        <span>No athletes in roster.</span>
      </Row>
    );
  };

  const headers = useMemo(
    () => [
      { Header: '#', accessor: 'col1' },
      { Header: 'Title', accessor: 'col2' },
      { Header: 'Athletes', accessor: 'col3' },
      {
        Header: 'Active Date',
        accessor: 'col4',
        Cell: (r) => {
          return dateTime(r.cell.value, false);
        },
      },
      {
        Header: 'Updated',
        accessor: 'col5',
        Cell: (r) => {
          return dateTime(r.cell.value, false);
        },
      },
      {
        Header: 'Default',
        accessor: 'col6',
      },
    ],
    []
  );

  const rosterListData = useMemo(() => {
    return (
      (fullTeam?.rosters &&
        fullTeam?.rosters.items.length !== 0 &&
        fullTeam?.rosters.items.map((r, i) => {
          const {
            title,
            updatedAt,
            athletes,
            activeDate,
            default: defaultRoster,
          } = r;
          const count = athletes.items.filter((el) => el.active).length;

          return {
            col1: i + 1,
            col2: title,
            col3: athletes && athletes.items ? count : 0,
            col4: activeDate,
            col5: updatedAt,
            col6: !defaultRoster ? '' : '✓',
            id: r.id,
          };
        })) ||
      []
    );
  }, [fullTeam]);

  const rosterList = () => {
    const initialState = {
      sortBy: [{ id: 'col4', desc: true }],
    };

    return (
      <Row style={{ minHeight: '60vh' }}>
        <TableComponent
          columns={headers}
          data={rosterListData}
          onClick={rosterDetail}
          initialState={initialState}
          textSort={false}
        />
      </Row>
    );
  };

  const setupBody = () => {
    switch (tabMode) {
      case ADD:
      case EDIT:
        return rosterForm();
      case LIST:
      case CREATE:
        if (team && team.rosters && team.rosters.items.length !== 0) {
          return rosterList();
        } else {
          return emptyList();
        }
      case DETAIL:
        return rosterForm();
      default:
        return null;
    }
  };

  return (
    <div className="teamRoster position-relative">
      {setupBody()}
      {showPhotoMatches && (
        <div className="photo-matches-overlay">
          <div className="photo-matches-container">
            <div className="header-container mb-3">
              <h6 className="mb-0">
                Match Photos to Athletes ({photoMatches.length})
              </h6>
              <div className="buttons-container">
                <Button
                  variant="primary"
                  size="sm"
                  disabled={photoMatches.every((m) => m.added || m.skipped)}
                  onClick={async () => {
                    // Process photo matches
                    const matchesToUpload = photoMatches.filter(
                      (match) =>
                        !match.skipped &&
                        (match.selectedFile || match.matches[0]?.file)
                    );

                    const uploadPromises = matchesToUpload.map(
                      async (match) => {
                        const fileToUpload =
                          match.selectedFile || match.matches[0].file;
                        const athlete = match.athlete;

                        // Create FileReader to get base64 data
                        const reader = new FileReader();

                        try {
                          // Wait for file to be read
                          const base64Data = await new Promise(
                            (resolve, reject) => {
                              reader.onload = () => resolve(reader.result);
                              reader.onerror = reject;
                              reader.readAsDataURL(fileToUpload);
                            }
                          );

                          // Create Image to verify loading
                          await new Promise((resolve, reject) => {
                            const img = new Image();
                            img.onload = resolve;
                            img.onerror = reject;
                            img.src = base64Data;
                          });

                          // Check file size
                          if (fileToUpload.size > FILE_SIZE) {
                            dispatch(
                              alertActions.error('Image exceeds max 5MB.')
                            );
                            return;
                          }

                          // Prepare image metadata
                          const newData = addImageFile(
                            fileToUpload,
                            athlete.profileImg
                          );

                          // Upload the image
                          await updateAthleteProfileImg(
                            fileToUpload,
                            newData,
                            athlete
                          );

                          // Mark this match as added after successful upload
                          setPhotoMatches((prev) =>
                            prev.map((m) =>
                              m.athlete.id === athlete.id
                                ? { ...m, added: true, skipped: false }
                                : m
                            )
                          );
                        } catch (error) {
                          dispatch(
                            alertActions.error(
                              `Failed to upload photo for ${athlete.name}`
                            )
                          );
                          console.error('Upload error:', error);
                        }
                      }
                    );

                    // Wait for all uploads to complete
                    await Promise.all(uploadPromises);
                    setShowPhotoMatches(false);
                  }}
                >
                  Upload Matched Photos (
                  {
                    photoMatches.filter(
                      (m) => !m.skipped && (m.selectedFile || m.matches[0])
                    ).length
                  }
                  )
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => setShowPhotoMatches(false)}
                >
                  {photoMatches.some((m) => m.added) ? 'Close' : 'Cancel'}
                </Button>
              </div>
            </div>
            <ul className="list-unstyled">
              {photoMatches.map((match, index) => (
                <li key={match.athlete.id} className="mb-3">
                  <div
                    className={`d-flex align-items-center ${
                      match.skipped ? 'skipped' : ''
                    }`}
                  >
                    <span className="athlete-number">{index + 1}.</span>
                    <span className="athlete-name">{match.athlete.name}</span>
                    <div className="matches-container">
                      {match.matches.map((fileMatch) => (
                        <div
                          key={fileMatch.file.name}
                          className={`match-option ${
                            match.selectedFile === fileMatch.file
                              ? 'selected'
                              : ''
                          }`}
                          onClick={() => {
                            setPhotoMatches((prev) =>
                              prev.map((m, i) =>
                                i === index
                                  ? { ...m, selectedFile: fileMatch.file }
                                  : m
                              )
                            );
                          }}
                        >
                          <div className="match-content">
                            <input
                              type="radio"
                              checked={
                                match.selectedFile === fileMatch.file ||
                                (!match.selectedFile &&
                                  fileMatch === match.matches[0])
                              }
                              onChange={() => {}}
                            />
                            <span>{fileMatch.file.name}</span>
                          </div>
                          <div className="match-score">
                            Match: {((1 - fileMatch.score) * 100).toFixed(0)}%
                          </div>
                        </div>
                      ))}
                      {match.matches.length === 0 && (
                        <div className="d-flex align-items-center gap-2">
                          <span className="text-muted">
                            No matching files found
                          </span>
                          <button
                            className="btn btn-outline-secondary btn-sm"
                            onClick={() => {
                              // Create a temporary file input
                              const input = document.createElement('input');
                              input.type = 'file';
                              input.multiple = true;
                              input.accept = 'image/*';
                              input.onchange = async (e) => {
                                const selectedFiles = Array.from(
                                  e.target.files
                                );
                                const matches = selectedFiles.map((file) => ({
                                  file,
                                  score: 0.2, // Default score for manual selection
                                  selected: false,
                                }));
                                setPhotoMatches((prev) =>
                                  prev.map((m, i) =>
                                    i === index ? { ...m, matches } : m
                                  )
                                );
                              };
                              input.click();
                            }}
                          >
                            Browse Files
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="skip-checkbox">
                      {match.added ? (
                        <div className="added-indicator">
                          <input type="checkbox" checked={true} readOnly />
                          <span>Added</span>
                        </div>
                      ) : (
                        <>
                          <input
                            type="checkbox"
                            checked={match.skipped}
                            onChange={() => {
                              if (!match.alreadyInRoster) {
                                setPhotoMatches((prev) =>
                                  prev.map((m, i) =>
                                    i === index
                                      ? { ...m, skipped: !m.skipped }
                                      : m
                                  )
                                );
                              }
                            }}
                          />
                          <span>
                            {match.alreadyInRoster ? 'Duplicate' : 'Skip'}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
});
